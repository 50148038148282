import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoMail } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { contactEmail } from "../../services/realize-your-project";

export const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      message: "",
      email: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .min(2, t(tokens.contactUs.errors.fullName))
        .max(50, t(tokens.contactUs.errors.fullName))
        .required(t(tokens.contactUs.errors.requiredField)),
      email: Yup.string()
        .trim()
        .email(t(tokens.contactUs.errors.validEmail))
        .required(t(tokens.contactUs.errors.requiredField)),
      message: Yup.string()
        .trim()
        .required(t(tokens.contactUs.errors.requiredField)),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const body = {
          ...values,
          subject: "Contact Form",
        };
        const response = await contactEmail(body);

        if (response.status === 200) {
          resetForm();
          setIsSubmitted(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  return (
    <section className="contact-area contact-bg">
      <div className="container">
        {isSubmitted ? (
          <div className="contact-content">
            <div className="section-title mb-30 text-center">
              <IoMail size={60} />
              <h4>{t(tokens.contact.submissionReceived.title)}</h4>
              <span>{t(tokens.contact.submissionReceived.message)}</span>
              <div className="my-3">
                <button
                  onClick={() => setIsSubmitted(false)}
                  className="btn btn-three ms-3 p-3"
                >
                  {t(tokens.contact.submissionReceived.newMessageBtn)}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="contact-content">
                <div className="section-title mb-30 tg-heading-subheading animation-style2">
                  <span className="sub-title tg-element-title">
                    {t(tokens.contactUs.sectionTitle)}
                  </span>
                  <h2 className="title tg-element-title">
                    {t(tokens.contactUs.mainTitle)}
                  </h2>
                </div>
                <p>{t(tokens.contactUs.description)}</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contact-form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="text"
                          name="fullName"
                          value={formik.values.fullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(tokens.contactUs.form.namePlaceholder)}
                        />
                        {formik.touched.fullName && formik.errors.fullName ? (
                          <div className="error">{formik.errors.fullName}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(
                            tokens.contactUs.form.emailPlaceholder
                          )}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-grp">
                        <textarea
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(
                            tokens.contactUs.form.messagePlaceholder
                          )}
                        ></textarea>
                        {formik.touched.message && formik.errors.message ? (
                          <div className="error">{formik.errors.message}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    style={{ textTransform: "none" }}
                  >
                    {t(tokens.contactUs.form.sendMessage)}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
