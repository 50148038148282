import { useTranslation } from "react-i18next";
import { OurSolutionsWrapper } from "../../../components/OurSolutions/OurSolutionsWrapper";
import { QA_FEATURES } from "../../../data/features";
import { Layout } from "../../../layouts/Layout";
import { QA_IMG } from "../../../lib/assets";
import { tokens } from "../../../locales/tokens";

const QualityAssurancePage = () => {
  const { t } = useTranslation();
  return (
    <Layout header={5} footer={2}>
      <OurSolutionsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={QA_IMG} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">{t(tokens.qaDevelopment.title)}</h2>
            <p>{t(tokens.qaDevelopment.description)}</p>
            <div className="our-solutions-list">
              <ul className="list-wrap">
                {QA_FEATURES.map((feature) => (
                  <li key={feature.id}>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <p>{t(feature.title)}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </OurSolutionsWrapper>
    </Layout>
  );
};

export default QualityAssurancePage;
