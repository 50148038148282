import { useTranslation } from "react-i18next";
import { CAPABILITYTEXT } from "../../data/capabilities";

export const OurCapabilityText = () => {
  const { t } = useTranslation();
  return (
    <div className="our-capability-text">
      <div className="our-capability-text-list">
        <ul className="list-wrap">
          {CAPABILITYTEXT.map((text) => (
            <li key={text.id}>
              {text.icon}
              <span>{t(text.title)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
