import { About } from "../../components/Company/RealizeYourProject/About";
import { ChooseArea } from "../../components/Company/RealizeYourProject/ChooseArea";
import { ContactArea } from "../../components/Company/RealizeYourProject/ContactArea";
import { HowItWork } from "../../components/Company/RealizeYourProject/HowItWork";
import { Layout } from "../../layouts/Layout";

const RealizeYourProjectPage = () => {
  return (
    <Layout header={5} footer={2}>
      {/* about-area */}
      <About />

      {/* choose-area */}
      <ChooseArea />

      {/* features-area */}
      <HowItWork />

      {/* contact-area */}
      <ContactArea />
    </Layout>
  );
};

export default RealizeYourProjectPage;
