import React from "react";
import { Layout } from "../../layouts/Layout";
import { PortfolioWrapper } from "../../components/Portfolio/PortfolioWrapper";
import { ContactWithUs } from "../../components/Portfolio/ContactWithUs";
import { WhyChooseDaxap } from "../../components/Portfolio/WhyChooseDaxap";

const PortfolioPage = () => {
  return (
    <Layout header={5} footer={2}>
      <ContactWithUs />
      <WhyChooseDaxap />
      <PortfolioWrapper />
    </Layout>
  );
};

export default PortfolioPage;
