import { TrustCenterWrapper } from "../../../components/Company/TrustCenter/TrustCenterWrapper";
import {
  CUSTOMER_DATA_SECURITY,
  ORGANIZATIONAL_SECURITY,
} from "../../../data/trust-center";
import { Layout } from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

const WhitePaperPage = () => {
  const { t } = useTranslation();

  return (
    <Layout header={5} footer={2}>
      <TrustCenterWrapper>
        <div className="services-details-wrap pt-30 pb-30">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-12">
                  <h2>{t(tokens.whitePaper.introduction)}</h2>
                  <p>{t(tokens.whitePaper.introductionText)}</p>
                </div>
                <div className="col-12">
                  <h2>{t(tokens.whitePaper.organizationalSecurity)}</h2>
                  <p>{t(tokens.whitePaper.organizationalSecurityText)}</p>
                  <h6>
                    {t(
                      "As Daxap, we are committed to the following statements to manage Information Security:"
                    )}
                  </h6>
                  <ul>
                    {ORGANIZATIONAL_SECURITY.map((item) => (
                      <li key={item.id}>{t(item.text)}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-12">
                  <h2>{t(tokens.whitePaper.protectingCustomerData)}</h2>
                  <p>{t(tokens.whitePaper.protectingCustomerDataText)}</p>
                  <h6>
                    {t(
                      "As Daxap, we are committed to the following statements to protect customer data:"
                    )}
                  </h6>
                  <ul>
                    {CUSTOMER_DATA_SECURITY.map((item) => (
                      <li key={item.id}>{t(item.text)}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-12">
                  <h2>{t(tokens.whitePaper.conclusion)}</h2>
                  <p>{t(tokens.whitePaper.conclusionText)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TrustCenterWrapper>
    </Layout>
  );
};

export default WhitePaperPage;
