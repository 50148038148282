import { FaPeopleGroup } from "react-icons/fa6";
import { FaPeopleCarry } from "react-icons/fa";
import { FaPeopleArrows } from "react-icons/fa6";
import { FaPeoplePulling } from "react-icons/fa6";
import { FaPeopleRobbery } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { tokens } from "../locales/tokens";

export const CAREERS = [
  {
    id: 1,
    title: tokens.careers.dynamicTeamsTitle,
    icon: <FaPeopleGroup />,
    text: tokens.careers.dynamicTeamsText,
  },
  {
    id: 2,
    title: tokens.careers.greatTeammatesTitle,
    icon: <FaPeopleCarry />,
    text: tokens.careers.greatTeammatesText,
  },
  {
    id: 3,
    title: tokens.careers.openCommunicationTitle,
    icon: <FaPeopleArrows />,
    text: tokens.careers.openCommunicationText,
  },
  {
    id: 4,
    title: tokens.careers.autonomyAndAttitudeTitle,
    icon: <FaPeoplePulling />,
    text: tokens.careers.autonomyAndAttitudeText,
  },
  {
    id: 5,
    title: tokens.careers.supportAndWinTitle,
    icon: <FaPeopleRobbery />,
    text: tokens.careers.supportAndWinText,
  },
  {
    id: 6,
    title: tokens.careers.teamworkMakesTheDreamWorkTitle,
    icon: <IoIosPeople />,
    text: tokens.careers.teamworkMakesTheDreamWorkText,
  },
];
