import { Layout } from "../../layouts/Layout";
import { WhoWeAre } from "../../components/WhoWeAre/WhoWeAre";
import { MainBanner } from "../../components/Banners/MainBanner";
import { CollaborationPartner } from "../../components/Brand/CollaborationPartner";
import { CounterArea } from "../../components/CounterAreas/CounterArea";
import { PortfolioArea } from "../../components/Portfolio/Home/PortfolioArea";
import { Services } from "../../components/Services/Services";
import { TestimonialArea } from "../../components/TestimonialAreas/TestimonialArea";
import { OurCapability } from "../../components/OurCapabilities/OurCapability";
import { BlogArea } from "../../components/Blog/BlogArea";

const Home = () => {
  return (
    <Layout header={5} footer={2}>
      {/* main-banner-area */}
      <MainBanner />

      {/* who-we-are-area */}
      <WhoWeAre />

      {/* counter-area */}
      <CounterArea />

      {/* collaboration-partner-area */}
      <CollaborationPartner />

      {/* services-area */}
      <Services />

      {/* our capabilities */}
      <OurCapability />

      {/* project-area */}
      <PortfolioArea />

      {/* testimonial-area */}
      <TestimonialArea />

      {/* blog-area */}
      <BlogArea />
    </Layout>
  );
};

export default Home;
