import {
  KIDDY_PROJECT_IMG01,
  MALIMO_PROJECT_IMG02,
  VIBORI_PROJECT_IMG03,
  NORSK_PROJECT_IMG04,
  T4KIDS_PROJECT_IMG05,
  PAILOT_PROJECT_IMG06,
} from "../lib/assets";
import { tokens } from "../locales/tokens";

export const ALL_PROJECT_LIST = [
  {
    id: 1,
    href: "/kiddy-app",
    src: KIDDY_PROJECT_IMG01,
    title: tokens.projects.kiddy.title,
    subtitle: tokens.projects.kiddy.subtitle,
  },
  {
    id: 2,
    href: "/vibori-app",
    src: VIBORI_PROJECT_IMG03,
    title: tokens.projects.vibori.title,
    subtitle: tokens.projects.vibori.subtitle,
  },
  {
    id: 3,
    href: "/malimo-app",
    src: MALIMO_PROJECT_IMG02,
    title: tokens.projects.malimo.title,
    subtitle: tokens.projects.malimo.subtitle,
  },
  {
    id: 4,
    href: "/norsk-luftfartsmuseum-app",
    src: NORSK_PROJECT_IMG04,
    title: tokens.projects.norsk.title,
    subtitle: tokens.projects.norsk.subtitle,
  },
  {
    id: 5,
    href: "/time4kids-app",
    src: T4KIDS_PROJECT_IMG05,
    title: tokens.projects.time4kids.title,
    subtitle: tokens.projects.time4kids.subtitle,
  },
  {
    id: 6,
    href: "/pailot-app",
    src: PAILOT_PROJECT_IMG06,
    title: tokens.projects.pailot.title,
    subtitle: tokens.projects.pailot.subtitle,
  },
];

export const HOMEPAGE_PROJECT_LIST = ALL_PROJECT_LIST;

export const APP_LIST = [
  {
    id: 1,
    href: "/kiddy-app",
    src: KIDDY_PROJECT_IMG01,
    title: tokens.projects.kiddy.title,
    subtitle: tokens.projects.kiddy.subtitle,
  },
  {
    id: 2,
    href: "/vibori-app",
    src: VIBORI_PROJECT_IMG03,
    title: tokens.projects.vibori.title,
    subtitle: tokens.projects.vibori.title,
  },
  {
    id: 4,
    href: "/norsk-luftfartsmuseum-app",
    src: NORSK_PROJECT_IMG04,
    title: tokens.projects.norsk.title,
    subtitle: tokens.projects.norsk.subtitle,
  },
];

export const WEB_APP_LIST = [
  {
    id: 1,
    href: "/kiddy-app",
    src: KIDDY_PROJECT_IMG01,
    title: tokens.projects.kiddy.title,
    subtitle: tokens.projects.kiddy.subtitle,
  },
  {
    id: 2,
    href: "/vibori-app",
    src: VIBORI_PROJECT_IMG03,
    title: tokens.projects.vibori.title,
    subtitle: tokens.projects.vibori.subtitle,
  },
  {
    id: 3,
    href: "/malimo-app",
    src: MALIMO_PROJECT_IMG02,
    title: tokens.projects.malimo.title,
    subtitle: tokens.projects.malimo.subtitle,
  },
];
