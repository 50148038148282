import { blogApi } from "./instance";
import { ApiError } from "./error";

export const contactEmail = async (values) => {
  try {
    const response = await blogApi.post("/api/emailer/send", values);
    return response;
  } catch (error) {
    const errorResponse = error.response.data;
    throw new ApiError(errorResponse);
  }
};
