import React from "react";
import { FAQ } from "../../../data/trust-center";
import { useTranslation } from "react-i18next";

export const TrustCenterFaq = () => {
  const { t } = useTranslation();

  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <h3 className="title text-center mt-60 mb-20">FAQ</h3>
        {FAQ.map((item) => (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${item.id}`}
                aria-expanded="false"
                aria-controls={`collapse${item.id}`}
              >
                {t(item.title)}
              </button>
            </h2>
            <div
              id={`collapse${item.id}`}
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>{t(item.text)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
