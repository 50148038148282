export const tokens = {
  /* Top Nav */
  nav: {
    ourTeam: "nav.ourTeam",
    socialEntrepreneurship: "nav.socialEntrepreneurship",
    realizeProject: "nav.realizeProject",
    careers: "nav.careers",
    trustCenter: "nav.trustCenter",
    sustainability: "nav.sustainability",
    aboutUs: "nav.aboutUs",
    company: "nav.company",
    ourSolutions: "nav.ourSolutions",
    portfolio: "nav.portfolio",
    blog: "nav.blog",
    contactUs: "nav.contactUs",
  },
  /* HomePage */
  banner: {
    digital: "banner.digital",
    solutions: "banner.solutions",
    forBetterSociety: "banner.forBetterSociety",
    description: "banner.description",
    bookMeeting: "banner.bookMeeting",
  },
  about: {
    whoWeAre: "about.whoWeAre",
    innovativeCompany: "about.innovativeCompany",
    description: "about.description",
    isoCertified: "about.isoCertified",
    socialImpactMngPlan: "about.socialImpactMngPlan",
    ourTeam: "about.ourTeam",
    socialEntrepreneurship: "about.socialEntrepreneurship",
  },
  counter: {
    bestPitchAward: "counter.bestPitchAward",
    endToEndProjects: "counter.endToEndProjects",
    globalPartners: "counter.globalPartners",
    globalClients: "counter.globalClients",
  },
  collaborationPartner: {
    subtitle: "collaborationPartner.subtitle",
    title: "collaborationPartner.title",
    description: "collaborationPartner.description",
  },
  services: {
    ourExpertise: "services.ourExpertise",
    title: "services.title",
    description: "services.description",
  },
  servicesList: {
    appDevelopmentTitle: "servicesList.appDevelopmentTitle",
    appDevelopmentSubtitle: "servicesList.appDevelopmentSubtitle",
    webDevelopmentTitle: "servicesList.webDevelopmentTitle",
    webDevelopmentSubtitle: "servicesList.webDevelopmentSubtitle",
    qualityAssuranceTitle: "servicesList.qualityAssuranceTitle",
    qualityAssuranceSubtitle: "servicesList.qualityAssuranceSubtitle",
  },
  serviceItem: {
    readMore: "serviceItem.readMore",
  },
  ourCapability: {
    subTitle: "ourCapability.subTitle",
    title: "ourCapability.title",
    description: "ourCapability.description",
  },
  capability: {
    saasApplications: "capability.saasApplications",
    webMobileAppDevelopment: "capability.webMobileAppDevelopment",
    productDevelopment: "capability.productDevelopment",
    customSoftwareDevelopment: "capability.customSoftwareDevelopment",
    customApiDevelopmentIntegration:
      "capability.customApiDevelopmentIntegration",
  },
  portfolio: {
    title: "portfolio.title",
    subtitle: "portfolio.subtitle",
    seeAllProjects: "portfolio.seeAllProjects",
  },
  projects: {
    kiddy: {
      title: "projects.kiddy.title",
      subtitle: "projects.kiddy.subtitle",
    },
    vibori: {
      title: "projects.vibori.title",
      subtitle: "projects.vibori.subtitle",
    },
    malimo: {
      title: "projects.malimo.title",
      subtitle: "projects.malimo.subtitle",
    },
    norsk: {
      title: "projects.norsk.title",
      subtitle: "projects.norsk.subtitle",
    },
    time4kids: {
      title: "projects.time4kids.title",
      subtitle: "projects.time4kids.subtitle",
    },
    pailot: {
      title: "projects.pailot.title",
      subtitle: "projects.pailot.subtitle",
    },
  },
  testimonials: {
    sectionTitle: "testimonials.sectionTitle",
    title: "testimonials.title",
    testimonialtext1: "testimonials.testimonialtext1",
    position1: "testimonials.position1",
    testimonialtext2: "testimonials.testimonialtext2",
    position2: "testimonials.position2",
    testimonialtext3: "testimonials.testimonialtext3",
    position3: "testimonials.position3",
    testimonialtext4: "testimonials.testimonialtext4",
    position4: "testimonials.position4",
    testimonialtext5: "testimonials.testimonialtext5",
    position5: "testimonials.position5",
  },
  blog: {
    maintitle: "blog.maintitle",
    title: "blog.title",
    subtitle: "blog.subtitle",
    description: "blog.description",
    readMore: "blog.readMore",
    recentPosts: "blog.recentPosts",
    noPosts: "blog.noPosts",
    categories: "blog.categories",
  },
  footer: {
    mainOffice: "footer.mainOffice",
    norwayOffice: "footer.norwayOffice",
    copyright: "footer.copyright",
    menu: "footer.menu",
    company: "footer.company",
    contactUs: "footer.contactUs",
  },
  /* Social Entrepreneurship Page */
  socialEntrepreneurship: {
    title: "socialEntrepreneurship.title",
    whatIs: "socialEntrepreneurship.whatIs",
    description: "socialEntrepreneurship.description",
    focusChallenges: "socialEntrepreneurship.focusChallenges",
    provenResults: "socialEntrepreneurship.provenResults",
    daxapQualifies: "socialEntrepreneurship.daxapQualifies",
    daxapStory: "socialEntrepreneurship.daxapStory",
    daxapPartners: "socialEntrepreneurship.daxapPartners",
  },
  featureGoals: {
    title: "featureGoals.title",
    description: "featureGoals.description",
  },
  goals: {
    goodHealth: "goals.goodHealth",
    qualityEducation: "goals.qualityEducation",
    reducedInequalities: "goals.reducedInequalities",
    sustainableCities: "goals.sustainableCities",
    partnerships: "goals.partnerships",
  },
  /* Team Page */
  team: {
    founded: "team.founded",
    activeMembers: "team.activeMembers",
    meetTeam: "team.meetTeam",
    coreTeam: "team.coreTeam",
    teamDescription: "team.teamDescription",
    supporters: "team.supporters",
    chairman: "team.chairman",
    boardMember: "team.boardMember",
    ceo: "team.ceo",
    headOfDevOps: "team.headOfDevOps",
    chiefProductOfficer: "team.chiefProductOfficer",
    qaManager: "team.qaManager",
    marketingManager: "team.marketingManager",
    headOfAppDev: "team.headOfAppDev",
    marketer: "team.marketer",
    globalSalesDirector: "team.globalSalesDirector",
    businessDeveloper: "team.businessDeveloper",
    adviser: "team.adviser",
  },
  /* Realize Your Vision  Page*/
  realizeYourProject: {
    realizeYourVision: "realizeYourProject.realizeYourVision",
    bringYourVisionToLife: "realizeYourProject.bringYourVisionToLife",
    description: "realizeYourProject.description",
    businessGrowth: "realizeYourProject.businessGrowth",
    targetAudience: "realizeYourProject.targetAudience",
    whatSpecialty: "realizeYourProject.whatSpecialty",
    whyChooseDaxap: "realizeYourProject.whyChooseDaxap",
    whyChooseDaxapDescription: "realizeYourProject.whyChooseDaxapDescription",
  },
  whyChooseDaxap: {
    customSolutions: "whyChooseDaxap.customSolutions",
    customSolutionsBody: "whyChooseDaxap.customSolutionsBody",
    expertGuidance: "whyChooseDaxap.expertGuidance",
    expertGuidanceBody: "whyChooseDaxap.expertGuidanceBody",
    endToEndSupport: "whyChooseDaxap.endToEndSupport",
    endToEndSupportBody: "whyChooseDaxap.endToEndSupportBody",
    creativeCollaboration: "whyChooseDaxap.creativeCollaboration",
    creativeCollaborationBody: "whyChooseDaxap.creativeCollaborationBody",
  },
  howItWorks: {
    sectionTitle: "howItWorks.sectionTitle",
    mainTitle: "howItWorks.mainTitle",
    shareYourVision: "howItWorks.shareYourVision",
    shareYourVisionText: "howItWorks.shareYourVisionText",
    collaborate: "howItWorks.collaborate",
    collaborateText: "howItWorks.collaborateText",
    createAndDeliver: "howItWorks.createAndDeliver",
    createAndDeliverText: "howItWorks.createAndDeliverText",
    ongoingSupport: "howItWorks.ongoingSupport",
    ongoingSupportText: "howItWorks.ongoingSupportText",
  },
  contact: {
    sectionTitle: "contact.sectionTitle",
    mainTitle: "contact.mainTitle",
    getInTouch: "contact.getInTouch",
    description: "contact.description",
    form: {
      namePlaceholder: "contact.form.namePlaceholder",
      emailPlaceholder: "contact.form.emailPlaceholder",
      mobilePlaceholder: "contact.form.mobilePlaceholder",
      serviceNeeded: "contact.form.serviceNeeded",
      projectType: {
        webDevelopment: "contact.form.projectType.webDevelopment",
        mobileApp: "contact.form.projectType.mobileApp",
        qualityAssurance: "contact.form.projectType.qualityAssurance",
      },
      descriptionPlaceholder: "contact.form.descriptionPlaceholder",
      sendMessage: "contact.form.sendMessage",
    },
    submissionReceived: {
      title: "contact.submissionReceived.title",
      message: "contact.submissionReceived.message",
      newMessageBtn: "contact.submissionReceived.newMessageBtn",
    },
    errors: {
      fullName: "contact.errors.fullName",
      validEmail: "contact.errors.validEmail",
      requiredField: "contact.errors.requiredField",
      validPhone: "contact.errors.validPhone",
      validProjectType: "contact.errors.validProjectType",
    },
  },
  careerCard: {
    sectionTitle: "careerCard.sectionTitle",
    mainTitle: "careerCard.mainTitle",
    description: "careerCard.description",
    interestedMessage: "careerCard.interestedMessage",
    joinMessage: "careerCard.joinMessage",
  },
  careers: {
    dynamicTeamsTitle: "careers.dynamicTeamsTitle",
    dynamicTeamsText: "careers.dynamicTeamsText",
    greatTeammatesTitle: "careers.greatTeammatesTitle",
    greatTeammatesText: "careers.greatTeammatesText",
    openCommunicationTitle: "careers.openCommunicationTitle",
    openCommunicationText: "careers.openCommunicationText",
    autonomyAndAttitudeTitle: "careers.autonomyAndAttitudeTitle",
    autonomyAndAttitudeText: "careers.autonomyAndAttitudeText",
    supportAndWinTitle: "careers.supportAndWinTitle",
    supportAndWinText: "careers.supportAndWinText",
    teamworkMakesTheDreamWorkTitle: "careers.teamworkMakesTheDreamWorkTitle",
    teamworkMakesTheDreamWorkText: "careers.teamworkMakesTheDreamWorkText",
  },
  contactForm: {
    readyMessage: "contactForm.readyMessage",
    applyMessage: "contactForm.applyMessage",
    submitCVButton: "contactForm.submitCVButton",
  },
  /* Trust Center Page*/
  trustCenter: {
    securityTitle: "trustCenter.securityTitle",
    securityDescription: "trustCenter.securityDescription",
    isoCertified: "trustCenter.isoCertified",
    gdprCompliant: "trustCenter.gdprCompliant",
    environmentalCertified: "trustCenter.environmentalCertified",
  },
  security: {
    features: {
      securelyDesigned: "security.features.securelyDesigned",
      encryption: "security.features.encryption",
      networkSegregation: "security.features.networkSegregation",
      accessToSystems: "security.features.accessToSystems",
      loggingMonitoring: "security.features.loggingMonitoring",
      respondingIncidents: "security.features.respondingIncidents",
      securelyDesignedText: "security.features.securelyDesignedText",
      encryptionText: "security.features.encryptionText",
      networkSegregationText: "security.features.networkSegregationText",
      accessToSystemsText: "security.features.accessToSystemsText",
      loggingMonitoringText: "security.features.loggingMonitoringText",
      respondingIncidentsText: "security.features.respondingIncidentsText",
    },
  },
  faq: {
    securityAndPrivacyProgram: "faq.securityAndPrivacyProgram",
    designatedLead: "faq.designatedLead",
    externalAudit: "faq.externalAudit",
    dataCenters: "faq.dataCenters",
    incidentDetectionResponse: "faq.incidentDetectionResponse",
    securityAndPrivacyProgramText: "faq.securityAndPrivacyProgramText",
    designatedLeadText: "faq.designatedLeadText",
    externalAuditText: "faq.externalAuditText",
    dataCentersText: "faq.dataCentersText",
    incidentDetectionResponseText: "faq.incidentDetectionResponseText",
  },
  /* security-white-paper page */
  whitePaper: {
    introduction: "whitePaper.introduction",
    organizationalSecurity: "whitePaper.organizationalSecurity",
    protectingCustomerData: "whitePaper.protectingCustomerData",
    conclusion: "whitePaper.conclusion",

    // Texts
    introductionText: "whitePaper.introductionText",
    organizationalSecurityText: "whitePaper.organizationalSecurityText",
    protectingCustomerDataText: "whitePaper.protectingCustomerDataText",
    conclusionText: "whitePaper.conclusionText",
  },
  organizationalSecurity: {
    statement1: "organizationalSecurity.statement1",
    statement2: "organizationalSecurity.statement2",
    statement3: "organizationalSecurity.statement3",
    statement4: "organizationalSecurity.statement4",
    statement5: "organizationalSecurity.statement5",
    statement6: "organizationalSecurity.statement6",
    statement7: "organizationalSecurity.statement7",
    statement8: "organizationalSecurity.statement8",
    statement9: "organizationalSecurity.statement9",
    statement10: "organizationalSecurity.statement10",
    statement11: "organizationalSecurity.statement11",
    statement12: "organizationalSecurity.statement12",
    statement13: "organizationalSecurity.statement13",
    statement14: "organizationalSecurity.statement14",
    statement15: "organizationalSecurity.statement15",
    statement16: "organizationalSecurity.statement16",
  },
  customerDataSecurity: {
    securelyDesign: "customerDataSecurity.securelyDesign",
    dataInTransit: "customerDataSecurity.dataInTransit",
    dataAtRest: "customerDataSecurity.dataAtRest",
    encryption: "customerDataSecurity.encryption",
    networkSegregation: "customerDataSecurity.networkSegregation",
    accessToSystems: "customerDataSecurity.accessToSystems",
    multiFactorAuthentication: "customerDataSecurity.multiFactorAuthentication",
    passwordManager: "customerDataSecurity.passwordManager",
    loggingMonitoring: "customerDataSecurity.loggingMonitoring",
    dataRetention: "customerDataSecurity.dataRetention",
    securityIncidents: "customerDataSecurity.securityIncidents",
    vendorManagement: "customerDataSecurity.vendorManagement",
    thirdPartyValidation: "customerDataSecurity.thirdPartyValidation",
  },
  /* privacy-policy page */
  privacyPolicy: {
    title: "privacyPolicy.title",
    introduction: "privacyPolicy.introduction",
    treatmentManager: {
      heading: "privacyPolicy.treatmentManager.heading",
      name: "privacyPolicy.treatmentManager.name",
      contactPerson: "privacyPolicy.treatmentManager.contactPerson",
      telephone: "privacyPolicy.treatmentManager.telephone",
      address: "privacyPolicy.treatmentManager.address",
    },
    processingPurpose: "privacyPolicy.processingPurpose",
    processingPurposeText: "privacyPolicy.processingPurposeText",
    processingWhen: "privacyPolicy.processingWhen",
    processingWhenText: "privacyPolicy.processingWhenText",
    newsletter: {
      heading: "privacyPolicy.newsletter.heading",
      where: "privacyPolicy.newsletter.where",
      storedData: "privacyPolicy.newsletter.storedData",
      usage: "privacyPolicy.newsletter.usage",
      storageTime: "privacyPolicy.newsletter.storageTime",
      basis: "privacyPolicy.newsletter.basis",
      safety: "privacyPolicy.newsletter.safety",
      dataProcessor: "privacyPolicy.newsletter.dataProcessor",
    },
    contactForm: {
      heading: "privacyPolicy.contactForm.heading",
      where: "privacyPolicy.contactForm.where",
      storedData: "privacyPolicy.contactForm.storedData",
      usage: "privacyPolicy.contactForm.usage",
      storageTime: "privacyPolicy.contactForm.storageTime",
      basis: "privacyPolicy.contactForm.basis",
      safety: "privacyPolicy.contactForm.safety",
      dataProcessor: "privacyPolicy.contactForm.dataProcessor",
    },
    cookies: "privacyPolicy.cookies",
    cookiesText: "privacyPolicy.cookiesText",
    processingBasis: "privacyPolicy.processingBasis",
    processingBasisText: "privacyPolicy.processingBasisText",
    rightsAccess: "privacyPolicy.rightsAccess",
    rightsAccessText: "privacyPolicy.rightsAccessText",
    rightsCorrection: "privacyPolicy.rightsCorrection",
    rightsCorrectionText: "privacyPolicy.rightsCorrectionText",
    rightsDeletion: "privacyPolicy.rightsDeletion",
    rightsDeletionText: "privacyPolicy.rightsDeletionText",
    rightsRestriction: "privacyPolicy.rightsRestriction",
    rightsRestrictionText: "privacyPolicy.rightsRestrictionText",
    dataPortability: "privacyPolicy.dataPortability",
    dataPortabilityText: "privacyPolicy.dataPortabilityText",
    withdrawConsent: "privacyPolicy.withdrawConsent",
    withdrawConsentText: "privacyPolicy.withdrawConsentText",
    complaint: "privacyPolicy.complaint",
    complaintText: "privacyPolicy.complaintText",
  },
  /* cookie-policy page */
  cookiePolicy: {
    title: "cookiePolicy.title",
    description: "cookiePolicy.description",
    userAccount: "cookiePolicy.userAccount",
    googleAnalytics: "cookiePolicy.googleAnalytics",
    marketing: "cookiePolicy.marketing",
    cookiesListTitle: "cookiePolicy.cookiesListTitle",
    noCookies: "cookiePolicy.noCookies",
    thirdPartyCookiesTitle: "cookiePolicy.thirdPartyCookiesTitle",
    thirdPartyCookiesDescription: "cookiePolicy.thirdPartyCookiesDescription",
    marketingCookiesTitle: "cookiePolicy.marketingCookiesTitle",
    googleAnalyticsTitle: "cookiePolicy.googleAnalyticsTitle",
    googleAnalyticsDescription: "cookiePolicy.googleAnalyticsDescription",
    googleConversionTitle: "cookiePolicy.googleConversionTitle",
    googleConversionDescription: "cookiePolicy.googleConversionDescription",
    googleRemarketingTitle: "cookiePolicy.googleRemarketingTitle",
    googleRemarketingDescription: "cookiePolicy.googleRemarketingDescription",
    facebookTitle: "cookiePolicy.facebookTitle",
    facebookDescription: "cookiePolicy.facebookDescription",
    linkedinTitle: "cookiePolicy.linkedinTitle",
    linkedinDescription: "cookiePolicy.linkedinDescription",
    googleTagManagerTitle: "cookiePolicy.googleTagManagerTitle",
    googleTagManagerDescription: "cookiePolicy.googleTagManagerDescription",
    tawkToTitle: "cookiePolicy.tawkToTitle",
    tawkToDescription: "cookiePolicy.tawkToDescription",
    acceptRejectTitle: "cookiePolicy.acceptRejectTitle",
    acceptRejectDescription: "cookiePolicy.acceptRejectDescription",
    acceptCookiesTitle: "cookiePolicy.acceptCookiesTitle",
    acceptCookiesDescription: "cookiePolicy.acceptCookiesDescription",
    benefitsTitle: "cookiePolicy.benefitsTitle",
    benefitsDescription: "cookiePolicy.benefitsDescription",
    rejectCookiesTitle: "cookiePolicy.rejectCookiesTitle",
    rejectCookiesDescription: "cookiePolicy.rejectCookiesDescription",
    browserSettingsTitle: "cookiePolicy.browserSettingsTitle",
    browserSettingsDescription: "cookiePolicy.browserSettingsDescription",
    ieSettingsTitle: "cookiePolicy.ieSettingsTitle",
    ieSettingsDescription: "cookiePolicy.ieSettingsDescription",
    safariSettingsTitle: "cookiePolicy.safariSettingsTitle",
    safariSettingsDescription: "cookiePolicy.safariSettingsDescription",
    chromeSettingsTitle: "cookiePolicy.chromeSettingsTitle",
    chromeSettingsDescription: "cookiePolicy.chromeSettingsDescription",
    firefoxSettingsTitle: "cookiePolicy.firefoxSettingsTitle",
    firefoxSettingsDescription: "cookiePolicy.firefoxSettingsDescription",
    operaSettingsTitle: "cookiePolicy.operaSettingsTitle",
    operaSettingsDescription: "cookiePolicy.operaSettingsDescription",
  },
  /* Sustainability Page */
  title: "sustainability.title",
  subtitle1: "sustainability.subtitle1",
  description1: "sustainability.description1",
  subtitle2: "sustainability.subtitle2",
  description2: "sustainability.description2",
  ongoingEffortsTitle: "sustainability.ongoingEffortsTitle",
  transparencyTitle: "sustainability.transparencyTitle",
  transparencyDescription: "sustainability.transparencyDescription",
  energyEfficiencyTitle: "sustainability.energyEfficiencyTitle",
  energyEfficiencyText: "sustainability.energyEfficiencyText",
  sustainableDevelopmentTitle: "sustainability.sustainableDevelopmentTitle",
  sustainableDevelopmentText: "sustainability.sustainableDevelopmentText",
  employeeEngagementTitle: "sustainability.employeeEngagementTitle",
  employeeEngagementText: "sustainability.employeeEngagementText",
  /* App Dev Page */
  appDevelopment: {
    sidebarTitle: "appDevelopment.sidebarTitle",
    title: "appDevelopment.title",
    description: "appDevelopment.description",
    feature1: "appDevelopment.feature1",
    feature2: "appDevelopment.feature2",
    feature3: "appDevelopment.feature3",
    feature4: "appDevelopment.feature4",
    feature5: "appDevelopment.feature5",
    ourApps: "appDevelopment.ourApps",
  },
  /* Web Dev Page */
  webDevelopment: {
    sidebarTitle: "webDevelopment.sidebarTitle",
    title: "webDevelopment.title",
    description: "webDevelopment.description",
    feature1: "webDevelopment.feature1",
    feature2: "webDevelopment.feature2",
    feature3: "webDevelopment.feature3",
    feature4: "webDevelopment.feature4",
    ourWebApps: "webDevelopment.ourApps",
  },
  /* QA Page */
  qaDevelopment: {
    sidebarTitle: "qaDevelopment.sidebarTitle",
    title: "qaDevelopment.title",
    description: "qaDevelopment.description",
    feature1: "qaDevelopment.feature1",
    feature2: "qaDevelopment.feature2",
    feature3: "qaDevelopment.feature3",
    feature4: "qaDevelopment.feature4",
  },
  /* Portfolio Page */
  ourPortfolio: {
    subtitle: "ourPortfolio.subtitle",
    title: "ourPortfolio.title",
    description: "ourPortfolio.description",
    contactWithUs: "ourPortfolio.contactWithUs",
    whyChooseDaxap: {
      subtitle: "ourPortfolio.whyChooseDaxap.subtitle",
      title: "ourPortfolio.whyChooseDaxap.title",
      feature1Title: "ourPortfolio.whyChooseDaxap.feature1Title",
      feature1Text: "ourPortfolio.whyChooseDaxap.feature1Text",
      feature2Title: "ourPortfolio.whyChooseDaxap.feature2Title",
      feature2Text: "ourPortfolio.whyChooseDaxap.feature2Text",
      feature3Title: "ourPortfolio.whyChooseDaxap.feature3Title",
      feature3Text: "ourPortfolio.whyChooseDaxap.feature3Text",
      feature4Title: "ourPortfolio.whyChooseDaxap.feature4Title",
      feature4Text: "ourPortfolio.whyChooseDaxap.feature4Text",
      feature5Title: "ourPortfolio.whyChooseDaxap.feature5Title",
      feature5Text: "ourPortfolio.whyChooseDaxap.feature5Text",
      feature6Title: "ourPortfolio.whyChooseDaxap.feature6Title",
      feature6Text: "ourPortfolio.whyChooseDaxap.feature6Text",
    },
    portfolioOurProjects: {
      subtitle: "ourPortfolio.portfolioOurProjects.subtitle",
      title: "ourPortfolio.portfolioOurProjects.title",
    },
  },
  contactUs: {
    ourOfficeAddress: "contactUs.ourOfficeAddress",
    daxapHeadquarters: "contactUs.daxapHeadquarters",
    daxapOslo: "contactUs.daxapOslo",
    sectionTitle: "contactUs.sectionTitle",
    mainTitle: "contactUs.mainTitle",
    description: "contactUs.description",
    form: {
      namePlaceholder: "contactUs.form.namePlaceholder",
      emailPlaceholder: "contactUs.form.emailPlaceholder",
      messagePlaceholder: "contactUs.form.messagePlaceholder",
      sendMessage: "contactUs.form.sendMessage",
    },
    submissionReceived: {
      title: "contactUs.submissionReceived.title",
      message: "contactUs.submissionReceived.message",
    },
    errors: {
      fullName: "contactUs.errors.fullName",
      validEmail: "contactUs.errors.validEmail",
      requiredField: "contactUs.errors.requiredField",
    },
  },
  /* blog detail page */
  tags: "tags",
  share: "share",
  recentPosts: "recentPosts",
  /* Kiddy Detail Page */
  kiddy: {
    appName: "kiddy.appName",
    projectInfo: "kiddy.projectInfo",
    client: "kiddy.client",
    clientText: "kiddy.clientText",
    date: "kiddy.date",
    category: "kiddy.category",
    categoryText: "kiddy.categoryText",
    projectDesc: "kiddy.projectDesc",
    overview: "kiddy.overview",
    overviewText: "kiddy.overviewText",
    challenges: "kiddy.challenges",
    challengesText: "kiddy.challengesText",
    solutions: "kiddy.solutions",
    solutionsText: "kiddy.solutionsText",
    outcomes: "kiddy.outcomes",
    outcomesText: "kiddy.outcomesText",
    keyFeatures: "kiddy.keyFeatures",
    dailyActivity: "kiddy.dailyActivity",
    dailyActivityText: "kiddy.dailyActivityText",
    realTime: "kiddy.realTime",
    realTimeText: "kiddy.realTimeText",
    childProgress: "kiddy.childProgress",
    childProgressText: "kiddy.childProgressText",
    safeSecure: "kiddy.safeSecure",
    safeSecureText: "kiddy.safeSecureText",
    testimonial: "kiddy.testimonial",
  },
  /* Vibori Detail Page */
  vibori: {
    appName: "vibori.appName",
    projectInfo: "vibori.projectInfo",
    client: "vibori.client",
    clientText: "vibori.clientText",
    date: "vibori.date",
    category: "vibori.category",
    categoryText: "vibori.categoryText",
    projectDesc: "vibori.projectDesc",
    overview: "vibori.overview",
    overviewText: "vibori.overviewText",
    challenges: "vibori.challenges",
    challengesText: "vibori.challengesText",
    solutions: "vibori.solutions",
    solutionsText: "vibori.solutionsText",
    outcomes: "vibori.outcomes",
    outcomesText: "vibori.outcomesText",
    keyFeatures: "vibori.keyFeatures",
    localNews: "vibori.localNews",
    localNewsText: "vibori.localNewsText",
    eventCalendar: "vibori.eventCalendar",
    eventCalendarText: "vibori.eventCalendarText",
    followOrg: "vibori.followOrg",
    followOrgText: "vibori.followOrgText",
    translateFeature: "vibori.translateFeature",
    translateFeatureText: "vibori.translateFeatureText",
    mapInt: "vibori.mapInt",
    mapIntText: "vibori.mapIntText",
  },
  /* Malimo Detail Page */
  malimo: {
    appName: "malimo.appName",
    projectInfo: "malimo.projectInfo",
    client: "malimo.client",
    clientText: "malimo.clientText",
    date: "malimo.date",
    dateText: "malimo.dateText",
    place: "malimo.place",
    placeText: "malimo.placeText",
    projectDesc: "malimo.projectDesc",
    overview: "malimo.overview",
    overviewText: "malimo.overviewText",
    challenges: "malimo.challenges",
    challengesText: "malimo.challengesText",
    solutions: "malimo.solutions",
    solutionsText: "malimo.solutionsText",
    outcomes: "malimo.outcomes",
    outcomesText: "malimo.outcomesText",
    keyFeatures: "malimo.keyFeatures",
    testimonial: "malimo.testimonial",
    testimonialAuthor: "malimo.testimonialAuthor",
    testimonialPosition: "malimo.testimonialPosition",
    pedagogical: "malimo.pedagogical",
    pedagogicalText: "malimo.pedagogicalText",
    extensive: "malimo.extensive",
    extensiveText: "malimo.extensiveText",
    inclusive: "malimo.inclusive",
    inclusiveText: "malimo.inclusiveText",
  },
  /* Norsk Luftfartsmuseum Detail Page */
  norskLuftfartsmuseum: {
    appName: "norskLuftfartsmuseum.appName",
    projectInfo: "norskLuftfartsmuseum.projectInfo",
    client: "norskLuftfartsmuseum.client",
    clientText: "norskLuftfartsmuseum.clientText",
    date: "norskLuftfartsmuseum.date",
    category: "norskLuftfartsmuseum.category",
    categoryText: "norskLuftfartsmuseum.categoryText",
    projectDesc: "norskLuftfartsmuseum.projectDesc",
    overview: "norskLuftfartsmuseum.overview",
    overviewText: "norskLuftfartsmuseum.overviewText",
    challenges: "norskLuftfartsmuseum.challenges",
    challengesText: "norskLuftfartsmuseum.challengesText",
    outcomes: "norskLuftfartsmuseum.outcomes",
    outcomesText: "norskLuftfartsmuseum.outcomesText",
    keyFeatures: "norskLuftfartsmuseum.keyFeatures",
    audio: "norskLuftfartsmuseum.audio",
    audioText: "norskLuftfartsmuseum.audioText",
    qrCode: "norskLuftfartsmuseum.qrCode",
    qrCodeText: "norskLuftfartsmuseum.qrCodeText",
    interactive: "norskLuftfartsmuseum.interactive",
    interactiveText: "norskLuftfartsmuseum.interactiveText",
    educational: "norskLuftfartsmuseum.educational",
    educationalText: "norskLuftfartsmuseum.educationalText",
    museumPark: "norskLuftfartsmuseum.museumPark",
    museumParkText: "norskLuftfartsmuseum.museumParkText",
  },
  /* t4kids Detail Page */
  t4kids: {
    appName: "t4kids.appName",
    projectInfo: "t4kids.projectInfo",
    client: "t4kids.client",
    clientText: "t4kids.clientText",
    date: "t4kids.date",
    category: "t4kids.category",
    categoryText: "t4kids.categoryText",
    projectDesc: "t4kids.projectDesc",
    overview: "t4kids.overview",
    overviewText: "t4kids.overviewText",
    challenges: "t4kids.challenges",
    challengesText: "t4kids.challengesText",
    solutions: "t4kids.solutions",
    solutionsText: "t4kids.solutionsText",
    outcomes: "t4kids.outcomes",
    outcomesText: "t4kids.outcomesText",
    keyFeatures: "t4kids.keyFeatures",
    caseMng: "t4kids.caseMng",
    caseMngText: "t4kids.caseMngText",
    agreement: "t4kids.agreement",
    agreementText: "t4kids.agreementText",
    mediation: "t4kids.mediation",
    mediationText: "t4kids.mediationText",
    tracking: "t4kids.tracking",
    trackingText: "t4kids.trackingText",
    licenceMng: "t4kids.licenceMng",
    licenceMngText: "t4kids.licenceMngText",
    testimonial: "t4kids.testimonial",
    testimonialAuthor: "t4kids.testimonialAuthor",
    testimonialPosition: "t4kids.testimonialPosition",
  },
  /* Pailot Detail Page */
  pailot: {
    appName: "pailot.appName",
    projectInfo: "pailot.projectInfo",
    client: "pailot.client",
    clientText: "pailot.clientText",
    date: "pailot.date",
    category: "pailot.category",
    categoryText: "pailot.categoryText",
    projectDesc: "pailot.projectDesc",
    overview: "pailot.overview",
    overviewText: "pailot.overviewText",
    challenges: "pailot.challenges",
    challengesText: "pailot.challengesText",
    solutions: "pailot.solutions",
    solutionsText: "pailot.solutionsText",
    outcomes: "pailot.outcomes",
    outcomesText: "pailot.outcomesText",
    keyFeatures: "pailot.keyFeatures",
    aiPowered: "pailot.aiPowered",
    aiPoweredText: "pailot.aiPoweredText",
    projectMng: "pailot.projectMng",
    projectMngText: "pailot.projectMngText",
    volunteer: "pailot.volunteer",
    volunteerText: "pailot.volunteerText",
    dataSec: "pailot.dataSec",
    dataSecText: "pailot.dataSecText",
    collaboration: "pailot.collaboration",
    collaborationText: "pailot.collaborationText",
  },
};
