import React from "react";
import { useTranslation } from "react-i18next";

export const TeamAreaFiveItem = ({ member }) => {
  const { t } = useTranslation();
  return (
    <div className="team-item-five">
      <div className="team-thumb-five">
        <img src={member.src} alt="" />
      </div>

      <div className="team-content-five">
        <h2 className="title">{member.title}</h2>
        <span>{t(member.designation)} </span>
        <div className="team-social-four">
          <ul className="list-wrap">
            <li>
              <a href={member.linkedIn}>
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            {/*   <li>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-pinterest-p"></i>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
