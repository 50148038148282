import { WebDevelopmentFeature } from "../../../components/OurSolutions/WebDevelopment/WebDevelopmentFeature";
import { WebProjectList } from "../../../components/OurSolutions/WebDevelopment/WebProjectList";
import { OurSolutionsWrapper } from "../../../components/OurSolutions/OurSolutionsWrapper";
import { Layout } from "../../../layouts/Layout";
import { WEB_DEV_IMG } from "../../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

const WebDevelopmentPage = () => {
  const { t } = useTranslation();
  return (
    <Layout header={5} footer={2}>
      <OurSolutionsWrapper>
        <div className="services-details-wrap">
          <div className="services-details-thumb">
            <img src={WEB_DEV_IMG} alt="" />
          </div>
          <div className="services-details-content">
            <h2 className="title">{t(tokens.webDevelopment.title)}</h2>
            <p>{t(tokens.webDevelopment.description)}</p>
          </div>
          <WebDevelopmentFeature />
          <WebProjectList />
        </div>
      </OurSolutionsWrapper>
    </Layout>
  );
};

export default WebDevelopmentPage;
