/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./translations/en";
import { no } from "./translations/no";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Connect with React
  .init({
    debug: true,
    resources: {
      en: { translation: en },
      no: { translation: no },
    },
    fallbackLng: "en", // Default fallback language

    interpolation: {
      escapeValue: false, // React already escapes by default
    },

    whitelist: ['en', 'no'], // Only allow these languages
    nonExplicitWhitelist: true, // Allow only whitelisted languages
  });

export default i18n;
