import React from "react";
import { useTranslation } from "react-i18next";

export const HowItWorkItem = ({ feature }) => {
  const { t } = useTranslation();
  return (
    <div className="features-item-three">
      <div className="features-icon-three">{feature.id}</div>
      <div className="features-content-three">
        <h2 className="title">{t(feature.title)}</h2>
        <p>{t(feature.text)}</p>
      </div>
    </div>
  );
};
