import { CareerCard } from "../../components/Company/Career/CareerCard";
import { ContactForm } from "../../components/Company/Career/ContactForm";
import { Layout } from "../../layouts/Layout";

const CareerPage = () => {
  return (
    <Layout header={5} footer={2}>
      {/* services */}
      <CareerCard />

      {/* cta-area */}
      <ContactForm />
    </Layout>
  );
};

export default CareerPage;
