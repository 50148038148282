import React, { useContext, useEffect } from "react";
import flag_uk from "../../assets/img/daxap/flag_uk.png";
import flag_no from "../../assets/img/daxap/flag_no.png";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/language-provider";

export const LanguagePopover = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { i18n } = useTranslation();

  /*   const isActiveCn = (path) => {
    return path === selectedLanguage ? "active" : "";
  };
 */
  const languageLinkList = [
    {
      id: 1,
      language: "en",
      icon: flag_uk,
      label: "English",
    },
    {
      id: 2,
      language: "no",
      icon: flag_no,
      label: "Norsk",
    },
  ];

  const handleChange = (language) => {
    setLanguage(language.language);
    i18n.changeLanguage(language.language);
    localStorage.setItem("language", language.language);
  };

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      setLanguage(language);
    }
  }, [i18n, language, setLanguage]);

  return (
    <li
      className={cn("menu-item-has-children", {
        active: languageLinkList.some((el) => el.language === language),
      })}
    >
      <button className="border-0 bg-transparent">
        <img
          src={language === "en" ? flag_uk : flag_no}
          alt="selected_langauge"
          style={{ width: "35px" }}
        />
      </button>
      <ul
        className="sub-menu"
        style={{
          left: "auto",
          right: 0,
          minWidth: "240px",
        }}
      >
        {languageLinkList.map((language) => (
          <li key={language.id}>
            <button
              className="border-0 bg-transparent"
              onClick={() => handleChange(language)}
            >
              <img
                src={language.icon}
                alt="selected_langauge"
                style={{ width: "25px" }}
                className="ms-3"
              />
              {language.label}
            </button>
          </li>
        ))}
      </ul>
    </li>
  );
};
