import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const TrustCenterSidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

    const services = [
    { label: "Trust Center", href: "/trust-center" },
    { label: "ISMS White Paper", href: "/security-white-paper" },
    { label: "Privacy Policy", href: "/privacy-policy" },
    { label: "Cookie Policy", href: "/cookie-policy" },
  ];

  return (
    <aside className="services-sidebar">
      <div className="services-widget">
        <div className="services-cat-list">
          <ul className="list-wrap">
            {services.map((item) => (
              <li key={item.label} className={cn({ active: item.href === pathname })}>
                <Link to={item.href}>
                  {item.label} <i className="flaticon-right-arrow"></i>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  );
};
