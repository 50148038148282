import React from "react";
import { useTranslation } from "react-i18next";
import { contributors, coreTeam, boardTeam } from "../../data/team";
import { TeamAreaFiveItem } from "./TeamAreaItem";
import { tokens } from "../../locales/tokens";

export const TeamArea = () => {
  const { t } = useTranslation();

  return (
    <section className="team-area-six">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title section-title-three text-center mb-50">
              <span className="sub-title">{t(tokens.team.meetTeam)}</span>
              <h2 className="title">{t(tokens.team.coreTeam)}</h2>
              <p>{t(tokens.team.teamDescription)}</p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {boardTeam.map((member) => (
            <div key={member.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
              <TeamAreaFiveItem member={member} />
            </div>
          ))}
        </div>
        <div className="row justify-content-center">
          {coreTeam.map((member) => (
            <div key={member.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
              <TeamAreaFiveItem member={member} />
            </div>
          ))}
        </div>
        <div className="row justify-content-center">
          <div className="section-title section-title-three text-center col-12 my-5">
            <h4 className="title">{t(tokens.team.supporters)}</h4>
          </div>
          {contributors.map((member) => (
            <div key={member.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
              <TeamAreaFiveItem member={member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
