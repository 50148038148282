/* WhyChooseDaxap */
import React from "react";
import { WHYCHOOSEDAXAP } from "../../data/portfolio";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const WhyChooseDaxap = () => {
  const { t } = useTranslation();
  return (
    <section className="features-area-five features-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">
                {t(tokens.ourPortfolio.whyChooseDaxap.subtitle)}
              </span>
              <h2 className="title">
                {t(tokens.ourPortfolio.whyChooseDaxap.title)}
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {WHYCHOOSEDAXAP.map((feature) => (
            <div key={feature.id} className="col-lg-4 col-md-6">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className={feature.icon}></i>
                    </div>
                    <h2 className="title">{t(feature.title)} </h2>
                  </div>
                  <p>{t(feature.text)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
