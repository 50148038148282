import React from "react";
import { Link } from "react-router-dom";
import { SECTION_1_IMG } from "../../lib/assets";
import { FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const MainBanner = () => {
  const { t } = useTranslation();

  return (
    <section className="banner-area-five has-animation">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7 col-md-10 order-0 order-lg-2">
            <div className="banner-img-five">
              <img
                src={SECTION_1_IMG}
                alt="main-img"
                className="main-img"
                data-aos="fade-left"
                data-aos-delay="400"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-content-five">
              <h2 className="title" data-aos="fade-right" data-aos-delay="0">
                {t(tokens.banner.digital)}
                <span>{t(tokens.banner.solutions)}</span>
                <span className="custom-subtitle">
                  {t(tokens.banner.forBetterSociety)}
                </span>
              </h2>

              <p data-aos="fade-right" data-aos-delay="300">
                {t(tokens.banner.description)}
              </p>
              <Link
                to="/realize-your-project"
                className="btn transparent-btn-two"
                data-aos="fade-right"
                data-aos-delay="600"
              >
                {t(tokens.nav.realizeProject)}
              </Link>
              <Link
                onClick={() => {
                  window.open(
                    "https://appt.link/daxap/meet-with-kubilay",
                    "_blank"
                  );
                }}
                className="btn btn-three ms-3"
                data-aos="fade-right"
                data-aos-delay="600"
              >
                <FaCalendarAlt style={{ marginRight: "6px" }} />
                {t(tokens.banner.bookMeeting)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
