import React from "react";
import { REALIZE_YOUR_PROJECT_IMG } from "../../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const About = () => {
  const { t } = useTranslation();

  return (
    <section className="about-area-nine pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7 col-md-10 order-0 order-lg-2">
            <div className="about-img-wrap-four">
              <div className="mask-img-wrap">
                <img src={REALIZE_YOUR_PROJECT_IMG} alt="REALIZE_YOUR_PROJECT_IMG" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-content-four">
              <div className="section-title-two mb-30">
                <span className="sub-title">
                  {t(tokens.realizeYourProject.realizeYourVision)}
                </span>
                <h2 className="title">
                  {t(tokens.realizeYourProject.bringYourVisionToLife)}
                </h2>
              </div>
              <p>{t(tokens.realizeYourProject.description)}</p>
              {/* <div className="about-list-three">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-profit"></i>
                    </div>
                    <div className="content">
                      <h2 className="title">
                        {t(tokens.realizeYourProject.businessGrowth)}
                      </h2>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-mission"></i>
                    </div>
                    <div className="content">
                      <h2 className="title">
                        {t(tokens.realizeYourProject.targetAudience)}
                      </h2>
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
