import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/Blog/BlogPageWrapper";
import { Link, useParams } from "react-router-dom";
import { getBlogs } from "../../services/blog";
import moment from "moment";
import { marked } from "marked";
import { LanguageContext } from "../../context/language-provider";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

const BlogDetailsPage = () => {
  const { slug } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!language) return; // Only proceed if language is defined

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchBlogs = async () => {
      const lang = language === "no" ? "nb" : language;
      const res = await getBlogs(signal, lang);
      setBlogs(res);

      const currentBlog = res.find((item) => item.slug === slug);
      setBlog(currentBlog);
    };

    fetchBlogs();

    return () => {
      controller.abort();
    };
  }, [slug, language]);

  return (
    <Layout header={5} footer={2}>
      <BlogPageWrapper blogs={blogs}>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={blog?.cover.url} alt={blog?.slug} style={{width:"100%"}}/>
        </div>
        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">{blog?.title}</h2>
          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>
                {moment(blog?.publishedAt).format("DD.MM.YYYY ")}
              </li>
              <li>
                by <Link to="/blog-details">{blog?.author?.name}</Link>
              </li>
            </ul>
          </div>

          {!blog || !blog.blocks || blog.blocks.length === 0 ? (
            <p>Loading...</p>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: marked(
                  blog.blocks[0].body.replace(/(#{1,6})([^\s])/g, "$1 $2")
                ),
              }}
            />
          )}
          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">{t(tokens.tags)}</h5>
                  {blog?.tag !== null ? (
                    <ul className="list-wrap">
                      <li>
                        <span>{blog?.tag.title}</span>
                      </li>
                    </ul>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">
                  <h5 className="title">{t(tokens.share)}</h5>
                  <ul className="list-wrap">
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://blog.daxap.io/${blog?.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=https://blog.daxap.io/${blog?.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage;
