import React, { useContext, useEffect, useState } from "react";
import { BlogCard } from "./BlogCard";
import { getRecentBlogs } from "../../services/blog";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { LanguageContext } from "../../context/language-provider";

export const BlogArea = () => {
  const [homePageBlogs, setHomePageBlogs] = useState([]);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (!language) return; // Only proceed if language is defined

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchBlogs = async () => {
      const lang = language === "no" ? "nb" : language;
      const res = await getRecentBlogs(signal, lang);
      setHomePageBlogs(res.slice(0, 3));
    };

    fetchBlogs();

    return () => {
      controller.abort();
    };
  }, [language]);
  return (
    <section className="blog-area-five">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title section-title-three text-center mb-60 tg-heading-subheading animation-style1">
              <span className="sub-title tg-element-title">
                {t(tokens.blog.maintitle)}
              </span>
              <h2 className="title">{t(tokens.blog.title)}</h2>
              <p>{t(tokens.blog.subtitle)}</p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {homePageBlogs.map((blog) => (
            <div key={blog.id} className="col-lg-4 col-md-6 col-sm-10">
              <BlogCard blog={blog} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
