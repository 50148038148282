import { tokens } from "../tokens";

export const en = {
  /* Top Nav */
  [tokens.nav.ourTeam]: "Our Team",
  [tokens.nav.socialEntrepreneurship]: "Social-Entrepreneurship",
  [tokens.nav.realizeProject]: "Realize Your Vision",
  [tokens.nav.careers]: "Careers",
  [tokens.nav.trustCenter]: "Trust Center",
  [tokens.nav.sustainability]: "Sustainability",
  [tokens.nav.aboutUs]: "About Us",
  [tokens.nav.company]: "Company",
  [tokens.nav.ourSolutions]: "Our Solutions",
  [tokens.nav.portfolio]: "Portfolio",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.contactUs]: "Contact Us",
  /* HomePage */
  [tokens.nav.realizeProject]: "Realize Your Vision",
  [tokens.banner.digital]: "Digital",
  [tokens.banner.solutions]: "Solutions",
  [tokens.banner.forBetterSociety]: "For a Better Society",
  [tokens.banner.description]:
    "Daxap creates innovative digital solutions to solve complex challenges, while driving positive change in society. Our mission is to leverage technology to empower communities and organizations for a better future.",
  [tokens.banner.bookMeeting]: "Book a Meeting",
  [tokens.about.whoWeAre]: "Who we are",
  [tokens.about.innovativeCompany]: "Your Technology Partner",
  [tokens.about.description]:
    "Founded to redefine the technology landscape, Daxap doesn’t bill by hours but by solutions delivered. By aligning our goals with those of our clients, we go beyond traditional consulting to build lasting partnerships.Our global team of industry experts is committed to quality, sustainability, and inclusion, creating solutions that address today’s challenges and set the stage for long-term growth and resilience. ",
  [tokens.about.isoCertified]: "ISO 27001 Certified",
  [tokens.about.socialImpactMngPlan]: "Social Impact Management Plan",
  [tokens.about.ourTeam]: "Our Team",
  [tokens.about.socialEntrepreneurship]: "Social Entrepreneurship",
  [tokens.counter.bestPitchAward]: "Best Pitch Award",
  [tokens.counter.endToEndProjects]: "Bespoke Products",
  [tokens.counter.globalPartners]: "Global Partners",
  [tokens.counter.globalClients]: "Global Clients",
  [tokens.collaborationPartner.subtitle]: "Collaboration partners",
  [tokens.collaborationPartner.title]:
    "Building Partnerships for Sustainable Growth",
  [tokens.collaborationPartner.description]:
    "We depend on a network of actors and individuals who want to make positive changes in society. We work with several groups specializing in financing, entrepreneurship, incubator and accelerator programs, legal assistance, research and development, impact measurement, multilingualism, multiculturalism, diversity, integration and inclusion.",
  [tokens.services.ourExpertise]: "Our Expertise",
  [tokens.services.title]: "Scalable Digital Solutions",
  [tokens.services.description]:
    "We create safe, user-friendly products that continuously evolve.",
  [tokens.servicesList.appDevelopmentTitle]: "App Development",
  [tokens.servicesList.appDevelopmentSubtitle]:
    "From developing our own Software as a Service (SaaS) platforms to creating Applications for our partners, we create safe, user-friendly products that continuously evolve.",
  [tokens.servicesList.webDevelopmentTitle]: "Web Development",
  [tokens.servicesList.webDevelopmentSubtitle]:
    "We take great pride in understanding the unique needs of our customers when it comes to website design and functionality. We take an individual approach that is true to your brand identity and needs.",
  [tokens.servicesList.qualityAssuranceTitle]: "Quality Assurance",
  [tokens.servicesList.qualityAssuranceSubtitle]:
    "Quality Assurance (QA) is critical for the growth and reliability of your digital products. Our QA services plays a pivotal role in ensuring your products are secure now and for the future. ",
  [tokens.serviceItem.readMore]: "Read More",
  [tokens.ourCapability.subTitle]: "Our Capabilities",
  [tokens.ourCapability.title]: "Simplifying a Complex Landscape",
  [tokens.ourCapability.description]:
    "We partner with trusted service providers to develop secure, scalable solutions that deliver a seamless, user-friendly experience for both our customers and their users.",
  [tokens.capability.saasApplications]: "SaaS Applications",
  [tokens.capability.webMobileAppDevelopment]: "Web & Mobile App Development",
  [tokens.capability.productDevelopment]: "Product Development",
  [tokens.capability.customSoftwareDevelopment]: "Custom Software Development",
  [tokens.capability.customApiDevelopmentIntegration]:
    "Custom API Development and Integration",
  [tokens.portfolio.title]: "Portfolio",
  [tokens.portfolio.subtitle]:
    "Diverse People, Diverse Projects. Check Out What We’ve Built",
  [tokens.portfolio.seeAllProjects]: "See All Projects",
  [tokens.projects.kiddy.title]: "Kiddy",
  [tokens.projects.kiddy.subtitle]: "Communication Simplified For Everyone",
  [tokens.projects.vibori.title]: "Vibori",
  [tokens.projects.vibori.subtitle]: "See What Is Going On In Your Town",
  [tokens.projects.malimo.title]: "Malimo",
  [tokens.projects.malimo.subtitle]: "More time for learning!",
  [tokens.projects.norsk.title]: "Norsk Luftfartsmuseum",
  [tokens.projects.norsk.subtitle]: "Norwegian Aviation Museum",
  [tokens.projects.time4kids.title]: "Time4Kids",
  [tokens.projects.time4kids.subtitle]: "Revolutionizing Mediation Operations",
  [tokens.projects.pailot.title]: "Pailot",
  [tokens.projects.pailot.subtitle]:
    "Pioneers in Transformative Non-Profit Solutions",
  [tokens.testimonials.sectionTitle]: "Our Testimonials",
  [tokens.testimonials.title]: "What our awesome customers say",
  [tokens.testimonials.testimonialtext1]:
    "Daxap has been essential for us. We have received a technical solution that allows us to grow into the future, with stability and smooth user experience. They possess solid expertise and have solved all challenges along the way. Additionally, they are great people who go the extra mile for us on all levels.",
  [tokens.testimonials.position1]: "Founder,",
  [tokens.testimonials.testimonialtext2]:
    "Their expertise and dedication are crucial to the development of our solution. Daxap understands our vision and implements our values at every stage of development. They became a natural choice for us, based on their previous work and commitment to social entrepreneurship.",
  [tokens.testimonials.position2]: "Founder,",
  [tokens.testimonials.testimonialtext3]:
    "Daxap AS has been a key partner in developing our products, and their technological expertise has helped us realize our vision of providing innovative and user-friendly tools for parents. With their flexibility and ability to deliver tailored solutions, Daxap has been a reliable partner, strengthening our position in the market and enabling us to offer high-quality products.",
  [tokens.testimonials.position3]: "Co-Founder,",
  [tokens.testimonials.testimonialtext4]:
    "As a customer and partner of Daxap AS, we experience them as accessible and accommodating, skilled at identifying needs and contributing to effective solutions, and delivering high professional competence in their work.",
  [tokens.testimonials.position4]: "Co-Founder,",
  [tokens.testimonials.testimonialtext5]:
    "In my collaboration with Daxap; in the boardroom, and with the Daxap team as developers of IT solutions, I experience Daxap as an organization that takes integrity seriously. Professionalism describes the team and leadership, who are also pleasant, down-to-earth people, making it enjoyable to work with them.",
  [tokens.testimonials.position5]: "Manager of the Business Incubator,",
  [tokens.blog.title]: "Read Our Latest Blogs",
  [tokens.blog.maintitle]: "News & Blogs",
  [tokens.blog.subtitle]:
    "Interested in how technology intersects with societal challenges and sustainable growth? Check out our latest articles!",
  [tokens.blog.description]:
    "Explore our blog for the latest updates on technology, innovation, and our journey.",
  [tokens.blog.readMore]: "Read More",
  [tokens.blog.recentPosts]: "Recent Posts",
  [tokens.blog.noPosts]: "No recent posts available.",
  [tokens.blog.categories]: "Categories",
  [tokens.nav.ourTeam]: "Our Team",
  [tokens.nav.socialEntrepreneurship]: "Social Entrepreneurship",
  [tokens.footer.mainOffice]: "Daxap Headquarters: Kuhaugen 4A, 7224 Melhus",
  [tokens.footer.norwayOffice]:
    "Daxap Oslo : Øvre Slottsgate 3, 0157 Oslo, Norway",
  [tokens.footer.copyright]: "Copyright © Daxap | All Right Reserved",
  [tokens.footer.menu]: "Menu",
  [tokens.footer.company]: "Company",
  [tokens.footer.contactUs]: "Contact Us",
  /* Social Entrepreneurship Page */
  [tokens.socialEntrepreneurship.title]: "Social Entrepreneurship",
  [tokens.socialEntrepreneurship.whatIs]: "What is social entrepreneurship?",
  [tokens.socialEntrepreneurship.description]:
    "A social entrepreneur is someone who drives positive change by addressing pressing societal challenges, using innovative approaches to create sustainable solutions that benefit communities and the environment.",
  [tokens.socialEntrepreneurship.focusChallenges]:
    "Focus on societal challenges",
  [tokens.socialEntrepreneurship.provenResults]: "Proven social results",
  [tokens.socialEntrepreneurship.daxapQualifies]:
    "What qualifies Daxap as a social entrepreneur, and why is this important to us?",
  [tokens.socialEntrepreneurship.daxapStory]:
    "Daxap was founded in 2021 by Kubilay Kartal and Emirhan Sözan, who moved to Norway just three years earlier. Both founders, along with their families, faced challenges integrating into society due to language and cultural barriers. Inspired by their experiences, they set out to make life easier for others, and Daxap was born. The company operates with a dual bottom line, measuring success by both social impact and financial results.",
  [tokens.socialEntrepreneurship.daxapPartners]:
    "Daxap partners with organizations committed to addressing societal challenges, allowing us to extend our reach and create a greater impact. Our collaboration with SINTEF, one of Europe’s largest independent research institutions, underscores this commitment. Through our solution, Kiddy, 83% of non-native Norwegian speakers reported that Kiddy significantly improved their access to essential communication, and 77% of all users felt it greatly enhanced communication as a whole.",
  [tokens.featureGoals.title]: "Working For Sustainable Development Goals",
  [tokens.featureGoals.description]:
    "We are committed to making a positive impact on society through our work towards achieving United Nation’s Sustainable Development Goals (SDGs). Our dedication to social impact and sustainability is ingrained in everything we do, from the development of innovative digital solutions to our corporate practices.",
  /* GOALS Titles */
  [tokens.goals.goodHealth]: "Good Health And Well-Being",
  [tokens.goals.qualityEducation]: "Quality Education",
  [tokens.goals.reducedInequalities]: "Reduced Inequalities",
  [tokens.goals.sustainableCities]: "Sustainable cities and communities",
  [tokens.goals.partnerships]: "Partnerships For The Goals",
  /* Team Page */
  [tokens.team.founded]: "Founded in 2021",
  [tokens.team.activeMembers]: "46 active team members",
  [tokens.team.meetTeam]: "Meet Our Team",
  [tokens.team.coreTeam]: "Core Team Members",
  [tokens.team.teamDescription]:
    "Our team is brought together with the belief that digital solutions can solve societal challenges.",
  [tokens.team.supporters]: "The People Who Supported Us On Our Journey",
  [tokens.team.meetTeam]: "Meet Our Team",
  [tokens.team.coreTeam]: "Core Team Members",
  [tokens.team.teamDescription]:
    "Our team is brought together with the belief that digital solutions can solve societal challenges.",
  [tokens.team.supporters]: "The People Who Supported Us On Our Journey",
  [tokens.team.chairman]: "Chairman of the Board",
  [tokens.team.boardMember]: "Board Member",
  [tokens.team.ceo]: "Board Member & CEO",
  [tokens.team.headOfDevOps]: "Head of DevOps",
  [tokens.team.chiefProductOfficer]: "Chief Product Officer",
  [tokens.team.qaManager]: "QA Manager",
  [tokens.team.marketingManager]: "Marketing Manager",
  [tokens.team.headOfAppDev]: "Head of App Dev",
  [tokens.team.marketer]: "Marketer",
  [tokens.team.globalSalesDirector]: "Global Sales Director",
  [tokens.team.businessDeveloper]: "Business Developer",
  [tokens.team.adviser]: "Adviser",
  /* Realize Your Vision  Page*/
  [tokens.realizeYourProject.realizeYourVision]: "Realize Your Vision",
  [tokens.realizeYourProject.bringYourVisionToLife]:
    "Bring Your Vision to Life with Daxap",
  [tokens.realizeYourProject.description]:
    "Have a brilliant idea that you want to turn into reality? At Daxap, we bring ideas and concepts to life. From developing Minimum Viable Products (MVP’s) for fundraising and awareness, to fully-integrated App and Web solutions for thousands of end-users, we take a unique and scalable approach to each engagement.",
  [tokens.realizeYourProject.businessGrowth]: "Business Growth",
  [tokens.realizeYourProject.targetAudience]: "Target Audience",
  [tokens.realizeYourProject.whatSpecialty]: "What Specialty",
  [tokens.realizeYourProject.whyChooseDaxap]: "Why Choose Daxap?",
  [tokens.realizeYourProject.whyChooseDaxapDescription]:
    "We specialize in creating tailor-made digital solutions that help businesses thrive in today’s fast-paced world. Our approach is simple: we listen, collaborate, and innovate. Your vision becomes our mission. With Daxap, you can expect:",
  [tokens.realizeYourProject.businessGrowth]: "Business Growth",
  [tokens.realizeYourProject.targetAudience]: "Target Audience",
  [tokens.realizeYourProject.whatSpecialty]: "What Specialty",
  [tokens.realizeYourProject.whyChooseDaxap]: "Why Choose Daxap?",
  /* Why Choose Daxap Translations */
  [tokens.whyChooseDaxap.customSolutions]: "Custom Solutions",
  [tokens.whyChooseDaxap.customSolutionsBody]:
    "We create unique, custom-built solutions based on your specific needs.",
  [tokens.whyChooseDaxap.expertGuidance]: "Expert Guidance",
  [tokens.whyChooseDaxap.expertGuidanceBody]:
    "Our team of industry experts will guide you through each phase of development, from idea to execution.",
  [tokens.whyChooseDaxap.endToEndSupport]: "End-to-End Support",
  [tokens.whyChooseDaxap.endToEndSupportBody]:
    "Whether it's project management, design, or development, we offer full support throughout the entire journey.",
  [tokens.whyChooseDaxap.creativeCollaboration]: "Creative Collaboration",
  [tokens.whyChooseDaxap.creativeCollaborationBody]:
    "Work closely with a team that values creativity and innovation just as much as you do.",

  /* How It Works Translations */
  [tokens.howItWorks.shareYourVision]: "Share Your Vision",
  [tokens.howItWorks.shareYourVisionText]:
    "Tell us about your project idea and your goals. The more details you provide, the more we can tailor our approach to deliver exactly what you need.",
  [tokens.howItWorks.collaborate]: "Collaborate",
  [tokens.howItWorks.collaborateText]:
    "We work closely with you to design and plan every aspect of your project, ensuring that it aligns with your vision and objectives.",
  [tokens.howItWorks.createAndDeliver]: "Create and Deliver",
  [tokens.howItWorks.createAndDeliverText]:
    "Our team brings your project to life using the latest technology and best practices to ensure high-quality results.",
  [tokens.howItWorks.ongoingSupport]: "Ongoing Support",
  [tokens.howItWorks.ongoingSupportText]:
    "Even after the project is complete, we’re here to offer support and help you scale.",
  [tokens.howItWorks.sectionTitle]: "How It Works",
  [tokens.howItWorks.mainTitle]: "From Vision To Reality",
  contact: {
    sectionTitle: "Contact Us",
    mainTitle: "Let’s Make It Happen",
    getInTouch: "GET IN TOUCH",
    description:
      "Ready to get your idea off the ground? Get in touch with a brief description of what you want to achieve and we will get back to you with ways to bring your vision to life!",
    form: {
      namePlaceholder: "Your Name *",
      emailPlaceholder: "Email *",
      mobilePlaceholder: "Mobile *",
      serviceNeeded: "Service Needed",
      projectType: {
        webDevelopment: "Web Development",
        mobileApp: "Mobile App",
        qualityAssurance: "Quality Assurance",
      },
      descriptionPlaceholder: "Brief Description of the Project",
      sendMessage: "Send Message",
    },
    submissionReceived: {
      title: "Your submission has been received.",
      message: "We will get back to you as soon as possible.",
      newMessageBtn: "New Message",
    },
    errors: {
      fullName: "Please specify your full name",
      validEmail: "Please enter a valid email address",
      requiredField: "This field is required",
      validPhone: "Please enter a valid phone number (10 to 15 digits)",
      validProjectType: "This field is required",
    },
  },
  careerCard: {
    sectionTitle: "INTERESTED IN JOINING DAXAP?",
    mainTitle: "Join a Growing Team of Passionate People",
    description:
      "We believe in investing in talent and passion. We are always looking for committed individuals who want to join our journey and make a difference. Whether you are looking for your next career challenge, or an exciting work placement, we would like to hear from you!",
    interestedMessage: "INTERESTED IN JOINING DAXAP?",
    joinMessage: "Join a Growing Team of Passionate People",
  },
  careers: {
    dynamicTeamsTitle: "Dynamic teams",
    dynamicTeamsText:
      "Emphasizing flexibility and adaptability, dynamic teams in an IT company are capable of quickly responding to technological changes and industry demands.",
    greatTeammatesTitle: "Great teammates",
    greatTeammatesText:
      "This value highlights the importance of having team members who are not only skilled in their technical expertise but also excel in collaboration, mutual respect, and support.",
    openCommunicationTitle: "Open communication",
    openCommunicationText:
      "This value underscores the importance of transparency, regular updates, and the free flow of ideas among all levels of the organization, fostering trust and efficiency.",
    autonomyAndAttitudeTitle: "Autonomy and attitude",
    autonomyAndAttitudeText:
      "It encourages employees to take initiative, be self-motivated, and make decisions that align with the company's goals while also maintaining a positive and forward-thinking mindset.",
    supportAndWinTitle: "Support and win",
    supportAndWinText:
      "It means that the company and its employees are committed to providing support to one another in their professional endeavors, creating a win-win situation for both individual and company growth.",
    teamworkMakesTheDreamWorkTitle: "Teamwork makes the dream work",
    teamworkMakesTheDreamWorkText:
      "This phrase encapsulates the belief that collective effort, where team members work cohesively towards a common goal, is the cornerstone of achieving extraordinary results and innovations.",
  },
  contactForm: {
    readyMessage:
      "Ready to join an innovative and dynamic team? Take the next step in your career and apply today.",
    submitCVButton: "Submit your CV",
  },
  /* Trust Center Page*/
  trustCenter: {
    securityTitle: "Security at Daxap",
    securityDescription:
      "At Daxap, we aim to create the most secure and trusted platforms for our customers and their end-users. Information Security, Privacy, and protecting Intellectual Property is at the core of what we do, and we are committed to continually raising the bar in these areas.",
    isoCertified: "ISO 27001 Certified",
    gdprCompliant: "GDPR Compliant",
    environmentalCertified: "Environmental Lighthouse Certified",
  },
  [tokens.security.features.securelyDesigned]: "Securely Designed",
  [tokens.security.features.encryption]: "Encryption",
  [tokens.security.features.networkSegregation]:
    "Network Segregation and Security",
  [tokens.security.features.accessToSystems]: "Access to Systems",
  [tokens.security.features.loggingMonitoring]:
    "Logging and monitoring the systems",
  [tokens.security.features.respondingIncidents]:
    "Responding to Security Incidents",

  // Texts
  [tokens.security.features.securelyDesignedText]:
    "From vision to concept and final product, Daxap’s information security standards are applied through the entire lifecycle of each engagement.",
  [tokens.security.features.encryptionText]:
    "All data in transit and at rest is encrypted using strong protocols including TLS/SSL and AES.",
  [tokens.security.features.networkSegregationText]:
    "Daxap separates its systems into distinct networks to enhance the protection of sensitive data.",
  [tokens.security.features.accessToSystemsText]:
    "Daxap adheres to the concept of least privilege allowing only authorized access to systems and requires all employees to use Multi Factor Authentication (MFA).",
  [tokens.security.features.loggingMonitoringText]:
    "Access to Daxap’s network, systems and communications heavily protected, but additionally, all activity is logged and monitored to identify potential misuse of systems or information.",
  [tokens.security.features.respondingIncidentsText]:
    "Daxap’s incident management process is established to correctly identify, contain, investigate, and remediate incidents that threaten the security or confidentiality data.",
  [tokens.faq.securityAndPrivacyProgram]:
    "Does your organization have a security and privacy program and policies?",
  [tokens.faq.designatedLead]:
    "Do you have a designated security/privacy lead who manages your security program?",
  [tokens.faq.externalAudit]: "Is your platform security externally audited?",
  [tokens.faq.dataCenters]:
    "Do you have your own data centers? How do you store my data?",
  [tokens.faq.incidentDetectionResponse]:
    "How does your organization detect and respond to a security incident?",

  // Texts
  [tokens.faq.securityAndPrivacyProgramText]:
    "Yes. Daxap has established an Information Security Management System (ISMS) from the inception of the company and manages ISMS through policies, procedures and trusted service providers.",
  [tokens.faq.designatedLeadText]:
    "Daxap fulfills all the requirements of ISMS with the security team, led by our Chief Information Security Officer (CISO). The team implements and manages the security program.",
  [tokens.faq.externalAuditText]:
    "Yes, Daxap holds ISO 27001 certification, necessitating regular external audits of our information security management system and security controls.",
  [tokens.faq.dataCentersText]:
    "Daxap does not own its data centers. Instead, we make use of industry leading Cloud Services to store and discard your data. All data is fully encrypted and the systems are only accessible by people and services as defined in our ISMS.",
  [tokens.faq.incidentDetectionResponseText]:
    "Daxap has an Incident Management policy and procedures, as well as software to proactively detect incidents or threats of incidents. Our security team detects, defines, and responds to incidents as soon as it has been identified. In case of a security incident, or even the threat of one, our customers will receive information about the time, nature and cause of the incident, as well as a plan to ensure any future vulnerabilities are protected.",
  /* security-white-paper page */
  [tokens.whitePaper.introduction]: "Introduction",
  [tokens.whitePaper.organizationalSecurity]: "Organizational Security",
  [tokens.whitePaper.protectingCustomerData]:
    "Protecting Customer Data Security",
  [tokens.whitePaper.conclusion]: "Conclusion",

  // Texts
  [tokens.whitePaper.introductionText]:
    "Daxap’s mission is to create solutions that have a positive societal impact and make everyday life easier for both organizations and their customers. We believe that we need to make your data secure and protect your data. It is one of our most important responsibilities. We are dedicated to being open about our security measures and assisting you in comprehending our methods.",
  [tokens.whitePaper.organizationalSecurityText]:
    "Daxap has established an Information Security Management System (ISMS) from the start of the company and successfully achieved ISO-27001 Certification in September 2023. Daxap’s security program is aligned with ISO 27001 and is constantly evolving with other industry best practices. Daxap fulfills all the requirements of ISMS with the security team, led by our Chief Information Security Officer (CISO). The team implements and manages the security program. In this regard, there are 31 Policies, 16 Procedures, and 134 Evidence Tasks to implement the controls and clauses of ISO-27001. Daxap has also established an Asset Management System, Incident Management System, Risk Management System, etc., and executed personnel trainings and internal/external audits to manage ISMS.",
  [tokens.whitePaper.protectingCustomerDataText]:
    "As Daxap, we prioritize the security and integrity of your information. Establishing ISMS and achieving ISO-27001 Certification underscores our commitment to maintaining the highest standards of information security management, ensuring your data is protected with the utmost care and diligence.",
  [tokens.whitePaper.conclusionText]:
    "We have a fundamental commitment to safeguarding your data. We believe that protecting your data is a crucial duty we owe to our customers, and we persistently strive to uphold that trust. Please don’t hesitate to reach out to us for any concerns or questions.",
  [tokens.organizationalSecurity.statement1]:
    "Daxap shall continuously improve and align information security practices to global best practices and standards.",
  [tokens.organizationalSecurity.statement2]:
    "Daxap shall maintain the highest standards of information security management and ensure customers’ data is protected.",
  [tokens.organizationalSecurity.statement3]:
    "Information should be classified and handled according to its criticality and sensitivity as mandated by relevant legislative, regulatory and contractual requirements.",
  [tokens.organizationalSecurity.statement4]:
    "Appropriate contacts shall be maintained with relevant authorities, special interest groups, or other specialist security forums.",
  [tokens.organizationalSecurity.statement5]:
    "Requirements for confidentiality or non-disclosure agreements reflecting the organization’s needs for the protection of information shall be identified, regularly reviewed, and documented.",
  [tokens.organizationalSecurity.statement6]:
    "Any information shared by Daxap with prospects, prior to entering, and for the duration of, a contract, shall be done in accordance with the established confidentiality or non-disclosure agreements.",
  [tokens.organizationalSecurity.statement7]:
    "Daxap’s information asset inventories shall be reviewed and updated when a new asset is added and/or an existing asset is upgraded.",
  [tokens.organizationalSecurity.statement8]:
    "A risk assessment process for Daxap’s information assets shall be defined and followed. Risk treatment shall be carried out through the process of continuous improvement.",
  [tokens.organizationalSecurity.statement9]:
    "Internal assessments or audits of Daxap’s Information Security Program shall be performed periodically, and any gaps or findings shall be remediated promptly.",
  [tokens.organizationalSecurity.statement10]:
    "Information security policies shall be reviewed regularly by management. Daxap employees shall acknowledge their adherence to these information security policies and practices annually.",
  [tokens.organizationalSecurity.statement11]:
    "Roles and responsibilities of senior officials and staff shall be clearly defined and communicated to relevant individuals.",
  [tokens.organizationalSecurity.statement12]:
    "Anti-virus and anti-malware solutions shall be deployed on system components.",
  [tokens.organizationalSecurity.statement13]:
    "Prevention, detection, and recovery controls to protect against malware and phishing attacks shall be implemented by Daxap, and these will be combined with appropriate user awareness.",
  [tokens.organizationalSecurity.statement14]:
    "Security awareness trainings for employees shall be provided regularly.",
  [tokens.organizationalSecurity.statement15]:
    "Disaster Recovery Plans (DRPs) and Business Continuity Plans (BCPs) shall be reviewed and tested at least annually.",
  [tokens.organizationalSecurity.statement16]:
    "Change and vulnerability management controls shall be established and implemented.",
  [tokens.customerDataSecurity.securelyDesign]:
    "Securely Design - Daxap’s products are aimed to be developed securely from design to final stage. Daxap has two main policies for this purpose: “Software Development Policy” and “Secure Coding Policy”. Daxap shall maintain a robust secure development lifecycle and follow OWASP Secure Coding Practices.",
  [tokens.customerDataSecurity.dataInTransit]:
    "All data in transit between Daxap clients and the Daxap services is encrypted using strong protocols. Daxap employs the latest recommended secure cipher suites to encrypt all in-transit traffic, including Transport Layer Security (TLS), Internet Protocol Security (IPSec), Secure Shell (SSH), etc. whenever the clients support them.",
  [tokens.customerDataSecurity.dataAtRest]:
    "For data at rest, Daxap’s production network is encrypted using methods such as Advanced Encryption Standard (AES) or RSA. This applies to all types of data stored within Daxap’s systems, including relational databases, file stores, and database backups.",
  [tokens.customerDataSecurity.encryption]:
    "Encryption - Daxap uses AWS as its servers and data centers where secure environment and physical protection are provided.",
  [tokens.customerDataSecurity.networkSegregation]:
    "Network Segregation and Security - Daxap separates its systems into distinct networks to enhance the protection of sensitive data. Systems used for testing and development are hosted in a different network from those supporting Daxap’s production infrastructure. Access to Daxap’s production environment from open, public networks (the Internet) is restricted. Daxap logs, monitors, and audits all system activities and establishes alerting within provider capabilities for all potential threats.",
  [tokens.customerDataSecurity.accessToSystems]:
    "Access to Systems - While granting access, Daxap adheres to the concept of least privilege and “deny all” by default, allowing only authorized access to systems accordingly with roles and responsibilities, to prevent any kind of data leakage.",
  [tokens.customerDataSecurity.multiFactorAuthentication]:
    "Daxap requires all employees to use Multi-Factor Authentication (MFA) to log into all systems where possible and appropriate.",
  [tokens.customerDataSecurity.passwordManager]:
    "Daxap mandates that staff utilize an authorized password manager which creates, saves, and inputs distinct and complex passwords to prevent password reuse, phishing attacks, and other password-related threats.",
  [tokens.customerDataSecurity.loggingMonitoring]:
    "Logging and monitoring the systems - Access to Daxap’s network, systems, and communications shall be logged and monitored to identify potential misuse of systems or information. Logging activities shall include regular monitoring of system access to prevent attempts at unauthorized access and confirm access control systems are effective. Log servers and documents shall be kept secure and only made available to authorized personnel. These logs shall be retained as long as necessary or required for functional use or appropriate state regulation or law.",
  [tokens.customerDataSecurity.dataRetention]:
    "Data Retention and disposal - Disposal of customer data will be carried out in accordance with the contractual agreement between Daxap and the customer. In the absence of any contractual agreement, an automatic script shall be initiated on any Daxap platform containing customer data. This activates a full hard delete of customer data on the platform. It is the responsibility of Daxap’s hosting providers to ensure that data is properly removed from disks before they are reused.",
  [tokens.customerDataSecurity.securityIncidents]:
    "Responding to Security Incidents - Daxap has established an incident management process to correctly identify, contain, investigate, and remediate incidents that threaten the security or confidentiality of Daxap’s information assets. All security incidents are managed by Daxap’s dedicated personnel. As needed, the security incidents would be reported outside of Daxap immediately by the designated person.",
  [tokens.customerDataSecurity.vendorManagement]:
    "Third-Party Providers (Vendor) Management - Daxap depends on third-party organizations/apps to operate efficiently. Since these third-party services could affect the security of Daxap’s production environment, we take necessary measures to maintain our security standards by creating agreements that obligate these service organizations to uphold the confidentiality commitments. Daxap ensures the effectiveness of these organizations' safeguards by reviewing their controls before engagement and at least once a year.",
  [tokens.customerDataSecurity.thirdPartyValidation]:
    "Third-Party Validation - Daxap continuously monitors and improves the effectiveness of ISMS activities. These audits are performed not only by internal auditors but also by third-party credentialed assessors. Those internal and external audit results are shared with senior management and tracked to resolution.",
  /* privacy-policy page */
  [tokens.privacyPolicy.title]: "Privacy statement",
  [tokens.privacyPolicy.introduction]:
    " Are you interested in how your personal information is protected in this website? Then you&apos;ve come to the right place! Below you will be able to read how we in daxap.io handles personal information, as well as obtain information about your rights as registered with us. We hope you find what you are looking for here. You can of course also contact us, if you have questions about our processing of your information. We are concerned about security and transparency in this area, and we take your inquiries seriously!",
  [tokens.privacyPolicy.treatmentManager.heading]: "Treatment manager :",
  [tokens.privacyPolicy.treatmentManager.name]: "Name :",
  [tokens.privacyPolicy.treatmentManager.contactPerson]: "Contact Person :",
  [tokens.privacyPolicy.treatmentManager.telephone]: "Telephone :",
  [tokens.privacyPolicy.treatmentManager.address]: "Address :",
  [tokens.privacyPolicy.processingPurpose]: "Why do we process personal data?",
  [tokens.privacyPolicy.processingPurposeText]:
    "We collect and process personal information in order to offer visitors to our websites the services and products they request. We also want to offer better and more relevant content to the individual customer, as well as create good and long-lasting relationships with our existing customers.",
  [tokens.privacyPolicy.processingWhen]: "When do we process personal data?",
  [tokens.privacyPolicy.processingWhenText]:
    "Below you will find a list of when and how we process personal data in the individual cases. Note that all processing is based on consent, with the exception of the collection of data at the time of purchase, where certain information must be provided in order to complete the purchase. In this case, the basis for processing is the conclusion of an agreement.",
  [tokens.privacyPolicy.newsletter.heading]: "Newsletter :",
  [tokens.privacyPolicy.newsletter.where]: "Where: Newsletter",
  [tokens.privacyPolicy.newsletter.storedData]:
    "What is stored: E-mail address.",
  [tokens.privacyPolicy.newsletter.usage]:
    " Use of personal information: To send out newsletters.",
  [tokens.privacyPolicy.newsletter.storageTime]:
    " Storage time: For an indefinite time. Until you unsubscribe from the newsletter or request deletion.",
  [tokens.privacyPolicy.newsletter.basis]: "Basis for treatment: Consent.",
  [tokens.privacyPolicy.newsletter.safety]:
    " Safety: The information is stored on a secure server. Contact us for further information on safety.",
  [tokens.privacyPolicy.newsletter.dataProcessor]:
    "Data processor (third party): –",
  [tokens.privacyPolicy.contactForm.heading]:
    "Contact form / customer inquiries :",
  [tokens.privacyPolicy.contactForm.where]:
    "Where: Contact form in the website",
  [tokens.privacyPolicy.contactForm.storedData]:
    "What is stored: First name, last name, telephone number, e-mail + inquiry.",
  [tokens.privacyPolicy.contactForm.usage]:
    "Use of personal information: The information is used to be able to respond to your inquiry. If you have previously submitted an inquiry registered with the same name, telephone number or e-mail address, we will be able to see your inquiry history. We do this to be able to answer your questions better, especially if they are related to previous inquiries or purchases.",
  [tokens.privacyPolicy.contactForm.storageTime]:
    "Storage time: The information associated with your inquiries is stored for as long as is necessary to be able to respond to your inquiries.",
  [tokens.privacyPolicy.contactForm.basis]: "Basis for treatment: Consent.",
  [tokens.privacyPolicy.contactForm.safety]:
    "Safety: The information is stored on a secure server.Contact us for further information on safety.",
  [tokens.privacyPolicy.contactForm.dataProcessor]:
    "Data processor (third party): tawk.to, ClickUp",
  [tokens.privacyPolicy.cookies]: "Cookies :",
  [tokens.privacyPolicy.cookiesText]:
    "This website uses cookies, or so-called cookies, to give you a better user experience in all channels. Use of cookies is based on consent through cookie settings in your browser, as well as your continued use of our pages. Are you curious about our use of cookies, as well as how you control the use of these?",
  [tokens.privacyPolicy.processingBasis]:
    "What is the website's processing basis?",
  [tokens.privacyPolicy.processingBasisText]:
    "DAXAP AS processes certain types of personal information. You will find the types of data we process in the overview above. Our basis for treatment is in most cases consent, with one exception. This means that we collect and process personal data based on the consent of the data subject.",
  [tokens.privacyPolicy.rightsAccess]:
    "Your rights access to your own personal information:",
  [tokens.privacyPolicy.rightsAccessText]:
    "If you are registered in our systems, you have the right to access the data we have about you, should you wish to do so. This means that you can have the data sent to you, or you can be informed where you can find this data. In some cases, we may need to request additional information about you in order to verify your identity before you can gain access. Contact the treatment manager if access is desired.",
  [tokens.privacyPolicy.rightsCorrection]:
    "Correction of own personal information:",
  [tokens.privacyPolicy.rightsCorrectionText]:
    "If you are registered in our systems, and perceive that we have registered incorrect information about you, you have the right to have this data corrected, or you can have access to correct this yourself. In some cases, we may need to request additional information about you in order to verify your identity before you can gain access. Contact the treatment manager if correction is desired.",
  [tokens.privacyPolicy.rightsDeletion]:
    "Deletion of own personal information:",
  [tokens.privacyPolicy.rightsDeletionText]:
    "If you are registered in our systems, but want us to delete the information we have about you, you have the right to do so. If you want to delete your data, please contact us via e-mail address post@daxap.io. We reserve the right that in some cases there may be reasons why the data can not be deleted immediately, if you have entered into a contract with us, and this data is necessary for the maintenance of this contract. In some cases, we may need to request additional information about you in order to verify your identity before you can gain access. Contact the data controller if deletion is desired.",
  [tokens.privacyPolicy.rightsRestriction]:
    "Restrict processing of own personal data:",
  [tokens.privacyPolicy.rightsRestrictionText]:
    "If you are registered in our systems, and wish to limit the processing of your personal data, you have the right to request this. You can request that certain forms of treatment be discontinued, or you can request that we end all treatment. We reserve the right that in some cases there may be legal reasons why such restriction of treatment can not be performed. Deletion of data can then be an alternative, if a general restriction is not possible according to current regulations. In some cases, we may need to request additional information about you in order to verify your identity before we can make the changes. Contact the treatment manager if limitation of treatment is desired.",
  [tokens.privacyPolicy.dataPortability]: "Data portability:",
  [tokens.privacyPolicy.dataPortabilityText]:
    " If you are registered in our systems, and the collection and processing of this personal information is performed automatically and is based on consent, you have the right to transfer this information to other suppliers. You can request this from us, and you then have the right to receive this information in a structured, commonly used and machine-readable format. You may also have the right to have this data transferred directly from us to another provider, if this is technically possible. In some cases, we may need to request additional information about you in order to verify your identity before we can do so. Contact the data controller if transfer or transfer of your personal information is desired.",
  [tokens.privacyPolicy.withdrawConsent]:
    "Withdraw consent to the processing of own personal data:",
  [tokens.privacyPolicy.withdrawConsentText]:
    " With the exception of information you provide in connection with the purchase, all processing of personal data by Daxap AS is based on consent. You have the right to withdraw your consent at any time, and the processing of this personal data for the relevant purpose shall then cease. If the information is only used for the purpose for which you withdraw your consent, the information must also be deleted. In some cases, we may need to request additional information about you in order to verify your identity before we can do so. Contact the data controller if you wish to withdraw your consent to one or more processing of personal data.",
  [tokens.privacyPolicy.complaint]:
    "Complaint about processing of own personal data:",
  [tokens.privacyPolicy.complaintText]:
    "If you wish to complain about the way your information is processed, or anything else related to your personal information, you have the right to do so. We hope you want to send an e-mail or otherwise contact the data controller (the contact information for the data controller can be found at the top of this page). The data controller is responsible for safeguarding your rights, and helps you with whatever you may be wondering about. You also have the right to contact the Norwegian Data Protection Authority if you disagree with decisions or treatment methods at Daxap AS.",
  /* cookie-policy page */
  [tokens.cookiePolicy.title]: "What is a cookie and what are they used for?",
  [tokens.cookiePolicy.description]:
    "A cookie is a small text file in alphanumeric format installed on the user's device by the server of the visited website, or by a third-party server (web analytics service, etc...). When you visit the Daxap website, cookies are stored in your browser. We use this information to: We also take security and privacy into account, and ensure that your application follows current rules and guidelines. If you want to develop your own application or learn more about our apps, you can contact us for a non-binding chat about your needs and possibilities. We look forward to helping you realize your ideas and improve your digital presence.",
  [tokens.cookiePolicy.userAccount]:
    "Know which user account is logged in to be able to display the correct data for the user.",
  [tokens.cookiePolicy.googleAnalytics]:
    "Google analytics to get statistics on traffic to the website",
  [tokens.cookiePolicy.marketing]: "Marketing",
  [tokens.cookiePolicy.cookiesListTitle]: "List of cookies used on the website",
  [tokens.cookiePolicy.noCookies]: "No",
  [tokens.cookiePolicy.thirdPartyCookiesTitle]:
    "Cookies issued by third parties",
  [tokens.cookiePolicy.thirdPartyCookiesDescription]:
    "Cookies may be issued on our website by third parties as a result of integrated third-party applications on our website, via third-party content. With these cookies, you can: Recognize the device while surfing on all other websites or services and which advertisers or third parties issue cookies adapt the content to your terminal.",
  [tokens.cookiePolicy.marketingCookiesTitle]:
    "Cookies we use for marketing and what they record:",
  [tokens.cookiePolicy.googleAnalyticsTitle]: "Google Analytics",
  [tokens.cookiePolicy.googleAnalyticsDescription]:
    "For a better user experience. An analysis tool that records the user pattern on daxap.io, so that we better understand how you use our websites. The data is used to improve the website and your user experience. On Google's own pages, you can read more about how they collect and protect data.",
  [tokens.cookiePolicy.googleConversionTitle]: "Google Conversion Tracking",
  [tokens.cookiePolicy.googleConversionDescription]:
    "Records what happens after you have clicked on one of our advertisements. Enables us to adapt the user experience on our landing pages better for you and yours.",
  [tokens.cookiePolicy.googleRemarketingTitle]: "Google Dynamic Remarketing",
  [tokens.cookiePolicy.googleRemarketingDescription]:
    "Collects information that is used to show you customized ads based on which parts of daxap.io you have visited.",
  [tokens.cookiePolicy.facebookTitle]:
    "Facebook Connect & Facebook Custom Audience",
  [tokens.cookiePolicy.facebookDescription]:
    "Used to verify whether you have a Facebook profile, to be able to reach you with Facebook ads after you have visited daxap.io, as well as to measure how the ads work. The data is anonymised and no personal information is recorded. Read more about Facebook's privacy policy and how you can change it in your settings.",
  [tokens.cookiePolicy.linkedinTitle]:
    "LinkedIn Ads & LinkedIn Marketing Solutions",
  [tokens.cookiePolicy.linkedinDescription]:
    "Enables us to create targeted advertisements on LinkedIn against previous visitors to daxap.io. The purpose is to create more relevant advertisements for the individual user based on previous areas of interest on daxap.io. These capsules do not record any personal information, and all data is anonymised. Read more about how you can manage your privacy settings.",
  [tokens.cookiePolicy.googleTagManagerTitle]: "Google Tag Manager",
  [tokens.cookiePolicy.googleTagManagerDescription]:
    "Used to organize and publish scripts and tags on the website.",
  [tokens.cookiePolicy.tawkToTitle]: "Tawk.to",
  [tokens.cookiePolicy.tawkToDescription]:
    "Used to communicate on the website.",
  [tokens.cookiePolicy.acceptRejectTitle]: "Accept/Reject cookies",
  [tokens.cookiePolicy.acceptRejectDescription]:
    "You can at any time choose to accept or reject the installation of cookies on your terminal by configuring your browser.",
  [tokens.cookiePolicy.acceptCookiesTitle]: "If you accept cookies",
  [tokens.cookiePolicy.acceptCookiesDescription]:
    "The registration of a cookie in your computer or telephone is subject to your will. You can express and change your choices at any time and free of charge through the options available through your browser. If you have accepted the storage of cookies in your device in your browser, the integrated cookies on pages and content you have visited will be temporarily stored in a dedicated space in your device. They will only be made visible to the sender.",
  [tokens.cookiePolicy.benefitsTitle]:
    "What benefit do I get from accepting cookies?",
  [tokens.cookiePolicy.benefitsDescription]:
    "When you visit the Daxap website, these cookies store information on your device. We use this information, among other things, to offer products in accordance with those you have selected on previous visits, and therefore make navigation on our website easier.",
  [tokens.cookiePolicy.rejectCookiesTitle]: "If you reject cookies",
  [tokens.cookiePolicy.rejectCookiesDescription]:
    "If you reject cookies in the terminal, or if you remove those registered, you cannot take advantage of a number of functions that are still necessary to navigate certain parts of our website. This will be the case if you are trying to access content or services that require a login. It is also the case when we or our suppliers cannot recognize (for technical accounting) the type of browser used by the terminal, settings and display language or the country in which the device appears to be connected to the internet. In this case, the company Daxap AS disclaims all responsibility for the consequences of reduced operation of our services when it is impossible for us to store or display necessary cookies that you have rejected or deleted.",
  [tokens.cookiePolicy.browserSettingsTitle]: "Setting your browser",
  [tokens.cookiePolicy.browserSettingsDescription]:
    "Setting the use of cookies depends on the browser. It is described in the Help menu of your browser.",
  [tokens.cookiePolicy.ieSettingsTitle]: "For Internet Explorer",
  [tokens.cookiePolicy.ieSettingsDescription]:
    "To adapt the settings for cookies to a website In Internet Explorer, click Tools and then Options for the Internet. Click on the Privacy tab, then Websites Type the full address (URL) of the website you want to customize the privacy settings in the address column. For example http://www.microsoft.com. If you want to allow cookies from the specified website on your computer, click Allow. To prevent the storage of cookies from the specified website. on your computer, click Decline. Repeat steps 3 and 4 for each website to block or allow. When finished, click OK twice.",
  [tokens.cookiePolicy.safariSettingsTitle]: "For Safari",
  [tokens.cookiePolicy.safariSettingsDescription]:
    "Go to Favorites and then click on the Privacy and Cookie settings category.",
  [tokens.cookiePolicy.chromeSettingsTitle]: "For Chrome",
  [tokens.cookiePolicy.chromeSettingsDescription]:
    "Click on the wrench icon on the browser toolbar. Select Settings. Click Show advanced settings. In the 'Privacy' section, click the Content Settings button. In the 'Cookies' section, you can change the following parameters: Remove cookies Block cookies automatically Accept cookies automatically Define exceptions for cookies from certain websites or areas.",
  [tokens.cookiePolicy.firefoxSettingsTitle]: "For Firefox",
  [tokens.cookiePolicy.firefoxSettingsDescription]:
    "Click Tools, then Options. Click on the heading Privacy. In the Retention the box to accept cookies, and then click to select websites that always or never have approval to install cookies on your terminal.",
  [tokens.cookiePolicy.operaSettingsTitle]: "For Opera",
  [tokens.cookiePolicy.operaSettingsDescription]:
    "Favorites - Advanced - Cookies - the way Opera handles cookies. By default, all cookies are accepted. Accept cookies All cookies are allowed (default) Only accept cookies from visited websites Third-party cookies, from a website other than the one I have visited, must be rejected. Never accept cookies All cookies are rejected",
  /* Sustainability Page */
  [tokens.title]: "Sustainability",
  [tokens.subtitle1]:
    "Daxap’s Commitment to Sustainability and Environmental Responsibility",
  [tokens.description1]:
    "At Daxap, we believe that technology should drive positive change—not only for our clients and society but also for the environment. Our dedication to sustainable development is central to everything we do, from the services we provide to the way we operate internally. As part of our ongoing commitment, we aim to make our work transparent and accessible, sharing how we contribute to a more sustainable world.",
  [tokens.subtitle2]: "Our Environmental and Climate Goals",
  [tokens.description2]:
    "Aligned with the United Nations' Sustainable Development Goals (SDGs), particularly Goal 12.6, we are committed to adopting sustainable practices and integrating these into our operations. We’ve implemented multiple initiatives to reduce our environmental footprint, including energy-efficient development processes, green coding practices, and the promotion of eco-friendly workplace habits.",
  [tokens.ongoingEffortsTitle]: "Ongoing Efforts and Achievements",
  [tokens.transparencyTitle]: "Transparency and Accountability",
  [tokens.transparencyDescription]:
    "We believe in transparency. As part of this, we openly share our annual sustainability reports, including our climate accounting and the outcomes of our sustainability initiatives. You can access detailed information about our environmental impact and ongoing projects through our sustainability reports, available on this page. For more information on Daxap’s environmental efforts or to request a copy of our sustainability reports, contact us directly. Together, we can build a better, greener future through technology.",
  [tokens.energyEfficiencyTitle]: "Energy Efficiency",
  [tokens.energyEfficiencyText]:
    "We focus on reducing the energy consumption of the digital services we develop for clients. This includes optimizing our infrastructure and ensuring that we follow best practices for energy-efficient programming.",
  [tokens.sustainableDevelopmentTitle]: "Sustainable Practices in Development",
  [tokens.sustainableDevelopmentText]:
    "Our team consistently strives to minimize the environmental impact of software development. This involves using technologies and methods that are not only innovative but also mindful of their energy consumption and overall sustainability.",
  [tokens.employeeEngagementTitle]: "Employee Engagement",
  [tokens.employeeEngagementText]:
    "We foster a work culture that emphasizes environmental awareness. All Daxap employees are trained on sustainability best practices, and we encourage active participation in our green initiatives.",
  /* App Dev Page */
  [tokens.appDevelopment.sidebarTitle]: "App Development",
  [tokens.appDevelopment.title]:
    "Daxap App Development – From MVP to Top Rated Applications",
  [tokens.appDevelopment.description]:
    "From developing our own Software as a Service (SaaS) platforms to creating Applications for our customers, we create scalable, safe, and user-friendly products.",
  [tokens.appDevelopment.feature1]:
    "Complete in-house development team, from Product Owners (PO) to front-end and back-end developers, testers, user interface (UI) and user experience (UX) designers.",
  [tokens.appDevelopment.feature2]:
    "Daxap’s ISO 27001 Information Security Management Systems (ISMS) certification means that all apps are built to the highest security standards, including GDPR.",
  [tokens.appDevelopment.feature3]:
    "Seamless integration with all iOS, Android and Windows operating systems and global app stores.",
  [tokens.appDevelopment.feature4]:
    "Integration of Application Programming Interfaces (API’s), from payment solutions to HR and calendar integrations.",
  [tokens.appDevelopment.feature5]:
    "Utilizing the Agile-Scrum methodology ensures that changes in direction and updates can be made swiftly without large budgetary timeline setbacks.",
  [tokens.appDevelopment.ourApps]: "Our Apps",
  /* Web Dev Page */
  [tokens.webDevelopment.sidebarTitle]: "Web Development",
  [tokens.webDevelopment.title]:
    "Daxap Web Development – Scalable Solutions That Grow With Your Needs",
  [tokens.webDevelopment.description]:
    "Daxap creates platforms that simplifies how the end user interacts with our websites, while integrating systems that saves time and money for our customers.",
  [tokens.webDevelopment.feature1]:
    "Complete in-house development team, from Product Owners (PO) to front-end and back-end developers, testers, user interface (UI) and user experience (UX) designers.",
  [tokens.webDevelopment.feature2]:
    "Daxap’s ISO 27001 Information Security Management Systems (ISMS) certification means that all apps are built to the highest security standards, including GDPR.",
  [tokens.webDevelopment.feature3]:
    "Integration of Application Programming Interfaces (API’s), from payment solutions to HR and calendar integrations.",
  [tokens.webDevelopment.feature4]:
    "Utilizing the Agile-Scrum methodology ensures that changes in direction and updates can be made swiftly without large budgetary timeline setbacks.",
  [tokens.webDevelopment.ourWebApps]: "Our Web Apps",
  /* QA Page */
  [tokens.qaDevelopment.sidebarTitle]: "Quality Assurance",
  [tokens.qaDevelopment.title]:
    "Daxap Quality Assurance – Protecting Your Digital Products.",
  [tokens.qaDevelopment.description]:
    "Our Quality Assurance (QA) services are tailored to meet the specific needs of your project, providing meticulous attention to detail and a comprehensive approach to testing.",
  [tokens.qaDevelopment.feature1]:
    "Our QA service is tailored to meet the specific needs of your project, providing meticulous attention to detail and a comprehensive approach to testing.",
  [tokens.qaDevelopment.feature2]:
    "We take pride in delivering QA solutions that are aligned with your brand's standards and expectations. Our team of experienced QA professionals employs industry-leading tools and methodologies to thoroughly assess the functionality, usability, and performance of your software.",
  [tokens.qaDevelopment.feature3]:
    "In addition to manual testing, we also specialize in API and automation testing to ensure the seamless integration and functionality of your digital products across platforms. Our automated testing processes enable quick and efficient validation of software functionality, saving time and resources while maintaining high-quality standards.",
  [tokens.qaDevelopment.feature4]:
    "With our QA service, you can trust that your digital products will meet the highest standards of quality, ensuring a seamless user experience and driving the success of your business. Let us help you achieve excellence in software quality assurance and elevate your digital presence to new heights.",
  /* Portfolio Page */
  [tokens.ourPortfolio.subtitle]: "Portfolio",
  [tokens.ourPortfolio.title]: "Innovative Digital Solutions",
  [tokens.ourPortfolio.description]:
    "At Daxap, we take pride in our ability to deliver tailored digital solutions that not only meet but exceed our clients’ expectations. Our development process is built on the Agile-Scrum methodology, an approach that gives us the flexibility to adapt quickly and efficiently to changes while continuously delivering functionality and value. This method has proven particularly effective in meeting today’s demands for the rapid development and launch of digital products.",
  [tokens.ourPortfolio.contactWithUs]: "GET IN TOUCH",
  [tokens.ourPortfolio.whyChooseDaxap.subtitle]: "Experts In Development",
  [tokens.ourPortfolio.whyChooseDaxap.title]: "Why Choose Daxap",
  [tokens.ourPortfolio.whyChooseDaxap.feature1Title]: "Agile-Scrum",
  [tokens.ourPortfolio.whyChooseDaxap.feature1Text]:
    "By breaking down the development work into smaller, manageable sprints, we can regularly deliver new features and improvements, allowing our clients to see and test new parts of the product.",
  [tokens.ourPortfolio.whyChooseDaxap.feature2Title]:
    "A Dedicated and Diverse Team",
  [tokens.ourPortfolio.whyChooseDaxap.feature2Text]:
    "The Daxap team consists of skilled professionals across various disciplines, including Product Owners (PO), Backend Engineers (BE), Frontend Engineers (FE), App Developers, Testers, and DevOps Engineers.",
  [tokens.ourPortfolio.whyChooseDaxap.feature3Title]: "ISO 27001",
  [tokens.ourPortfolio.whyChooseDaxap.feature3Text]:
    "Quality and security are fundamental elements of our development process. Daxap is proud to be ISO 27001 certified, a globally recognized standard that ensures we follow strict protocols for information security.",
  [tokens.ourPortfolio.whyChooseDaxap.feature4Title]:
    "Efficient Project Management",
  [tokens.ourPortfolio.whyChooseDaxap.feature4Text]:
    "To ensure a seamless workflow, we use modern tools such as Figma for UI/UX design and ClickUp for project management. Figma enables our design team to create intuitive and user-friendly interfaces.",
  [tokens.ourPortfolio.whyChooseDaxap.feature5Title]: "Function-Based Delivery",
  [tokens.ourPortfolio.whyChooseDaxap.feature5Text]:
    "At Daxap, it’s not just about delivering a product, but about building a long-term partnership with our clients. We are not a typical consultancy that bills by the hour.",
  [tokens.ourPortfolio.whyChooseDaxap.feature6Title]: "A Flexible Partner",
  [tokens.ourPortfolio.whyChooseDaxap.feature6Text]:
    "In addition to offering development services, Daxap is also open to the possibility of becoming co-owners in projects where this is desirable. We understand that some projects have unique potential.",
  [tokens.ourPortfolio.portfolioOurProjects.subtitle]: "Our Projects",
  [tokens.ourPortfolio.portfolioOurProjects.title]:
    "Diverse People, Diverse Projects. Check Out What We’ve Built",
  /* Contact Us Page */
  [tokens.contactUs.ourOfficeAddress]: "Our Office Address",
  [tokens.contactUs.daxapHeadquarters]: "Daxap Headquarters",
  [tokens.contactUs.daxapOslo]: "Daxap Oslo",
  [tokens.contactUs.sectionTitle]: "GET IN TOUCH",
  [tokens.contactUs.mainTitle]: "Let’s Make It Happen",
  [tokens.contactUs.description]:
    "Ready to start? Simply fill out the form, and we’ll get back to you.",
  [tokens.contactUs.form.namePlaceholder]: "Your Full Name *",
  [tokens.contactUs.form.emailPlaceholder]: "Email *",
  [tokens.contactUs.form.messagePlaceholder]: "Message *",
  [tokens.contactUs.form.sendMessage]: "Send",
  [tokens.contactUs.submissionReceived.title]:
    "Your submission has been received.",
  [tokens.contactUs.submissionReceived.message]:
    "We will get back to you as soon as possible.",
  [tokens.contactUs.errors.fullName]: "Please enter a valid full name",
  [tokens.contactUs.errors.validEmail]: "Please enter a valid email address",
  [tokens.contactUs.errors.requiredField]: "This field is required",
  /* blog detail page */
  [tokens.tags]: "Tags :",
  [tokens.share]: "Share :",
  [tokens.recentPosts]: "Recent Posts :",
  /* Kiddy Detail Page */
  [tokens.kiddy.appName]: "Kiddy",
  [tokens.kiddy.projectInfo]: "Project Information",
  [tokens.kiddy.client]: "Client:",
  [tokens.kiddy.clientText]: "Institutions with child and parents",
  [tokens.kiddy.date]: "Date:",
  [tokens.kiddy.category]: "Category:",
  [tokens.kiddy.categoryText]: "Education & Communication App",
  [tokens.kiddy.projectDesc]: "Project Description",
  [tokens.kiddy.overview]: "Overview",
  [tokens.kiddy.overviewText]:
    "Kiddy is a multilingual communication platform and app that simplifies communication between parents and institutions such as schools, childcare and sports clubs. With Kiddy, users can track important information, such as schedules, child progress, and daily activities, all in one place. The Kiddy app brings convenience and peace of mind to parents, ensuring they can stay connected to their child's care wherever they are.",
  [tokens.kiddy.challenges]: "Challenges",
  [tokens.kiddy.challengesText]:
    "Parents and guardians, especially those with immigrant backgrounds, find it challenging to communicate effectively with institutions such as schools, daycares and sports clubs. This has a knock-on effect on the ability for children to feel included.",
  [tokens.kiddy.solutions]: "Solutions",
  [tokens.kiddy.solutionsText]:
    "Kiddy’s app interface was designed with simplicity and accessibility in mind. We focused on user friendly design, intuitive navigation, and multilingual functions. Additionally, real-time notifications were integrated to keep parents informed about their child’s activities. The web/admin module was also designed to save time for employees and administrators alike.",
  [tokens.kiddy.outcomes]: "Outcomes",
  [tokens.kiddy.outcomesText]:
    "Kiddy has received excellent feedback from both parents and caregivers. The app has been praised for its ease of use and how it streamlines communication between families and employees. In a survey conducted by research organization SINTEF, 77% of parents said Kiddy made communication a lot better, and 83% said the multilingual feature helped them receive information they would not otherwise have understood.",
  [tokens.kiddy.keyFeatures]: "Key Features of Kiddy",
  [tokens.kiddy.dailyActivity]: "Daily Activity Tracking",
  [tokens.kiddy.dailyActivityText]:
    "Parents can view photos, nap times, and activities throughout the day.",
  [tokens.kiddy.realTime]: "Real-Time Updates",
  [tokens.kiddy.realTimeText]:
    "Employees can send updates, messages and photos to parents in real-time.Remove “Kiddy realTimeText”? ",
  [tokens.kiddy.childProgress]: "Child Progress Reports",
  [tokens.kiddy.childProgressText]:
    "Regular reports on a child’s development and milestones.",
  [tokens.kiddy.safeSecure]: "Safe and Secure",
  [tokens.kiddy.safeSecureText]:
    "All personal data is encrypted and stored securely.",
  [tokens.kiddy.testimonial]: "Testimonial",
  /* Vibori Detail Page */
  [tokens.vibori.appName]: "Vibori",
  [tokens.vibori.projectInfo]: "Project Information",
  [tokens.vibori.client]: "Client:",
  [tokens.vibori.clientText]: "Municipalities and communities",
  [tokens.vibori.date]: "Date:",
  [tokens.vibori.category]: "Category:",
  [tokens.vibori.categoryText]: "Community Engagement App",
  [tokens.vibori.projectDesc]: "Project Description",
  [tokens.vibori.overview]: "Overview",
  [tokens.vibori.overviewText]:
    "Vibori was designed to keep residents informed and engaged with activities in their local community. With the Vibori app, users can access important updates, news, and events in their municipality. Vibori makes it easy to browse upcoming activities and select specific interests to receive notifications about events in their area. With a focus on creating a connected and informed community, Vibori is helping break down the engagement challenges experienced across local communities across the world.",
  [tokens.vibori.challenges]: "Challenges",
  [tokens.vibori.challengesText]:
    "Local communities are struggling to engage their residents in local events, activities and volunteer work. Because of the algorithms on platforms such as Facebook and Instagram, grassroots organizers and municipalities are struggling to reach their residents, especially those from immigrant backgrounds who may not speak the native language.",
  [tokens.vibori.solutions]: "Solutions",
  [tokens.vibori.solutionsText]:
    "Vibori implemented an intuitive design that includes a built-in translation feature, making the app accessible to everyone. The event calendar is easy to navigate, and users can filter events by category, ensuring they find relevant content effortlessly. The app also allows users to follow specific organizations and select interests to receive tailored notifications about events and updates.",
  [tokens.vibori.outcomes]: "Outcomes",
  [tokens.vibori.outcomesText]:
    "While Vibori is a brand-new solution, we have already started making an impact in local communities by engaging municipalities and organizers, allowing them one central location to communicate and engage with locals. Vibori also has an Impact Management Plan, where the social impact will be measured as the platform grows.",
  [tokens.vibori.keyFeatures]: "Key Features of Vibori",
  [tokens.vibori.localNews]: "Local News",
  [tokens.vibori.localNewsText]:
    "Stay updated with the latest local news and important updates from your municipality.",
  [tokens.vibori.eventCalendar]: "Event Calendar",
  [tokens.vibori.eventCalendarText]:
    "Browse and filter events by category, such as family, culture, and sports events, and add them to your personal calendar.",
  [tokens.vibori.followOrg]: "Follow Organizations",
  [tokens.vibori.followOrgText]:
    "Receive real-time notifications from organizations you follow, ensuring you never miss an important update or event.",
  [tokens.vibori.translateFeature]: "Translation Feature",
  [tokens.vibori.translateFeatureText]:
    "Access all content in multiple languages, breaking down language barriers and including everyone in the community.",
  [tokens.vibori.mapInt]: "Map Integration",
  [tokens.vibori.mapIntText]:
    "Find local events and activities using the map feature, helping you easily locate nearby happenings.",
  /* Malimo Detail Page */
  [tokens.malimo.appName]: "Malimo",
  [tokens.malimo.projectInfo]: "Project Information",
  [tokens.malimo.client]: "Client:",
  [tokens.malimo.clientText]: "Malimo",
  [tokens.malimo.date]: "Date:",
  [tokens.malimo.dateText]: "Educational Resources",
  [tokens.malimo.place]: "Place:",
  [tokens.malimo.placeText]: "Steinkjer, Norway",
  [tokens.malimo.projectDesc]: "Project Description",
  [tokens.malimo.overview]: "Overview",
  [tokens.malimo.overviewText]:
    "Malimo is a leading provider of educational materials and resources designed for students from grades 1 to 7, special education, and child care. The goal of Malimo is to foster motivation through mastery and ensure that every student succeeds at their own level. By offering an extensive library of resources, Malimo aims to simplify the workload for teachers, enabling them to spend more time on teaching and less on administrative duties.",
  [tokens.malimo.challenges]: "Challenges",
  [tokens.malimo.challengesText]:
    "The main challenge was to create a platform that not only provided a wide variety of learning resources but also catered to different learning levels and needs, from regular classroom settings to special education. Additionally, Malimo needed to ensure that the platform would be user-friendly for both teachers and students.",
  [tokens.malimo.solutions]: "Solutions",
  [tokens.malimo.solutionsText]:
    "Malimo offers a growing library of over 250 free resources and more than 2,500 products, all designed to enhance learning. Their approach is based on the 'Malimo pedagogy,' which emphasizes that everyone can learn, just in different ways and at different paces. The platform includes ready-to-use materials, editable templates, and resources available in Norwegian, English, and other languages, ensuring accessibility and adaptability for all users.",
  [tokens.malimo.outcomes]: "Outcomes",
  [tokens.malimo.outcomesText]:
    "With more than 72,000 active users, Malimo has been highly successful in providing teachers and educators with high-quality, time-saving resources. Teachers report that using Malimo’s materials not only saves them time but also engages students in new and creative ways. The positive feedback from users reflects the significant impact Malimo has had on the educational landscape in Norway.",
  [tokens.malimo.keyFeatures]: "Key Features of Malimo",
  [tokens.malimo.testimonial]:
    "Daxap has been essential for us. We have received a technical solution that allows us to grow into the future, with stability and smooth user experience. They possess solid expertise and have solved all challenges along the way. Additionally, they are great people who go the extra mile for us on all levels.",
  [tokens.malimo.testimonialAuthor]: "Maren Lie Malmo",
  [tokens.malimo.testimonialPosition]: "Founder, Malimo",
  [tokens.malimo.pedagogical]: "Pedagogical Framework",
  [tokens.malimo.pedagogicalText]:
    "Malimo’s educational philosophy centers on the belief that every child can succeed, with a focus on individualized learning and motivation through mastery.",
  [tokens.malimo.extensive]: "Extensive Resource Library",
  [tokens.malimo.extensiveText]:
    "Access over 250 free resources, and thousands of educational products covering multiple subjects and learning levels.",
  [tokens.malimo.inclusive]: "Inclusive Learning",
  [tokens.malimo.inclusiveText]:
    "Materials are designed to support special education, child care, and students with diverse learning needs.",
  /* Norsk Luftfartsmuseum Detail Page */
  [tokens.norskLuftfartsmuseum.appName]: "Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.projectInfo]: "Project Information",
  [tokens.norskLuftfartsmuseum.client]: "Client:",
  [tokens.norskLuftfartsmuseum.clientText]: "Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.date]: "Date:",
  [tokens.norskLuftfartsmuseum.category]: "Category:",
  [tokens.norskLuftfartsmuseum.categoryText]: "Museum Audio Guide App",
  [tokens.norskLuftfartsmuseum.projectDesc]: "Project Description",
  [tokens.norskLuftfartsmuseum.overview]: "Overview",
  [tokens.norskLuftfartsmuseum.overviewText]:
    "The Norwegian Aviation Museum app is designed to enhance the visitor experience at the largest aviation museum in the Nordics. Covering over 10,000 square meters, the museum showcases the history of aviation with a particular focus on Norwegian history. The app is designed to guide visitors through both the military and civil aviation sections of the museum, offering a more interactive and informative visit.",
  [tokens.norskLuftfartsmuseum.challenges]: "Challenges",
  [tokens.norskLuftfartsmuseum.challengesText]:
    "The main challenge was to create an app that would create value and ease-of-use to visitors of all ages from the moment they enter the museum.",
  [tokens.norskLuftfartsmuseum.outcomes]: "Outcomes",
  [tokens.norskLuftfartsmuseum.outcomesText]:
    "The Norwegian Aviation Museum app has successfully enhanced the visitor experience, offering a more engaging and interactive way to explore the museum’s rich history. Visitors can now enjoy a personalized, informative tour that caters to both adults and children, making the museum accessible and enjoyable for all ages.",
  [tokens.norskLuftfartsmuseum.keyFeatures]:
    "Key Features of Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.audio]: "Audio Guide",
  [tokens.norskLuftfartsmuseum.audioText]:
    "Learn more about the museum’s exhibits with a comprehensive audio guide for both the military and civil aviation sections.",
  [tokens.norskLuftfartsmuseum.qrCode]: "QR Code Scanner",
  [tokens.norskLuftfartsmuseum.qrCodeText]:
    "Easily scan exhibit QR codes for more detailed information about specific displays.",
  [tokens.norskLuftfartsmuseum.interactive]: "Interactive Museum Map",
  [tokens.norskLuftfartsmuseum.interactiveText]:
    "Navigate the museum with an interactive map that helps you find your way through the various sections and exhibits.",
  [tokens.norskLuftfartsmuseum.educational]: "Educational Tools",
  [tokens.norskLuftfartsmuseum.educationalText]:
    "Access educational resources, including routes and activities designed for young visitors.",
  [tokens.norskLuftfartsmuseum.museumPark]: "Museum Park Information",
  [tokens.norskLuftfartsmuseum.museumParkText]:
    "Explore the museum park and learn about the additional exhibits available outdoors.",
  /* t4kids Detail Page */
  [tokens.t4kids.appName]: "Time4Kids",
  [tokens.t4kids.projectInfo]: "Project Information",
  [tokens.t4kids.client]: "Client:",
  [tokens.t4kids.clientText]: "Foreldreplanlegger AS",
  [tokens.t4kids.date]: "Date:",
  [tokens.t4kids.category]: "Category:",
  [tokens.t4kids.categoryText]: "Family Mediation Platform",
  [tokens.t4kids.projectDesc]: "Project Description",
  [tokens.t4kids.overview]: "Overview",
  [tokens.t4kids.overviewText]:
    "Time4Kids is a platform designed to help parents, mediators, and lawyers manage family mediation processes, especially in cases of separation or divorce. It simplifies communication, case management, and agreement handling between parents and mediators. Time4Kids aims to create a smoother, more efficient process that helps parents collaborate on important decisions regarding their children.",
  [tokens.t4kids.challenges]: "Challenges",
  [tokens.t4kids.challengesText]:
    "The key challenge for Time4Kids was to design a platform that effectively supports both parents and mediators in navigating the complex family mediation process. The goal was to offer a simple, intuitive interface while providing the tools needed to manage cases, set up mediation meetings, and track progress.",
  [tokens.t4kids.solutions]: "Solutions",
  [tokens.t4kids.solutionsText]:
    "Time4Kids offers several essential features, including case management, session scheduling, agreement creation, and a calendar function to manage important deadlines and meetings. The platform allows for seamless collaboration, saving time and reducing costs for both parents and mediators. The built-in tools ensure that all mediation processes are easily tracked and managed in one place, ensuring clarity and transparency.",
  [tokens.t4kids.outcomes]: "Outcomes",
  [tokens.t4kids.outcomesText]:
    "Time4Kids is revolutionized the way family mediation is conducted by providing a digital space where all relevant stakeholders can collaborate efficiently. Mediators can handle multiple cases effortlessly, while parents benefit from streamlined communication and easier management of child-related agreements. The result is a faster, more organized process that supports all parties involved.",
  [tokens.t4kids.keyFeatures]: "Key Features of T4Kids",
  [tokens.t4kids.caseMng]: "Case Management",
  [tokens.t4kids.caseMngText]:
    "Easily create and manage cases online, ensuring all documentation and progress is accessible in one place.",
  [tokens.t4kids.agreement]: "Agreement Creation",
  [tokens.t4kids.agreementText]:
    "Simplify the drafting and management of agreements between parents during the mediation process.",
  [tokens.t4kids.mediation]: "Mediation Session Scheduling",
  [tokens.t4kids.mediationText]:
    "Plan and manage mandatory mediation sessions between parents using an integrated calendar.",
  [tokens.t4kids.tracking]: "Tracking Progress",
  [tokens.t4kids.trackingText]:
    "Monitor the status and development of cases and sessions, saving time and reducing costs along the way.",
  [tokens.t4kids.licenceMng]: "License Management",
  [tokens.t4kids.licenceMngText]:
    "Mediators can manage multiple licenses and share them with team members, ensuring a collaborative approach.",
  [tokens.t4kids.testimonial]:
    "Daxap AS has been a key partner in developing our products, and their technological expertise has helped us realize our vision of providing innovative and user-friendly tools for parents. With their flexibility and ability to deliver tailored solutions, Daxap has been a reliable partner, strengthening our position in the market and enabling us to offer high-quality products.",
  [tokens.t4kids.testimonialAuthor]: "Grethe Andersen",
  [tokens.t4kids.testimonialPosition]: "Co-Founder , Foreldreplanlegger",
  /* Pailot Detail Page */
  [tokens.pailot.appName]: "Pailot",
  [tokens.pailot.projectInfo]: "Project Information",
  [tokens.pailot.client]: "Client",
  [tokens.pailot.clientText]: "Servai AS",
  [tokens.pailot.date]: "Date:",
  [tokens.pailot.category]: "Category:",
  [tokens.pailot.categoryText]: "Non-Profit Management Platform",
  [tokens.pailot.projectDesc]: "Project Description",
  [tokens.pailot.overview]: "Overview",
  [tokens.pailot.overviewText]:
    "Pailot is an innovative platform developed to transform the way non-profit organizations and NGO's manage their projects, volunteers, and impact. Pailot integrates AI-powered tools to streamline operations, improve collaboration, and provide valuable insights into organizational performance. By simplifying the complex processes of project management and volunteer coordination, Pailot empowers non-profits to focus more on delivering meaningful community engagement, while saving meaningful time.",
  [tokens.pailot.challenges]: "Challenges",
  [tokens.pailot.challengesText]:
    "Non-profit organizations often face significant challenges when managing projects and coordinating volunteers due to limited resources and complex operations. Pailot needed to address these issues by offering a comprehensive solution that combines ease of use, data security, and AI-driven insights, all while adhering to strict data protection regulations.",
  [tokens.pailot.solutions]: "Solutions",
  [tokens.pailot.solutionsText]:
    "Pailot offers a suite of features designed to enhance organizational efficiency, including project management tools, volunteer coordination modules, and AI-powered analytics that provide actionable insights. The platform is built with a user-friendly interface to ensure that even organizations with limited technical expertise can effectively use it. Additionally, Pailot prioritizes data security with encrypted data transmission and compliance with global regulations such as GDPR.",
  [tokens.pailot.outcomes]: "Outcomes",
  [tokens.pailot.outcomesText]:
    "Since its launch, Pailot has helped numerous non-profit organizations optimize their operations, reduce administrative burdens, and amplify their social impact. By integrating advanced analytics, non-profits can now track their progress and make data-driven decisions that enhance their mission’s effectiveness. The platform has proven to be a valuable tool for fostering collaboration and ensuring that every effort is aligned with organizational goals.",
  [tokens.pailot.aiPowered]: "AI-Powered Analytics",
  [tokens.pailot.aiPoweredText]:
    "Leverage AI to gain deeper insights into project performance and volunteer engagement, ensuring your organization makes informed decisions.",
  [tokens.pailot.projectMng]: "Project Management",
  [tokens.pailot.projectMngText]:
    "Manage projects from start to finish with tools that simplify task allocation, tracking, and reporting.",
  [tokens.pailot.volunteer]: "Volunteer Coordination",
  [tokens.pailot.volunteerText]:
    "Streamline volunteer onboarding, scheduling, and communication to improve engagement and retention.",
  [tokens.pailot.dataSec]: "Data Security",
  [tokens.pailot.dataSecText]:
    "With Pailot, all data is encrypted and GDPR compliant, ensuring that sensitive information is well protected.",
  [tokens.pailot.collaboration]: "Collaboration Tools",
  [tokens.pailot.collaborationText]:
    "Foster better teamwork with integrated communication features that keep everyone on the same page.",
  [tokens.pailot.keyFeatures]: "Key Features of Pailot",
};
