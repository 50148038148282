import { SustainabilityCard } from "../../components/Company/Sustainability/SustainabilityCard";
import { Layout } from "../../layouts/Layout";

const SustainabilityPage = () => {
  return (
    <Layout header={5} footer={2}>
      <SustainabilityCard />
    </Layout>
  );
};

export default SustainabilityPage;
