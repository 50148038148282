import { ApiError } from "./error";
import { blogApi } from "./instance";

export const getBlogs = async (signal, language) => {
  try {
    const response = await blogApi.get(
      `/api/articles?filters[category][slug][$eq]=daxap&sort[0]=publishedAt:desc&populate=*&locale=${language}`,
      { signal }
    );

    if (response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    const errorResponse = error.response.data;
    throw new ApiError(errorResponse);
  }
};

export const getPaginateBlogs = async (
  start,
  blogsPerPage,
  signal,
  language
) => {
  try {
    const response = await blogApi.get(
      `/api/articles?filters[category][slug][$eq]=daxap&pagination[start]=${start}&pagination[limit]=${blogsPerPage}&sort[0]=publishedAt:desc&populate=*&locale=${language}`,
      { signal }
    );

    // total blog count
    const countResponse = await blogApi.get(
      `/api/articles?filters[category][slug][$eq]=daxap`,
      { signal }
    );

    if (response.data.data) {
      return {
        fetchedBlogs: response.data.data,
        total: countResponse.data.data.length,
      };
    }
  } catch (error) {
    const errorResponse = error.response.data;
    throw new ApiError(errorResponse);
  }
};
export const getRecentBlogs = async (signal, language) => {
  try {
    const response = await blogApi.get(
      `/api/articles?filters[category][slug][$eq]=daxap&pagination[start]=0&pagination[limit]=4&sort[0]=publishedAt:desc&populate=*&locale=${language}`,
      { signal }
    );

    if (response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    const errorResponse = error.response.data;
    throw new ApiError(errorResponse);
  }
};
