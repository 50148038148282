import { TrustCenterWrapper } from "../../../components/Company/TrustCenter/TrustCenterWrapper";
import { Layout } from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <Layout header={5} footer={2}>
      <TrustCenterWrapper>
        <div className="services-details-wrap pt-30 pb-30">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-12">
                  <h2>{t(tokens.privacyPolicy.title)}</h2>
                  <p>{t(tokens.privacyPolicy.introduction)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.treatmentManager.heading)}</h6>
                  <ul>
                    <li>
                      {t(tokens.privacyPolicy.treatmentManager.name)}: DAXAP AS
                    </li>
                    <li>
                      {t(tokens.privacyPolicy.treatmentManager.contactPerson)}:
                      Emirhan Sozan
                    </li>
                    <li>
                      {t(tokens.privacyPolicy.treatmentManager.telephone)}: +47
                      98 33 90 44
                    </li>
                    <li>
                      <a href="mailto:post@daxap.io">post@daxap.io</a>
                    </li>
                    <li>
                      {t(tokens.privacyPolicy.treatmentManager.address)}:
                      Kuhaugen 4A, 7224 Melhus
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.processingPurpose)}</h6>
                  <p>{t(tokens.privacyPolicy.processingPurposeText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.processingWhen)}</h6>
                  <p>{t(tokens.privacyPolicy.processingWhenText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.newsletter.heading)}</h6>
                  <ul>
                    <li>{t(tokens.privacyPolicy.newsletter.where)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.storedData)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.usage)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.storageTime)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.basis)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.safety)}</li>
                    <li>{t(tokens.privacyPolicy.newsletter.dataProcessor)}</li>
                  </ul>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.contactForm.heading)}</h6>
                  <ul>
                    <li>{t(tokens.privacyPolicy.contactForm.where)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.storedData)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.usage)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.storageTime)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.basis)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.safety)}</li>
                    <li>{t(tokens.privacyPolicy.contactForm.dataProcessor)}</li>
                  </ul>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.cookies)}</h6>
                  <p>{t(tokens.privacyPolicy.cookiesText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.processingBasis)}</h6>
                  <p>{t(tokens.privacyPolicy.processingBasisText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.rightsAccess)}</h6>
                  <p>{t(tokens.privacyPolicy.rightsAccessText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.rightsCorrection)}</h6>
                  <p>{t(tokens.privacyPolicy.rightsCorrectionText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.rightsDeletion)}</h6>
                  <p>{t(tokens.privacyPolicy.rightsDeletionText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.rightsRestriction)}</h6>
                  <p>{t(tokens.privacyPolicy.rightsRestrictionText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.dataPortability)}</h6>
                  <p>{t(tokens.privacyPolicy.dataPortabilityText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.withdrawConsent)}</h6>
                  <p>{t(tokens.privacyPolicy.withdrawConsentText)}</p>
                </div>
                <div className="col-12">
                  <h6>{t(tokens.privacyPolicy.complaint)}</h6>
                  <p>{t(tokens.privacyPolicy.complaintText)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TrustCenterWrapper>
    </Layout>
  );
};

export default PrivacyPolicyPage;
