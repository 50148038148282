import { SiFsecure } from "react-icons/si";
import { MdEnhancedEncryption } from "react-icons/md";
import { MdNetworkLocked } from "react-icons/md";
import { IoAccessibility } from "react-icons/io5";
import { PiMonitorFill } from "react-icons/pi";
import { MdOutlineSecurity } from "react-icons/md";
import { tokens } from "../locales/tokens";

export const SECURITY_FEATURES = [
  {
    id: 1,
    title: tokens.security.features.securelyDesigned,
    icon: <SiFsecure />,
    text: tokens.security.features.securelyDesignedText,
  },
  {
    id: 2,
    title: tokens.security.features.encryption,
    icon: <MdEnhancedEncryption />,
    text: tokens.security.features.encryptionText,
  },
  {
    id: 3,
    title: tokens.security.features.networkSegregation,
    icon: <MdNetworkLocked />,
    text: tokens.security.features.networkSegregationText,
  },
  {
    id: 4,
    title: tokens.security.features.accessToSystems,
    icon: <IoAccessibility />,
    text: tokens.security.features.accessToSystemsText,
  },
  {
    id: 5,
    title: tokens.security.features.loggingMonitoring,
    icon: <PiMonitorFill />,
    text: tokens.security.features.loggingMonitoringText,
  },
  {
    id: 6,
    title: tokens.security.features.respondingIncidents,
    icon: <MdOutlineSecurity />,
    text: tokens.security.features.respondingIncidentsText,
  },
];

export const ORGANIZATIONAL_SECURITY = [
  {
    id: 1,
    text: tokens.organizationalSecurity.statement1,
  },
  {
    id: 2,
    text: tokens.organizationalSecurity.statement2,
  },
  {
    id: 3,
    text: tokens.organizationalSecurity.statement3,
  },
  {
    id: 4,
    text: tokens.organizationalSecurity.statement4,
  },
  {
    id: 5,
    text: tokens.organizationalSecurity.statement5,
  },
  {
    id: 6,
    text: tokens.organizationalSecurity.statement6,
  },
  {
    id: 7,
    text: tokens.organizationalSecurity.statement7,
  },
  {
    id: 8,
    text: tokens.organizationalSecurity.statement8,
  },
  {
    id: 9,
    text: tokens.organizationalSecurity.statement9,
  },
  {
    id: 10,
    text: tokens.organizationalSecurity.statement10,
  },
  {
    id: 11,
    text: tokens.organizationalSecurity.statement11,
  },
  {
    id: 12,
    text: tokens.organizationalSecurity.statement12,
  },
  {
    id: 13,
    text: tokens.organizationalSecurity.statement13,
  },
  {
    id: 14,
    text: tokens.organizationalSecurity.statement14,
  },
  {
    id: 15,
    text: tokens.organizationalSecurity.statement15,
  },
  {
    id: 16,
    text: tokens.organizationalSecurity.statement16,
  },
];
export const CUSTOMER_DATA_SECURITY = [
  {
    id: 1,
    text: tokens.customerDataSecurity.securelyDesign,
  },
  {
    id: 2,
    text: tokens.customerDataSecurity.dataInTransit,
  },
  {
    id: 3,
    text: tokens.customerDataSecurity.dataAtRest,
  },
  {
    id: 4,
    text: tokens.customerDataSecurity.encryption,
  },
  {
    id: 5,
    text: tokens.customerDataSecurity.networkSegregation,
  },
  {
    id: 6,
    text: tokens.customerDataSecurity.accessToSystems,
  },
  {
    id: 7,
    text: tokens.customerDataSecurity.multiFactorAuthentication,
  },
  {
    id: 8,
    text: tokens.customerDataSecurity.passwordManager,
  },
  {
    id: 9,
    text: tokens.customerDataSecurity.loggingMonitoring,
  },
  {
    id: 10,
    text: tokens.customerDataSecurity.dataRetention,
  },
  {
    id: 11,
    text: tokens.customerDataSecurity.securityIncidents,
  },
  {
    id: 12,
    text: tokens.customerDataSecurity.vendorManagement,
  },
  {
    id: 13,
    text: tokens.customerDataSecurity.thirdPartyValidation,
  },
];

export const FAQ = [
  {
    id: 1,
    title: tokens.faq.securityAndPrivacyProgram,
    text: tokens.faq.securityAndPrivacyProgramText,
  },
  {
    id: 2,
    title: tokens.faq.designatedLead,
    text: tokens.faq.designatedLeadText,
  },
  {
    id: 3,
    title: tokens.faq.externalAudit,
    text: tokens.faq.externalAuditText,
  },
  {
    id: 4,
    title: tokens.faq.dataCenters,
    text: tokens.faq.dataCentersText,
  },
  {
    id: 5,
    title: tokens.faq.incidentDetectionResponse,
    text: tokens.faq.incidentDetectionResponseText,
  },
];
