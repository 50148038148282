import React from "react";
import { Layout } from "../../layouts/Layout";
import { ContactAreaInner } from "../../components/ContactUs/ContactAreaInner";
import { ContactAreaMap } from "../../components/ContactUs/ContactAreaMap";
import { ContactForm } from "../../components/ContactUs/ContactForm";

const ContactPage = () => {
  return (
    <Layout header={5} footer={2}>
      {/* contact-area */}
      <ContactAreaInner />

      {/* contact-form */}
      <ContactForm />

      {/* map */}
      <ContactAreaMap />
    </Layout>
  );
};

export default ContactPage;
