import { Layout } from "../../../layouts/Layout";
import { SocialEntrepreneurship } from "../../../components/About/SocialEntrepreneurship";

const SocialEntrepreneurshipPage = () => {
  return (
    <Layout header={5} footer={2}>
      <SocialEntrepreneurship />
    </Layout>
  );
};

export default SocialEntrepreneurshipPage;
