import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const PortfolioNavBar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const services = [
    { label: "Kiddy", href: "/kiddy-app" },
    { label: "Vibori", href: "/vibori-app" },
    { label: "Malimo", href: "/malimo-app" },
    { label: "Norsk Luftfartsmuseum", href: "/norsk-luftfartsmuseum-app" },
    { label: "Time4Kids", href: "/time4kids-app" },
    { label: "Pailot", href: "/pailot-app" },
  ];

  return (
    <div className="row mt-60 bg-light py-5 px-3 justify-content-center">
      {services.map((item) => (
        <Link
          key={item.label}
          to={item.href}
          className={`col-lg-5 p-4 border m-3 app-item ${cn({
            active: item.href === pathname,
          })}`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span>{item.label}</span> <i className="flaticon-right-arrow"></i>
          </div>
        </Link>
      ))}
    </div>
  );
};
