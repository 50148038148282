import React from "react";
import "reactjs-popup/dist/index.css";
import Iframe from "react-iframe";

export const VideoPlayerOne = ({ src, text,height, ...props }) => {
  return (
    <Iframe
      url={src}
      width="100%"
      height={height}
      display="block"
      position="relative"
    />
  );
};
