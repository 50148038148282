import React from "react";
import { Link } from "react-router-dom";
import { TEAM_1_IMG } from "../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const WhoWeAre = () => {
  const { t } = useTranslation();

  return (
    <section className="about-area-six">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="about-img-six d-flex justify-content-center">
              <img src={TEAM_1_IMG} alt="who_we_are"/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-six">
              <div className="section-title section-title-three mb-30 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">
                  {t(tokens.about.whoWeAre)}
                </span>
                <h2 className="title tg-element-title">
                  {t(tokens.about.innovativeCompany)}
                </h2>
              </div>

              <p style={{ textTransform: "none" }}>
                {t(tokens.about.description)}
              </p>
              <div className="about-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-arrow-right"></i>
                    {t(tokens.about.isoCertified)}
                  </li>
                  <li>
                    <i className="fas fa-arrow-right"></i>
                    {t(tokens.about.socialImpactMngPlan)}
                  </li>
                </ul>
              </div>
              <Link to="/team" className="btn transparent-btn-two me-3">
                {t(tokens.nav.ourTeam)}
              </Link>
              <Link
                to="/social-entrepreneurship"
                className="btn transparent-btn-two"
              >
                {t(tokens.nav.socialEntrepreneurship)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
