import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import { tg_title_animation } from "./lib/gsap/tg_title_animation";
import { useParticles } from "./lib/hooks/useParticles";
import { useParallax } from "./lib/hooks/useParallax";
import Home from "./pages/Home/Home";
import { useHasAnimation } from "./lib/hooks/useHasAnimation";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import BlogPage from "./pages/BlogPages/BlogPage";
import BlogDetailsPage from "./pages/BlogPages/BlogDetailsPage";
import { useWow } from "./lib/hooks/useWow";
import { useAos } from "./lib/hooks/useAos";
import { useJarallax } from "./lib/hooks/useJarallax";
import TeamPage from "./pages/AboutUs/Team/TeamPage";
import SocialEntrepreneurshipPage from "./pages/AboutUs/SocialEntrepreneurship/SocialEntrepreneurshipPage";
import AppDevelopmentPage from "./pages/OurSolutions/AppDevelopment/AppDevelopmentPage";
import WebDevelopmentPage from "./pages/OurSolutions/WebDevelopment/WebDevelopmentPage";
import QualityAssurancePage from "./pages/OurSolutions/QualityAssurance/QualityAssurancePage";
import RealizeYourProjectPage from "./pages/Company/RealizeYourProjectPage";
import CareerPage from "./pages/Company/CareerPage";
import TrustCenterPage from "./pages/Company/TrustCenter/TrustCenterPage";
import WhitePaperPage from "./pages/Company/TrustCenter/WhitePaperPage";
import PrivacyPolicyPage from "./pages/Company/TrustCenter/PrivacyPolicyPage";
import CookiePolicyPage from "./pages/Company/TrustCenter/CookiePolicyPage";
import ContactPage from "./pages/ContactUs/ContactPage";
import PortfolioPage from "./pages/Portfolio/PortfolioPage";
import KiddyPage from "./pages/Portfolio/Projects/KiddyPage";
import ViboriPage from "./pages/Portfolio/Projects/ViboriPage";
import MalimoPage from "./pages/Portfolio/Projects/MalimoPage";
import PailotPage from "./pages/Portfolio/Projects/PailotPage";
import T4KidsPage from "./pages/Portfolio/Projects/T4KidsPage";
import NorskLuftfartsmuseumPage from "./pages/Portfolio/Projects/NorskLuftfartsmuseumPage";
import SustainabilityPage from "./pages/Company/SustainabilityPage";

function App() {
  useWow();
  useAos();
  useJarallax();
  useParallax();
  useParticles();
  useHasAnimation();

  // title, subtitle animation
  useEffect(() => {
    tg_title_animation();
  }, []);

  // on route change to top of the page
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* about us */}
      <Route path="/team" element={<TeamPage />} />
      <Route
        path="/social-entrepreneurship"
        element={<SocialEntrepreneurshipPage />}
      />
      {/* our-solutions */}
      <Route path="/app-development" element={<AppDevelopmentPage />} />
      <Route path="/web-development" element={<WebDevelopmentPage />} />
      <Route path="/quality-assurance" element={<QualityAssurancePage />} />
      {/* portfolio */}
      <Route path="/portfolio" element={<PortfolioPage />} />
      <Route path="/kiddy-app" element={<KiddyPage />} />
      <Route path="/vibori-app" element={<ViboriPage />} />
      <Route path="/malimo-app" element={<MalimoPage />} />
      <Route path="/time4kids-app" element={<T4KidsPage />} />
      <Route path="/pailot-app" element={<PailotPage />} />
      <Route
        path="/norsk-luftfartsmuseum-app"
        element={<NorskLuftfartsmuseumPage />}
      />
      {/* company */}
      <Route
        path="/realize-your-project"
        element={<RealizeYourProjectPage />}
      />
      <Route path="/careers" element={<CareerPage />} />
      <Route path="/sustainability" element={<SustainabilityPage />} />
      <Route path="/trust-center" element={<TrustCenterPage />} />
      <Route path="/security-white-paper" element={<WhitePaperPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/cookie-policy" element={<CookiePolicyPage />} />

      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:slug" element={<BlogDetailsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
