import React from "react";
import { SERVICES_FOUR_LIST, SERVICES_LIST } from "../../data/services";
import { ServiceItem } from "./ServiceItem";
import cn from "classnames";
import { tokens } from "../../locales/tokens";
import { useTranslation } from "react-i18next";

export const Services = ({ className }) => {
  const { t } = useTranslation();

  return (
    <section className={cn("services-area-four", className)}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title section-title-three text-center mb-60 tg-heading-subheading animation-style1">
              <span className="sub-title tg-element-title">
                {t(tokens.services.ourExpertise)}
              </span>
              <h2 className="title tg-element-title">
                {t(tokens.services.title)}
              </h2>
              <p>{t(tokens.services.description)}</p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {SERVICES_LIST.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6">
              <ServiceItem service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
