import { tokens } from "../locales/tokens";

export const KIDDY_KEY_FEATURES = [
  {
    id: 1,
    title: tokens.kiddy.dailyActivity,
    text: tokens.kiddy.dailyActivityText,
  },
  {
    id: 2,
    title: tokens.kiddy.realTime,
    text: tokens.kiddy.realTimeText,
  },
  {
    id: 3,
    title: tokens.kiddy.childProgress,
    text: tokens.kiddy.childProgressText,
  },
  {
    id: 4,
    title: tokens.kiddy.safeSecure,
    text: tokens.kiddy.safeSecureText,
  },
];
export const VIBORI_KEY_FEATURES = [
  {
    id: 1,
    title: tokens.vibori.localNews,
    text: tokens.vibori.localNewsText,
  },
  {
    id: 2,
    title: tokens.vibori.eventCalendar,
    text: tokens.vibori.eventCalendarText,
  },
  {
    id: 3,
    title: tokens.vibori.followOrg,
    text: tokens.vibori.followOrgText,
  },
  {
    id: 4,
    title: tokens.vibori.translateFeature,
    text: tokens.vibori.translateFeatureText,
  },
  {
    id: 5,
    title: tokens.vibori.mapInt,
    text: tokens.vibori.mapIntText,
  },
];
export const MALIMO_KEY_FEATURES = [
  {
    id: 1,
    title: tokens.malimo.pedagogical,
    text: tokens.malimo.pedagogicalText,
  },
  {
    id: 2,
    title: tokens.malimo.extensive,
    text: tokens.malimo.extensiveText,
  },
  {
    id: 3,
    title: tokens.malimo.inclusive,
    text: tokens.malimo.inclusiveText,
  },
];

export const NORSK_KEY_FEATURES = [
  {
    id: 1,
    title: tokens.norskLuftfartsmuseum.audio,
    text: tokens.norskLuftfartsmuseum.audioText,
  },
  {
    id: 2,
    title: tokens.norskLuftfartsmuseum.qrCode,
    text: tokens.norskLuftfartsmuseum.qrCodeText,
  },
  {
    id: 3,
    title: tokens.norskLuftfartsmuseum.interactive,
    text: tokens.norskLuftfartsmuseum.interactiveText,
  },
  {
    id: 4,
    title: tokens.norskLuftfartsmuseum.educational,
    text: tokens.norskLuftfartsmuseum.educationalText,
  },
  {
    id: 5,
    title: tokens.norskLuftfartsmuseum.museumPark,
    text: tokens.norskLuftfartsmuseum.museumParkText,
  },
];

export const T4KIDS_FEATURES = [
  {
    id: 1,
    title: tokens.t4kids.caseMng,
    text: tokens.t4kids.caseMngText,
  },
  {
    id: 2,
    title: tokens.t4kids.agreement,
    text: tokens.t4kids.agreementText,
  },
  {
    id: 3,
    title: tokens.t4kids.mediation,
    text: tokens.t4kids.mediationText,
  },
  {
    id: 4,
    title: tokens.t4kids.tracking,
    text: tokens.t4kids.trackingText,
  },
  {
    id: 5,
    title: tokens.t4kids.licenceMng,
    text: tokens.t4kids.licenceMngText,
  },
];
export const PAILOT_FEATURES = [
  {
    id: 1,
    title: tokens.pailot.aiPowered,
    text: tokens.pailot.aiPoweredText,
  },
  {
    id: 2,
    title: tokens.pailot.projectMng,
    text: tokens.pailot.projectMngText,
  },
  {
    id: 3,
    title: tokens.pailot.volunteer,
    text: tokens.pailot.volunteerText,
  },
  {
    id: 4,
    title: tokens.pailot.dataSec,
    text: tokens.pailot.dataSecText,
  },
  {
    id: 5,
    title: tokens.pailot.collaboration,
    text: tokens.pailot.collaborationText,
  },
];

export const WHYCHOOSEDAXAP = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: tokens.ourPortfolio.whyChooseDaxap.feature1Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature1Text,
  },
  {
    id: 2,
    icon: "flaticon-profit",
    title: tokens.ourPortfolio.whyChooseDaxap.feature2Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature2Text,
  },
  {
    id: 3,
    icon: "flaticon-mission",
    title: tokens.ourPortfolio.whyChooseDaxap.feature3Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature3Text,
  },
  {
    id: 4,
    icon: "flaticon-business-presentation",
    title: tokens.ourPortfolio.whyChooseDaxap.feature4Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature4Text,
  },
  {
    id: 5,
    icon: "flaticon-money",
    title: tokens.ourPortfolio.whyChooseDaxap.feature5Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature5Text,
  },
  {
    id: 6,
    icon: "flaticon-inspiration",
    title: tokens.ourPortfolio.whyChooseDaxap.feature6Title,
    text: tokens.ourPortfolio.whyChooseDaxap.feature6Text,
  },
];
