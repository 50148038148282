export const BlogCardPlaceholder = () => (
    <div className="col-md-6">
      <div className="blog-post-item-two">
        <div className="blog-post-thumb-two">
          <div
            style={{
              width: "100%",
              height: "200px",
              backgroundColor: "#e0e0e0",
            }}
          ></div>
        </div>
  
        <div className="blog-post-content-two">
          <div
            style={{
              width: "70%",
              height: "24px",
              backgroundColor: "#e0e0e0",
              marginBottom: "10px",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              height: "15px",
              backgroundColor: "#e0e0e0",
              marginBottom: "5px",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              height: "15px",
              backgroundColor: "#e0e0e0",
              marginBottom: "5px",
            }}
          ></div>
  
          <div className="blog-meta">
            <ul className="list-wrap">
              <li>
                <div
                  style={{
                    width: "50px",
                    height: "15px",
                    backgroundColor: "#e0e0e0",
                  }}
                ></div>
              </li>
              <li>
                <div
                  style={{
                    width: "100px",
                    height: "15px",
                    backgroundColor: "#e0e0e0",
                  }}
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );