import {
  BRAND_IMG01,
  BRAND_IMG02,
  BRAND_IMG03,
  BRAND_IMG04,
  BRAND_IMG05,
  BRAND_IMG06,
  BRAND_IMG07,
  BRAND_IMG08,
  BRAND_IMG09,
  BRAND_IMG10,
  BRAND_IMG11,
  BRAND_IMG12,
} from "../lib/assets";

export const BRAND_LIST = [
  { id: 1, src: BRAND_IMG01 },
  { id: 2, src: BRAND_IMG02 },
  { id: 3, src: BRAND_IMG03 },
  { id: 4, src: BRAND_IMG04 },
  { id: 5, src: BRAND_IMG05 },
  { id: 6, src: BRAND_IMG06 },
  { id: 7, src: BRAND_IMG07 },
  { id: 8, src: BRAND_IMG08 },
  { id: 9, src: BRAND_IMG09 },
  { id: 10, src: BRAND_IMG10 },
  { id: 11, src: BRAND_IMG11 },
  { id: 12, src: BRAND_IMG12 },
];
