import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tokens } from "../../locales/tokens";

export const ContactWithUs = () => {
  const { t } = useTranslation();
  return (
    <section className="about-area-seven pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <div className="about-content-seven">
              <div className="section-title mb-30">
                <span className="sub-title">
                  {t(tokens.ourPortfolio.subtitle)}
                </span>
                <h2 className="title">{t(tokens.ourPortfolio.title)}</h2>
              </div>
              <p>{t(tokens.ourPortfolio.description)}</p>
              <Link to="/contact" className="btn btn-three">
              {t(tokens.ourPortfolio.contactWithUs)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
