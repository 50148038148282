import React from "react";
import { Link } from "react-router-dom";
import { PLUS_ICON } from "../../../lib/assets";
import { useTranslation } from "react-i18next";

export const PortfolioAreaItem = ({ project }) => {
  const { t } = useTranslation();
  return (
    <div className="project-item-four">
      <div className="project-thumb-four">
        <img
          src={project.src}
          alt={project.title}
          className="img-fluid border w-100 object-cover"
        />
        <div className="project-link">
          <Link to={project.href}>
            <img src={PLUS_ICON} alt={project.title} />
          </Link>
        </div>
      </div>

      <div className="project-content-four">
        <h4 className="title">
          <Link to={project.href}>{t(project.title)} </Link>
        </h4>
        <span>{t(project.subtitle)} </span>
      </div>
    </div>
  );
};
