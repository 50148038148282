import React from "react";
import { useTranslation } from 'react-i18next';
import { tokens } from "../../../locales/tokens";

export const ContactForm = () => {
  const { t } = useTranslation();

  return (
    <section className="cta-area-five pt-90 pb-90">
      <div className="container">
        <div
          className="cta-inner-wrap-two"
          data-background="assets/img/bg/cta_bg02.jpg"
        >
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="cta-content">
                <h4 className="text-white">
                  {t(tokens.contactForm.readyMessage)}
                </h4>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="cta-btn text-end">
                <a
                  href="https://forms.clickup.com/2577916/f/2enfw-48321/P4ZAYIQ00JCV2J7BVK"
                  target="_blank"
                  className="btn btn-three"
                  rel="noreferrer"
                >
                  {t(tokens.contactForm.submitCVButton)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
