import React from "react";
import { useTranslation } from "react-i18next";

export const CareerCardItem = ({ career }) => {
  const { t } = useTranslation();
  return (
    <div className="features-item-three">
      <div className="features-content-three d-flex align-items-center">
        <div className="features-icon-three">{career.icon}</div>
        <h4 className="title ms-4">{t(career.title)}</h4>
      </div>
      <div className="features-content-three">
        <p>{t(career.text)}</p>
      </div>
    </div>
  );
};
