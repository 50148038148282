import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

export const ServiceItem = ({ service }) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="services-item-four">
      <div className="services-icon-four">
        <i className={service.icon}></i>
      </div>
      <div className="services-content-four">
        <h2 className="title">
          <Link to={service.href}>{t(service.title)}</Link>
        </h2>
        <p style={{ textTransform: "none" }}>{t(service.subtitle)}</p>
        <Link to={service.href} className="btn transparent-btn-two">
          {t("serviceItem.readMore")}
        </Link>
      </div>
    </div>
  );
};
