import { useTranslation } from "react-i18next";
import { WEB_APP_LIST } from "../../../data/projects";
import { PortfolioAreaItem } from "../../Portfolio/Home/PortfolioAreaItem";
import { tokens } from "../../../locales/tokens";

export const WebProjectList = () => {
  const { t } = useTranslation();
  return (
    <div className="row justify-content-center">
      <div class="section-title section-title-three text-center col-12 my-5">
        <h4 class="title">{t(tokens.webDevelopment.ourWebApps)}</h4>
      </div>
      {WEB_APP_LIST.map((project, idx) => (
        <div key={project.id} className="col-lg-4 col-md-6 col-12">
          <PortfolioAreaItem project={project} />
        </div>
      ))}
    </div>
  );
};
