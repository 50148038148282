import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React from "react";

export const ContactAreaMap = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS;
  const center = {
    lat: 60.472,
    lng: 8.4689,
  };

  const locations = [
    {
      lat: 63.2851,
      lng: 10.2936,
      label: "Main Office: Kuhaugen 4A, 7224 Melhus Norway",
    },
    {
      lat: 59.9139,
      lng: 10.7411,
      label: "Office: Øvre Slottsgate 3, 0157 Oslo, Norway",
    },
  ];
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={5}>
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            label={location.label}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};
