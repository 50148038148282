import React from "react";
import { COUNTER_LIST_THREE } from "../../data/counter";
import { Odometer } from "../Odometer/Odometer";
import { useTranslation } from "react-i18next";

export const CounterArea = () => {
  const { t } = useTranslation();

  return (
    <section className="counter-area-three">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {COUNTER_LIST_THREE.map((item) => (
            <div key={item.id} className="col-lg-4">
              <div className="counter-item-three">
                <div className="counter-icon">
                  <i className={item.icon}></i>
                </div>
                <div className="counter-content">
                  <h2 className="count">
                    <Odometer end={item.count} />
                    {item.suffix}
                  </h2>

                  <p>{t(item.title)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
