import moment from "moment";
import { Link } from "react-router-dom";

export const BlogCard = ({ blog }) => {
  const author = blog.author?.name;
  const cover = blog.cover.formats.thumbnail.url;
  const publishedAt = moment(blog.publishedAt).format("DD.MM.YYYY ");
  const title = blog.title;
  const slug = blog.slug;
  const description = blog.description;

  return (
    <div className="blog-post-item-two">
      <div className="blog-post-thumb-two">
        <Link to={`/blog/${slug}`}>
          <img src={cover} alt={slug} />
        </Link>

        {/*   <Link to={blog.tagHref} className="tag tag-two">
                        {blog.tag}
                      </Link> */}
      </div>

      <div className="blog-post-content-two">
        <h2 className="title">
          <Link to={`/blog/${slug}`}>{title}</Link>
        </h2>
        <p>{description}</p>

        <div className="blog-meta">
          <ul className="list-wrap">
            <li>
              <Link to={`/blog/${slug}`}>{author}</Link>
            </li>
            <li>
              <i className="far fa-calendar"></i>
              {publishedAt}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
