import { tokens } from "../locales/tokens";

export const APP_DEV_FEATURES = [
  {
    id: 1,
    icon: "fas fa-arrow-right",
    title: tokens.appDevelopment.feature1,
  },
  {
    id: 2,
    icon: "fas fa-arrow-right",
    title: tokens.appDevelopment.feature2,
  },
  {
    id: 3,
    icon: "fas fa-arrow-right",
    title: tokens.appDevelopment.feature3,
  },
  {
    id: 4,
    icon: "fas fa-arrow-right",
    title: tokens.appDevelopment.feature4,
  },
  {
    id: 5,
    icon: "fas fa-arrow-right",
    title: tokens.appDevelopment.feature5,
  },
];
export const WEB_DEV_FEATURES = [
  {
    id: 1,
    icon: "fas fa-arrow-right",
    title: tokens.webDevelopment.feature1,
  },
  {
    id: 2,
    icon: "fas fa-arrow-right",
    title: tokens.webDevelopment.feature2,
  },
  {
    id: 3,
    icon: "fas fa-arrow-right",
    title: tokens.webDevelopment.feature3,
  },
  {
    id: 4,
    icon: "fas fa-arrow-right",
    title: tokens.webDevelopment.feature4,
  },
];
export const QA_FEATURES = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    title: tokens.qaDevelopment.feature1,
  },
  {
    id: 2,
    icon: "flaticon-business-presentation",
    title: tokens.qaDevelopment.feature2,
  },
  {
    id: 3,
    icon: "flaticon-business-presentation",
    title: tokens.qaDevelopment.feature3,
  },
  {
    id: 4,
    icon: "flaticon-business-presentation",
    title: tokens.qaDevelopment.feature4,
  },
];
