import { TrustCenterWrapper } from "../../../components/Company/TrustCenter/TrustCenterWrapper";
import { Layout } from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

const CookiePolicyPage = () => {
  const { t } = useTranslation();

  return (
    <Layout header={5} footer={2}>
      <TrustCenterWrapper>
        <div className="services-details-wrap pt-30 pb-30">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-12">
                  <h2>{t(tokens.cookiePolicy.title)}</h2>
                  <p>{t(tokens.cookiePolicy.description)}</p>
                </div>
                <div className="col-12">
                  <ul>
                    <li>{t(tokens.cookiePolicy.userAccount)}</li>
                    <li>{t(tokens.cookiePolicy.googleAnalytics)}</li>
                    <li>{t(tokens.cookiePolicy.marketing)}</li>
                  </ul>
                </div>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.cookiesListTitle)}</h6>
                <p>{t(tokens.cookiePolicy.noCookies)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.thirdPartyCookiesTitle)}</h6>
                <p>{t(tokens.cookiePolicy.thirdPartyCookiesDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.marketingCookiesTitle)}</h6>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.googleAnalyticsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.googleAnalyticsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.googleConversionTitle)}</h6>
                <p>{t(tokens.cookiePolicy.googleConversionDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.googleRemarketingTitle)}</h6>
                <p>{t(tokens.cookiePolicy.googleRemarketingDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.facebookTitle)}</h6>
                <p>{t(tokens.cookiePolicy.facebookDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.linkedinTitle)}</h6>
                <p>{t(tokens.cookiePolicy.linkedinDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.googleTagManagerTitle)}</h6>
                <p>{t(tokens.cookiePolicy.googleTagManagerDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.tawkToTitle)}</h6>
                <p>{t(tokens.cookiePolicy.tawkToDescription)}</p>
              </div>
              <div className="col-12">
                <h2>{t(tokens.cookiePolicy.acceptRejectTitle)}</h2>
                <h5>{t(tokens.cookiePolicy.acceptRejectDescription)}</h5>
                <h6>{t(tokens.cookiePolicy.acceptCookiesTitle)}</h6>
                <p>{t(tokens.cookiePolicy.acceptCookiesDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.benefitsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.benefitsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.rejectCookiesTitle)}</h6>
                <p>{t(tokens.cookiePolicy.rejectCookiesDescription)}</p>
              </div>
              <div className="col-12">
                <h2>{t(tokens.cookiePolicy.browserSettingsTitle)}</h2>
                <p>{t(tokens.cookiePolicy.browserSettingsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.ieSettingsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.ieSettingsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.safariSettingsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.safariSettingsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.chromeSettingsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.chromeSettingsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.firefoxSettingsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.firefoxSettingsDescription)}</p>
              </div>
              <div className="col-12">
                <h6>{t(tokens.cookiePolicy.operaSettingsTitle)}</h6>
                <p>{t(tokens.cookiePolicy.operaSettingsDescription)}</p>
              </div>
            </div>
          </div>
        </div>
      </TrustCenterWrapper>
    </Layout>
  );
};

export default CookiePolicyPage;
