import React from "react";
import { REALIZE_YOUR_PROJECT_2_IMG } from "../../../lib/assets";
import { WHYCHOOSEDAXAP } from "../../../data/realize-your-project";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const ChooseArea = () => {
  const { t } = useTranslation();

  return (
    <section className="choose-area-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="choose-content-three">
              <div className="section-title-two white-title mb-30">
                <span className="sub-title">{t(tokens.realizeYourProject.whatSpecialty)}</span>
                <h2 className="title">{t(tokens.realizeYourProject.whyChooseDaxap)}</h2>
              </div>
              <p>
                {t(tokens.realizeYourProject.whyChooseDaxapDescription)}
              </p>

              <div className="accordion-wrap-two">
                <div className="accordion" id="accordionExample">
                  {WHYCHOOSEDAXAP.map((item) => (
                    <div className="accordion-item" key={item.id}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${item.id}`}
                          aria-expanded="false"
                          aria-controls={`collapse${item.id}`}
                        >
                          {t(item.title)}
                        </button>
                      </h2>
                      <div
                        id={`collapse${item.id}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>{t(item.body)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="choose-img-three">
              <img src={REALIZE_YOUR_PROJECT_2_IMG} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
