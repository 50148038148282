import React from "react";
import { APP_DEV_FEATURES } from "../../../data/features";
import { useTranslation } from "react-i18next";

export const AppDevelopmentFeature = () => {
  const { t } = useTranslation();
  return (
    <div className="our-solutions-list">
      <ul className="list-wrap">
        {APP_DEV_FEATURES.map((feature) => (
          <li>
            <div className="icon">
              <i className="flaticon-business-presentation"></i>
            </div>
            <div className="content">
              <p>{t(feature.title)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
