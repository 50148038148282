import { tokens } from "../locales/tokens";

export const WHYCHOOSEDAXAP = [
  {
    id: 1,
    title: tokens.whyChooseDaxap.customSolutions, // Custom Solutions
    body: tokens.whyChooseDaxap.customSolutionsBody, // We create unique, custom-built solutions based on your specific needs.
  },
  {
    id: 2,
    title: tokens.whyChooseDaxap.expertGuidance, // Expert Guidance
    body: tokens.whyChooseDaxap.expertGuidanceBody, // Our team of industry experts will guide you through each phase of development, from idea to execution.
  },
  {
    id: 3,
    title: tokens.whyChooseDaxap.endToEndSupport, // End-to-End Support
    body: tokens.whyChooseDaxap.endToEndSupportBody, // Whether it's project management, design, or development, we offer full support throughout the entire journey.
  },
  {
    id: 4,
    title: tokens.whyChooseDaxap.creativeCollaboration, // Creative Collaboration
    body: tokens.whyChooseDaxap.creativeCollaborationBody, // Work closely with a team that values creativity and innovation just as much as you do.
  },
];

export const HOW_IT_WORK_LIST = [
  {
    id: 1,
    title: tokens.howItWorks.shareYourVision, // Share Your Vision
    text: tokens.howItWorks.shareYourVisionText, // Tell us about your project idea and your goals. The more details you provide, the more we can tailor our approach to deliver exactly what you need.
  },
  {
    id: 2,
    title: tokens.howItWorks.collaborate, // Collaborate
    text: tokens.howItWorks.collaborateText, // We work closely with you to design and plan every aspect of your project, ensuring that it aligns with your vision and objectives.
  },
  {
    id: 3,
    title: tokens.howItWorks.createAndDeliver, // Create and Deliver
    text: tokens.howItWorks.createAndDeliverText, // Our team brings your project to life using the latest technology and best practices to ensure high-quality results.
  },
  {
    id: 4,
    title: tokens.howItWorks.ongoingSupport, // Ongoing Support
    text: tokens.howItWorks.ongoingSupportText, // Even after the project is complete, we’re here to offer support and help you scale.
  },
];
