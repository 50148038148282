import React from "react";
import { BRAND_LIST } from "../../data/brands";
import { SlickSlider } from "../SlickSlider/SlickSlider";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const CollaborationPartner = () => {
  const { t } = useTranslation();

  const slick_settings_brands = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="brand-area-five">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title section-title-three mb-60 tg-heading-subheading animation-style1">
              <span className="sub-title tg-element-title">
                {t(tokens.collaborationPartner.subtitle)}
              </span>
              <h2 className="title tg-element-title">
                {t(tokens.collaborationPartner.title)}
              </h2>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-title section-title-three text-left mb-60 tg-heading-subheading animation-style1">
              <p>{t(tokens.collaborationPartner.description)}</p>
            </div>
          </div>
        </div>
        <div className="row brand-active">
          <SlickSlider settings={slick_settings_brands}>
            {BRAND_LIST.map((brand) => (
              <div key={brand.id} className="col-lg-12">
                <div className="brand-item">
                  <img src={brand.src} alt="brand_image" />
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};
