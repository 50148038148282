import { AboutTeam } from "../../../components/About/AboutTeam";
import { TeamArea } from "../../../components/TeamAreas/TeamArea";
import { Layout } from "../../../layouts/Layout";

const TeamPage = () => {
  return (
    <Layout header={5} footer={2}>
      {/* about-area */}
      <AboutTeam />
      {/* team-area */}
      <TeamArea />
    </Layout>
  );
};

export default TeamPage;
