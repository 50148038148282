import React from "react";
import {
  CHECK_ICON,
  PAILOT_PROJECT,
  PAILOT_PROJECT2,
} from "../../../lib/assets";
import { Layout } from "../../../layouts/Layout";
import { PAILOT_FEATURES } from "../../../data/portfolio";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { PortfolioNavBar } from "../../../components/Portfolio/PortfolioSidebar";

const PailotPage = () => {
  const { t } = useTranslation();
  return (
    <Layout header={5} footer={2}>
      {/* project-details-area */}
      <section className="project-details-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="project-details-wrap">
                <div className="row">
                  {/* image */}
                  <div className="col-71 border">
                    <div className="project-details-thumb">
                      <img src={PAILOT_PROJECT} alt="PAILOT_PROJECT" />
                    </div>
                  </div>

                  {/* project details info */}
                  <div className="col-29">
                    <h3 className="title">{t(tokens.pailot.appName)}</h3>
                    <div className="project-details-info">
                      <h4 className="title">{t(tokens.pailot.projectInfo)}</h4>
                      <ul className="list-wrap">
                        <li>
                          <span>{t(tokens.pailot.client)}</span>
                          {t(tokens.pailot.clientText)}
                        </li>
                        <li>
                          <span>{t(tokens.pailot.date)}</span>01 December, 2023
                        </li>
                        <li>
                          <span>{t(tokens.pailot.category)}</span>
                          {t(tokens.pailot.categoryText)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="project-details-content mt-30">
                  <div className="section-title section-title-three tg-heading-subheading animation-style1">
                    <span className="sub-title tg-element-title">
                      {t(tokens.pailot.projectDesc)}
                    </span>
                  </div>
                  <div className="pd-optimized-wrap">
                    <h3 className="title-two">{t(tokens.pailot.overview)}</h3>
                    <p>{t(tokens.pailot.overviewText)}</p>
                  </div>
                  <div className="pd-optimized-wrap">
                    <h3 className="title-two">{t(tokens.pailot.challenges)}</h3>
                    <p>{t(tokens.pailot.challengesText)}</p>
                  </div>
                  <div className="pd-optimized-wrap">
                    <h3 className="title-two">{t(tokens.pailot.solutions)}</h3>
                    <p>{t(tokens.pailot.solutionsText)}</p>
                  </div>
                  <div className="pd-optimized-wrap">
                    <h3 className="title-two">{t(tokens.pailot.outcomes)}</h3>
                    <p>{t(tokens.pailot.outcomesText)}</p>
                  </div>
                  <div className="pd-inner-wrap mt-60">
                    <div className="row align-items-center">
                      <div className="col-41">
                        <div className="content">
                          <div className="section-title section-title-three tg-heading-subheading animation-style1">
                            <span className="sub-title tg-element-title">
                              {t(tokens.pailot.keyFeatures)}
                            </span>
                          </div>
                          <ul className="list-wrap d-flex align-items-center">
                            {PAILOT_FEATURES.map((feature) => (
                              <li
                                key={feature.id}
                                className="d-flex w-100 py-2"
                              >
                                <img src={CHECK_ICON} alt="check_icon" />
                                <div className="d-flex flex-column">
                                  <span>{t(feature.title)}</span>
                                  <p>{t(feature.text)}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-59">
                        <div className="thumb">
                          <img src={PAILOT_PROJECT2} alt="PAILOT_PROJECT" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PortfolioNavBar />
        </div>
      </section>
    </Layout>
  );
};

export default PailotPage;
