import React from "react";
import { GOALS } from "../../data/goals";
import { Odometer } from "../Odometer/Odometer";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const FeatureGoals = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-90" style={{ backgroundColor: "#f8faff" }}>
      <div className="container">
        <div className="row mt-30 mb-30">
          <div className="col-lg-6">
            <h2>{t(tokens.featureGoals.title)}</h2>
          </div>
          <div className="col-lg-6">
            <p>{t(tokens.featureGoals.description)}</p>
          </div>
        </div>
        <div className="features-item-wrap-four">
          <div className="row">
            {GOALS.map((goal) => (
              <div key={goal.id} className="col-lg-4 col-md-6">
                <div className="features-item-four">
                  <div className="features-icon-four">{goal.icon}</div>
                  <div className="features-content-four">
                    <h2 className="count mb-0">
                      <Odometer end={t(goal.count)} />
                    </h2>
                    <p>{t(goal.title)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
