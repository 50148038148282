import {
  MarenLieMalmo_IMG,
  LindaRefsnes_IMG,
  GretheAndresen_IMG,
  MariJohanneOlsen_IMG,
  Solvi_IMG,
} from "../lib/assets";
import { tokens } from "../locales/tokens";

export const TESTIMONIALS = [
  {
    id: 1,
    text: tokens.testimonials.testimonialtext1,
    src: MarenLieMalmo_IMG,
    title: "Maren Lie Malmo",
    position: tokens.testimonials.position1,
    company: "Malimo",
  },
  {
    id: 2,
    text: tokens.testimonials.testimonialtext2,
    src: LindaRefsnes_IMG,
    title: "Linda Lorense Refsnes",
    position: tokens.testimonials.position2,
    company: "Bloomlet",
  },
  {
    id: 3,
    text: tokens.testimonials.testimonialtext3,
    src: GretheAndresen_IMG,
    title: "Grethe Andersen",
    position: tokens.testimonials.position3,
    company: "Foreldreplanlegger",
  },
  {
    id: 4,
    text: tokens.testimonials.testimonialtext4,
    src: MariJohanneOlsen_IMG,
    title: "Mari Johanne Olsen",
    position: tokens.testimonials.position4,
    company: "Foreldreplanlegger",
  },
  {
    id: 5,
    text: tokens.testimonials.testimonialtext5,
    src: Solvi_IMG,
    title: "Sølvi Berfjord Nordin",
    // position: tokens.testimonials.position5,
    position: "",
    company: "Blått Kompetanse",
  },
];

/* Maren Lie Malmo / Founder / Malimo
NO: Daxap har vært essensielle for oss. Vi har fått en teknisk løsning som lar oss vokse inn i fremtiden, med stabilitet og god brukerflyt. De har solid kompetanse og har løst alle nøtter underveis. I tillegg er de gode mennesker som står på for oss på alle plan.
EN: Daxap has been essential for us. We have received a technical solution that allows us to grow into the future, with stability and smooth user experience. They possess solid expertise and have solved all challenges along the way. Additionally, they are great people who go the extra mile for us on all levels.
Linda Lorense Refsnes / Founder / Bloomlet
NO: Deres kompetanse og dedikasjon er avgjørende for utviklingen av vår løsning. Daxap forstår vår visjon og implementerer våre verdier i alle ledd av utviklingen. De ble et naturlig valg for oss, basert på deres tidligere arbeid og engasjement innen sosialt entreprenørskap.
EN: Their expertise and dedication are crucial to the development of our solution. Daxap understands our vision and implements our values at every stage of development. They became a natural choice for us, based on their previous work and commitment to social entrepreneurship.
Mari Johanne Olsen / Co-Founder / Foreldreplanlegger
NO: Som kunde og samarbeidspartner av Daxap AS opplever vi at de er tilgjengelige og imøtekommende, gode på å se behov og bidra til effektive løsninger, og at de leverer høy faglig kompetanse i sitt arbeid.
EN: As a customer and partner of Daxap AS, we experience them as accessible and accommodating, skilled at identifying needs and contributing to effective solutions, and delivering high professional competence in their work.
Grethe Andersen / Co-Founder / Foreldreplanlegger
NO: Daxap AS har vært en nøkkelpartner i å utvikle våre produkter, og deres teknologiske ekspertise har hjulpet oss med å realisere vår visjon om å tilby innovative og brukervennlige verktøy for foreldre.
Med deres fleksibilitet og evne til å levere skreddersydde løsninger har Daxap vært en pålitelig samarbeidspartner, noe som har styrket vår posisjon i markedet og gjort oss i stand til å tilby produkter av høy kvalitet.
EN: Daxap AS has been a key partner in developing our products, and their technological expertise has helped us realize our vision of providing innovative and user-friendly tools for parents. With their flexibility and ability to deliver tailored solutions, Daxap has been a reliable partner, strengthening our position in the market and enabling us to offer high-quality products. */
