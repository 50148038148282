import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoMail } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { contactEmail } from "../../../services/realize-your-project";

const phoneRegExp = /^[0-9]{10,15}$/;

export const ContactArea = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      mobile: "",
      projectType: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .min(2, t(tokens.contact.errors.fullName))
        .max(50, t(tokens.contact.errors.fullName))
        .required(t(tokens.contact.errors.fullName)),
      email: Yup.string()
        .trim()
        .email(t(tokens.contact.errors.validEmail))
        .required(t(tokens.contact.errors.validEmail)),
      mobile: Yup.string()
        .required(t(tokens.contact.errors.requiredField))
        .matches(phoneRegExp, t(tokens.contact.errors.validPhone)),
      projectType: Yup.string().required(
        t(tokens.contact.errors.validProjectType)
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const body = {
          ...values,
          subject: "Daxap.io Project inquiry from",
        };
        const response = await contactEmail(body);

        if (response.status === 200) {
          resetForm();
          setIsSubmitted(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  return (
    <section className="contact-area contact-bg">
      <div className="container">
        {isSubmitted ? (
          <div className="contact-content">
            <div className="section-title mb-30 text-center">
              <IoMail size={60} />
              <h4>{t(tokens.contact.submissionReceived.title)}</h4>
              <span>{t(tokens.contact.submissionReceived.message)}</span>
              <div className="my-3">
                <button
                  onClick={() => setIsSubmitted(false)}
                  className="btn btn-three ms-3 p-3"
                >
                  {t(tokens.contact.submissionReceived.newMessageBtn)}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="contact-content">
                <div className="section-title mb-30 tg-heading-subheading animation-style2">
                  <span className="sub-title tg-element-title">
                    {t(tokens.contact.getInTouch)}
                  </span>
                  <h2 className="title tg-element-title">
                    {t(tokens.contact.mainTitle)}
                  </h2>
                </div>
                <p>{t(tokens.contact.description)}</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contact-form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="text"
                          name="fullName"
                          value={formik.values.fullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(tokens.contact.form.namePlaceholder)}
                        />
                        {formik.touched.fullName && formik.errors.fullName ? (
                          <div className="error">{formik.errors.fullName}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(tokens.contact.form.emailPlaceholder)}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="text"
                          name="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(tokens.contact.form.mobilePlaceholder)}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="error">{formik.errors.mobile}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <select
                          className="form-select"
                          name="projectType"
                          value={formik.values.projectType}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-label={t(tokens.contact.form.projectType)}
                        >
                          <option
                            value=""
                            label={t(tokens.contact.form.serviceNeeded)}
                          />
                          <option value="Web Development">
                            {t(tokens.contact.form.projectType.webDevelopment)}
                          </option>
                          <option value="Mobile App">
                            {t(tokens.contact.form.projectType.mobileApp)}
                          </option>
                          <option value="Quality Assurance">
                            {t(
                              tokens.contact.form.projectType.qualityAssurance
                            )}
                          </option>
                        </select>
                        {formik.touched.projectType &&
                        formik.errors.projectType ? (
                          <div className="error">
                            {formik.errors.projectType}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-grp">
                        <textarea
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t(
                            tokens.contact.form.descriptionPlaceholder
                          )}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    style={{ textTransform: "none" }}
                  >
                    {t(tokens.contact.form.sendMessage)}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
