// data/sustainability.js
import React from "react";
import { GiRollingEnergy } from "react-icons/gi";
import { SlEnergy } from "react-icons/sl";
import { FaLeaf } from "react-icons/fa";
import { tokens } from "../locales/tokens";

export const SUSTAINABILITIES = [
  {
    id: 1,
    icon: <GiRollingEnergy />,
    title: tokens.energyEfficiencyTitle,
    text: tokens.energyEfficiencyText,
  },
  {
    id: 2,
    icon: <SlEnergy />,
    title: tokens.sustainableDevelopmentTitle,
    text: tokens.sustainableDevelopmentText,
  },
  {
    id: 3,
    icon: <FaLeaf />,
    title: tokens.employeeEngagementTitle,
    text: tokens.employeeEngagementText,
  },
];
