import { TrustCenterFaq } from "../../../components/Company/TrustCenter/TrustCenterFaq";
import { TrustCenterWrapper } from "../../../components/Company/TrustCenter/TrustCenterWrapper";
import { SECURITY_FEATURES } from "../../../data/trust-center";
import { Layout } from "../../../layouts/Layout";
import { CHECK_ICON, TRUST_CENTER_IMG } from "../../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

const TrustCenterPage = () => {
  const { t } = useTranslation();

  return (
    <Layout header={5} footer={2}>
      <TrustCenterWrapper>
        <div className="services-details-wrap">
          <div className="services-details-content">
            <div className="sd-inner-wrap-four">
              <div className="row align-items-center">
                <div className="col-55">
                  <div className="thumb">
                    <img src={TRUST_CENTER_IMG} alt="TRUST_CENTER_IMG" />
                  </div>
                </div>
                <div className="col-45">
                  <div className="content">
                    <h2 className="title">
                      {t(tokens.trustCenter.securityTitle)}
                    </h2>
                    <p>{t(tokens.trustCenter.securityDescription)}</p>
                    <ul className="list-wrap">
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        {t(tokens.trustCenter.isoCertified)}
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        {t(tokens.trustCenter.gdprCompliant)}
                      </li>
                      <li>
                        <img src={CHECK_ICON} alt="" />
                        {t(tokens.trustCenter.environmentalCertified)}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="security-features-list">
              <ul className="list-wrap">
                {SECURITY_FEATURES.map((feature) => (
                  <li key={feature.id}>
                    <div className="icon">{feature.icon}</div>
                    <div className="content">
                      <h4 className="title">{t(feature.title)}</h4>
                      <p>{t(feature.text)}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* faq */}
            <TrustCenterFaq />
          </div>
        </div>
      </TrustCenterWrapper>
    </Layout>
  );
};

export default TrustCenterPage;
