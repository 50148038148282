import React from "react";
import { SustainabilityCardItem } from "./SustainabilityCardItem";
import {
  SUSTAINABILITY_1_IMG,
  SUSTAINABILITY_2_IMG,
} from "../../../lib/assets";
import { SUSTAINABILITIES } from "../../../data/sustainability";
import { useTranslation } from "react-i18next"; 
import { tokens } from "../../../locales/tokens";

export const SustainabilityCard = () => {
  const { t } = useTranslation();

  return (
    <section className="services-area-seven pt-120 pb-90">
      <div className="container">
        <div className="row justify-content-center mb-30">
          <div className="col-lg-7 order-0 order-lg-2">
            <div className="about-img-wrap-four">
              <div className="mask-img-wrap">
                <img src={SUSTAINABILITY_1_IMG} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="section-title-two mb-50">
              <span className="sub-title">{t(tokens.title)}</span>
              <h2 className="subtitle">{t(tokens.subtitle1)}</h2>
              <p>{t(tokens.description1)}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-30">
          <div className="col-lg-7 order-0 order-lg-2">
            <div className="about-img-wrap-four">
              <div className="mask-img-wrap">
                <img src={SUSTAINABILITY_2_IMG} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="section-title-two mb-50">
              <h2 className="subtitle">{t(tokens.subtitle2)}</h2>
              <p>{t(tokens.description2)}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-30">
          <h2 className="subtitle mb-30">{t(tokens.ongoingEffortsTitle)}</h2>
          {SUSTAINABILITIES.map((career) => (
            <div key={career.id} className="col-lg-4 col-md-6">
              <SustainabilityCardItem career={career} />
            </div>
          ))}
        </div>
        <div className="row justify-content-center mb-30">
          <div className="col-12">
            <div className="section-title-two">
              <h2 className="subtitle">{t(tokens.transparencyTitle)}</h2>
              <p>{t(tokens.transparencyDescription)}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
