import { tokens } from "../locales/tokens";
export const COUNTER_LIST_THREE = [
 /*  {
    id: 1,
    count: 1,
    suffix: "",
    icon: "flaticon-trophy",
    title: tokens.counter.bestPitchAward,
  }, */
  {
    id: 2,
    count: 8,
    suffix: "",
    icon: "flaticon-folder-1",
    title: tokens.counter.endToEndProjects,
  },
  {
    id: 3,
    count: 20,
    suffix: "",
    icon: "flaticon-rating",
    title: tokens.counter.globalPartners,
  },
  {
    id: 4,
    count: 37,
    suffix: "",
    icon: "flaticon-round-table",
    title: tokens.counter.globalClients,
  },
];
