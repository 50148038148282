import React from "react";
import {
  SlickSlider,
  SlickSliderArrowNext,
  SlickSliderArrowPrev,
} from "../SlickSlider/SlickSlider";
import { useParticlesTestimonialFive } from "../../lib/hooks/useParticles";
import { TESTIMONIALS } from "../../data/testimonials";
import { tokens } from "../../locales/tokens";
import { useTranslation } from "react-i18next";

export const TestimonialArea = () => {
  const { t } = useTranslation();
  // particles
  useParticlesTestimonialFive();

  const slick_settings_brands = {
    dots: false,
    arrows: true,
    prevArrow: <SlickSliderArrowPrev classNameWrapper="testimonial-nav-two" />,
    nextArrow: <SlickSliderArrowNext classNameWrapper="testimonial-nav-two" />,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial-area-two testimonial-bg-two">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title">
                {t(tokens.testimonials.sectionTitle)}
              </span>
              <h2 className="title tg-element-title">
                {t(tokens.testimonials.title)}
              </h2>
            </div>
          </div>
        </div>

        <div className="testimonial-item-wrap-two">
          <div className="row testimonial-active-two">
            <SlickSlider settings={slick_settings_brands}>
              {TESTIMONIALS.map((customer) => (
                <div key={customer.id} className="col-lg-6">
                  <div className="testimonial-item-two">
                    <div className="testimonial-content-two">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>{t(customer.text)}</p>
                      <div className="testimonial-avatar">
                        <div className="avatar-thumb">
                          <img src={customer.src} alt={`${customer.name}`} />
                        </div>
                        <div className="avatar-info">
                          <h2 className="title">{t(customer.title)}</h2>
                          <span>
                            {t(customer.position)} {customer.company}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </section>
  );
};
