import React from "react";
import { CONTACT_PAGE_IMG } from "../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const ContactAreaInner = () => {
  const { t } = useTranslation();
  return (
    <section className="inner-contact-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="inner-contact-img">
              <img src={CONTACT_PAGE_IMG} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner-contact-info">
              <h2 className="title">{t(tokens.contactUs.ourOfficeAddress)}</h2>
              <div className="contact-info-item">
                <h5 className="title-two">
                  {t(tokens.contactUs.daxapHeadquarters)}
                </h5>
                <ul className="list-wrap">
                  <li>Kuhaugen 4A, 7224 Melhus Norway</li>
                </ul>
              </div>
              <div className="contact-info-item">
                <h5 className="title-two">{t(tokens.contactUs.daxapOslo)}</h5>
                <ul className="list-wrap">
                  <li>Øvre Slottsgate 3, 0157 Oslo, Norway</li>
                </ul>
              </div>
              <div className="contact-info-item">
                <ul className="list-wrap">
                  <li className="d-flex align-items-center">
                    <div className="icon me-2">
                      <i className="flaticon-phone-call"></i>
                    </div>
                    <div className="content">
                      <a href="tel:0123456789">+47 98 33 90 44</a>
                    </div>
                  </li>
                  <li className="d-flex align-items-center">
                    <div className="icon me-2">
                      <i className="flaticon-mail"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:post@daxap.io">post@daxap.io</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
