import { tokens } from "../tokens";

export const no = {
  /* Top Nav */
  [tokens.nav.ourTeam]: "Teamet vårt",
  [tokens.nav.socialEntrepreneurship]: "Sosialt entreprenørskap",
  [tokens.nav.realizeProject]: "Realiser din visjon",
  [tokens.nav.careers]: "Karrierer",
  [tokens.nav.trustCenter]: "Tillitsenter",
  [tokens.nav.sustainability]: "Bærekraft",
  [tokens.nav.aboutUs]: "Om oss",
  [tokens.nav.company]: "Selskap",
  [tokens.nav.ourSolutions]: "Våre løsninger",
  [tokens.nav.portfolio]: "Portefølje",
  [tokens.nav.blog]: "Blogg",
  [tokens.nav.contactUs]: "Kontakt oss",
  /* HomePage */
  [tokens.nav.realizeProject]: "Realiser din visjon",
  [tokens.banner.digital]: "Digitale",
  [tokens.banner.solutions]: "løsninger",
  [tokens.banner.forBetterSociety]: "for et bedre samfunn",
  [tokens.banner.description]:
    "Daxap skaper innovative digitale løsninger for å løse komplekse utfordringer, samtidig som vi fremmer positiv endring i samfunnet. Vårt mål er å utnytte teknologi for å styrke lokalsamfunn og organisasjoner for en bedre fremtid.",
  [tokens.banner.bookMeeting]: "Book et møte",
  [tokens.about.whoWeAre]: "Hvem vi er",
  [tokens.about.innovativeCompany]: "Din teknologipartner",
  [tokens.about.description]:
    "Daxap ble grunnlagt for å redefinere teknologilandskapet. Vi fakturerer ikke etter timer, men for løsninger som blir levert. Ved å harmonisere våre mål med våre kunder, går vi utover tradisjonell rådgivning for å bygge varige samarbeid.Vårt globale team av bransjeeksperter er forpliktet til kvalitet, bærekraft og inkludering. Vi skaper løsninger som adresserer dagens utfordringer og legger grunnlaget for langsiktig vekst.",
  [tokens.about.isoCertified]: "ISO 27001 Sertifisert",
  [tokens.about.socialImpactMngPlan]:
    "Plan for håndtering av sosiale konsekvenser",
  [tokens.about.ourTeam]: "Vårt team",
  [tokens.about.socialEntrepreneurship]: "Sosialt entreprenørskap",
  [tokens.about.promisedTimeline]: "TBD",
  [tokens.counter.bestPitchAward]: "Beste Pitch-pris",
  [tokens.counter.endToEndProjects]: "Skreddersydde produkter",
  [tokens.counter.globalPartners]: "Globale partnere",
  [tokens.counter.globalClients]: "Globale kunder",
  [tokens.collaborationPartner.subtitle]: "Samarbeidspartnere",
  [tokens.collaborationPartner.title]:
    "Vi bygger samarbeid for bærekraftig vekst",
  [tokens.collaborationPartner.description]:
    "Vi er avhengige av et nettverk av aktører og enkeltpersoner som ønsker å gjøre positive endringer i samfunnet. Vi samarbeider med flere grupper som spesialiserer seg på finansiering, entreprenørskap, inkubator- og akseleratorprogrammer, juridisk bistand, forskning og utvikling, effektmåling, flerspråklighet, multikulturalisme, mangfold, integrasjon og inkludering.",
  [tokens.services.ourExpertise]: "Vår ekspertise",
  [tokens.services.title]: "Skalerbare digitale løsninger",
  [tokens.services.description]:
    "Vi skaper trygge, brukervennlige produkter som kontinuerlig videreutvikles.",
  [tokens.servicesList.appDevelopmentTitle]: "Apputvikling",
  [tokens.servicesList.appDevelopmentSubtitle]:
    "Vi utvikler både våre egne Software as a Service (SaaS)-produkter i tillegg til applikasjoner for våre samarbeidspartnere. Vi lager trygge og brukervennlige løsninger som er designet for å kunne skaleres.",
  [tokens.servicesList.webDevelopmentTitle]: "Webutvikling",
  [tokens.servicesList.webDevelopmentSubtitle]:
    "Vi legger stor vekt på å forstå de unike behovene til våre kunder når det kommer til nettstedsdesign og funksjonalitet. Vi tar en individuell tilnærming som er tro mot din merkevareidentitet og behov.",
  [tokens.servicesList.qualityAssuranceTitle]: "Kvalitetssikring",
  [tokens.servicesList.qualityAssuranceSubtitle]:
    "Kvalitetssikring (QA) er avgjørende for veksten og påliteligheten til dine digitale produkter. QA-tjenestene våre spiller en sentral rolle for å sikre at produktene dine er sikre nå og i fremtiden.",
  [tokens.serviceItem.readMore]: "Les mer",
  [tokens.ourCapability.subTitle]: "VÅR VERDEN",
  [tokens.ourCapability.title]: "Forenkling av et komplekst landskap",
  [tokens.ourCapability.description]:
    "Vi samarbeider med pålitelige tjenesteleverandører for å utvikle sikre, skalerbare løsninger som gir en sømløs og brukervennlig opplevelse for både våre kunder og deres brukere.",
  [tokens.capability.saasApplications]: "SaaS-applikasjoner",
  [tokens.capability.webMobileAppDevelopment]: "Web- og mobilapputvikling",
  [tokens.capability.productDevelopment]: "Produktutvikling",
  [tokens.capability.customSoftwareDevelopment]:
    "Skreddersydd programvareutvikling",
  [tokens.capability.customApiDevelopmentIntegration]:
    "Skreddersydd API-utvikling og integrasjoner",
  [tokens.portfolio.title]: "Portefølje",
  [tokens.portfolio.subtitle]:
    "Mangfoldige mennesker, mangfoldige prosjekter. Se hva vi har bygget.",
  [tokens.portfolio.seeAllProjects]: "Se Alle Prosjekter",
  [tokens.projects.kiddy.title]: "Kiddy",
  [tokens.projects.kiddy.subtitle]: "Kommunikasjon forenklet for alle",
  [tokens.projects.vibori.title]: "Vibori",
  [tokens.projects.vibori.subtitle]: "Se hva som skjer i ditt nærmiljø",
  [tokens.projects.malimo.title]: "Malimo",
  [tokens.projects.malimo.subtitle]: "Mer tid til læring!",
  [tokens.projects.norsk.title]: "Norsk Luftfartsmuseum",
  [tokens.projects.norsk.subtitle]:
    "Se hvordan luftfarten samlet Norge som nasjon",
  [tokens.projects.time4kids.title]: "Time4Kids",
  [tokens.projects.time4kids.subtitle]: "Revolusjonerende mekling",
  [tokens.projects.pailot.title]: "Pailot",
  [tokens.projects.pailot.subtitle]:
    "Pionerer innen transformative løsninger for ideell-sektoren",
  [tokens.testimonials.sectionTitle]: "Våre attester",
  [tokens.testimonials.title]: "Hva våre fantastiske kunder sier",
  [tokens.testimonials.testimonialtext1]:
    "Daxap har vært essensielle for oss. Vi har fått en teknisk løsning som lar oss vokse inn i fremtiden, med stabilitet og god brukerflyt. De har solid kompetanse og har løst alle nøtter underveis. I tillegg er de gode mennesker som står på for oss på alle plan.",
  [tokens.testimonials.position1]: "Grunnlegger,",
  [tokens.testimonials.testimonialtext2]:
    "Deres kompetanse og dedikasjon er avgjørende for utviklingen av vår løsning. Daxap forstår vår visjon og implementerer våre verdier i alle ledd av utviklingen. De ble et naturlig valg for oss, basert på deres tidligere arbeid og engasjement innen sosialt entreprenørskap.",
  [tokens.testimonials.position2]: "Grunnlegger,",
  [tokens.testimonials.testimonialtext3]:
    "Daxap AS har vært en nøkkelpartner i å utvikle våre produkter, og deres teknologiske ekspertise har hjulpet oss med å realisere vår visjon om å tilby innovative og brukervennlige verktøy for foreldre.Med deres fleksibilitet og evne til å levere skreddersydde løsninger har Daxap vært en pålitelig samarbeidspartner, noe som har styrket vår posisjon i markedet og gjort oss i stand til å tilby produkter av høy kvalitet.",
  [tokens.testimonials.position3]: "Medgründer,",
  [tokens.testimonials.testimonialtext4]:
    "Som kunde og samarbeidspartner av Daxap AS opplever vi at de er tilgjengelige og imøtekommende, gode på å se behov og bidra til effektive løsninger, og at de leverer høy faglig kompetanse i sitt arbeid.",
  [tokens.testimonials.position4]: "Medgründer,",
  [tokens.testimonials.testimonialtext5]:
    "I mitt samarbeid med Daxap; I styrerommet og med Daxap teamet som utvikler av IT løsninger opplever jeg at Daxap er en aktør som tar redelighet på alvor. Profesjonalitet beskriver teamet og ledelse som samtidig er hyggelige, jordnære folk som det er trivelig å være sammen med",
  [tokens.testimonials.position5]: "Leder av Business Incubator,",
  [tokens.blog.title]: "Les våre nyeste blogginnlegg",
  [tokens.blog.maintitle]: "Nyheter & Blogg",
  [tokens.blog.subtitle]:
    "Interessert i hvordan teknologi samspiller med samfunnsutfordringer og bærekraftig vekst? Sjekk ut våre nyeste artikler!",
  [tokens.blog.description]:
    "Utforsk bloggen vår for de siste oppdateringene om teknologi, innovasjon og vår reise.",
  [tokens.blog.readMore]: "Les Mer",
  [tokens.blog.recentPosts]: "Siste Innlegg",
  [tokens.blog.noPosts]: "Ingen tilgjengelige innlegg.",
  [tokens.blog.categories]: "Kategorier",
  [tokens.nav.ourTeam]: "Vårt team",
  [tokens.nav.socialEntrepreneurship]: "Sosialt entreprenørskap",
  [tokens.footer.mainOffice]: "Daxap hovedkvarter: Kuhaugen 4A, 7224 Melhus",
  [tokens.footer.norwayOffice]:
    "Daxap Oslo : Øvre Slottsgate 3, 0157 Oslo, Norge",
  [tokens.footer.copyright]: "Copyright © Daxap | Alle rettigheter reservert",
  [tokens.footer.menu]: "Meny",
  [tokens.footer.company]: "Selskap",
  [tokens.footer.contactUs]: "Kontakt oss",
  /* Social Entrepreneurship Page */
  [tokens.socialEntrepreneurship.title]: "Sosialt entreprenørskap",
  [tokens.socialEntrepreneurship.whatIs]: "Hva er sosialt entreprenørskap?",
  [tokens.socialEntrepreneurship.description]:
    "En sosial entreprenør er folk eller bedrifter som driver positiv endring ved å ta tak i samfunnets utfordringer gjennom innovative tilnærminger for å skape bærekraftige løsninger som gagner lokalsamfunn og miljøet.",
  [tokens.socialEntrepreneurship.focusChallenges]:
    "Fokus på samfunnsutfordringer",
  [tokens.socialEntrepreneurship.provenResults]:
    "Dokumenterte sosiale resultater",
  [tokens.socialEntrepreneurship.daxapQualifies]:
    "Hva kvalifiserer Daxap som sosial entreprenør, og hvorfor er dette viktig for oss?",
  [tokens.socialEntrepreneurship.daxapStory]:
    "Daxap ble grunnlagt i 2021 av Kubilay Kartal og Emirhan Sözan, som flyttet til Norge bare tre år tidligere. Begge grunnleggerne, sammen med sine familier, opplevde utfordringer med å integrere seg i samfunnet på grunn av språk- og kulturbarrierer. Inspirert av sine erfaringer, satte de seg mål om å gjøre livet enklere for andre, og Daxap ble skapt.Selskapet opererer med en dobbelt bunnlinje og måler suksess både gjennom sosial påvirkning og økonomiske resultater.Daxap samarbeider med organisasjoner som er forpliktet til å takle samfunnsutfordringer, noe som gjør at vi kan utvide vår rekkevidde og skape større innvirkning.Vårt samarbeid med SINTEF, en av Europas største uavhengige forskningsinstitusjoner, understreker denne forpliktelsen. Gjennom vår løsning, Kiddy, rapporterte 83 % av de som ikke har norsk som morsmål at Kiddy betydelig forbedret deres tilgang til essensiell kommunikasjon, og 77 % av alle brukere følte at den i stor grad forbedret kommunikasjonen generelt.",
  [tokens.socialEntrepreneurship.daxapPartners]:
    "Daxap samarbeider med organisasjoner som er forpliktet til å takle samfunnsutfordringer, noe som gjør at vi kan utvide vår rekkevidde og skape større innvirkning. Vårt samarbeid med SINTEF, en av Europas største uavhengige forskningsinstitusjoner, understreker denne forpliktelsen. Gjennom vår løsning, Kiddy, rapporterte 83 % av de som ikke har norsk som morsmål at Kiddy betydelig forbedret deres tilgang til essensiell kommunikasjon, og 77 % av alle brukere følte at den i stor grad forbedret kommunikasjonen generelt.",
  [tokens.featureGoals.title]: "Jobber for bærekraftsmålene",
  [tokens.featureGoals.description]:
    "Vi er forpliktet til å gjøre en positiv innvirkning på samfunnet gjennom vårt arbeid mot å oppnå FNs bærekraftsmål (SDG). Vår dedikasjon til sosial påvirkning og bærekraft er innprentet i alt vi gjør, fra utvikling av innovative digitale løsninger til våre bedriftspraksiser.",
  /* GOALS Titles */
  [tokens.goals.goodHealth]: "God helse og velvære",
  [tokens.goals.qualityEducation]: "Kvalitetsutdanning",
  [tokens.goals.reducedInequalities]: "Reduserte ulikheter",
  [tokens.goals.sustainableCities]: "Bærekraftige byer og lokalsamfunn",
  [tokens.goals.partnerships]: "Partnerskap for målene",
  /* Team Page */
  [tokens.team.founded]: "Grunnlagt i 2021",
  [tokens.team.activeMembers]: "46 aktive teammedlemmer",
  [tokens.team.meetTeam]: "Møt vårt team",
  [tokens.team.coreTeam]: "Kjernemedlemmer",
  [tokens.team.teamDescription]:
    "Teamet vårt er samlet med troen på at digitale løsninger kan løse samfunnsutfordringer.",
  [tokens.team.supporters]: "Folkene som støttet oss på vår reise",
  [tokens.team.meetTeam]: "Møt vårt team",
  [tokens.team.coreTeam]: "Kjernemedlemmer",
  [tokens.team.teamDescription]:
    "Teamet vårt er samlet med troen på at digitale løsninger kan løse samfunnsutfordringer.",
  [tokens.team.supporters]: "Folkene som støttet oss på vår reise",
  [tokens.team.chairman]: "Styreleder",
  [tokens.team.boardMember]: "Styremedlem",
  [tokens.team.ceo]: "Styremedlem & CEO",
  [tokens.team.headOfDevOps]: "DevOps-leder",
  [tokens.team.chiefProductOfficer]: "Produktsjef",
  [tokens.team.qaManager]: "QA-sjef",
  [tokens.team.marketingManager]: "Markedsføringssjef",
  [tokens.team.headOfAppDev]: "Apputviklingsleder",
  [tokens.team.marketer]: "Markedsfører",
  [tokens.team.globalSalesDirector]: "Global salgssjef",
  [tokens.team.businessDeveloper]: "Forretningsutvikler",
  [tokens.team.adviser]: "Rådgiver",
  /* Realize Your Vision  Page*/
  [tokens.realizeYourProject.realizeYourVision]: "Realiser Din Visjon",
  [tokens.realizeYourProject.bringYourVisionToLife]:
    "Gi din visjon liv med Daxap",
  [tokens.realizeYourProject.description]:
    "Har du en strålende idé du ønsker å realisere? Hos Daxap gir vi liv til ideer og konsepter. Vi utvikler alt fra minimum levedyktige produkter (Minimum Viable Product, MVP) for kapitalinnhenting og Proof of Concept, til fullt integrerte app- og web-løsninger for tusenvis av sluttbrukere. Vi har en unik og skalerbar tilnærming til hvert prosjekt.",
  [tokens.realizeYourProject.businessGrowth]: "Forretningsvekst",
  [tokens.realizeYourProject.targetAudience]: "Målgruppe",
  [tokens.realizeYourProject.whatSpecialty]: "Hva Spesialiserer Vi Oss I",
  [tokens.realizeYourProject.whyChooseDaxap]: "Hvorfor velge Daxap?",
  [tokens.realizeYourProject.whyChooseDaxapDescription]:
    "Vi spesialiserer oss på å lage skreddersydde digitale løsninger som hjelper bedrifter med å blomstre i dagens hurtige verden. Vår tilnærming er enkel: vi lytter, samarbeider og innoverer. Ditt syn blir vårt oppdrag. Med Daxap kan du forvente:",
  [tokens.realizeYourProject.businessGrowth]: "Forretningsvekst",
  [tokens.realizeYourProject.targetAudience]: "Målgruppe",
  [tokens.realizeYourProject.whatSpecialty]: "Hva Spesialiserer Vi Oss I",
  [tokens.realizeYourProject.whyChooseDaxap]: "Hvorfor velge Daxap?",
  /* Why Choose Daxap Translations */
  [tokens.whyChooseDaxap.customSolutions]: "Egendefinerte løsninger",
  [tokens.whyChooseDaxap.customSolutionsBody]:
    "Vi lager unike, spesiallagde løsninger basert på dine spesifikke behov.",
  [tokens.whyChooseDaxap.expertGuidance]: "Ekspertveiledning",
  [tokens.whyChooseDaxap.expertGuidanceBody]:
    "Vårt team av bransjeeksperter vil veilede deg gjennom hver fase av utviklingen, fra idé til utførelse.",
  [tokens.whyChooseDaxap.endToEndSupport]: "End-to-End støtte",
  [tokens.whyChooseDaxap.endToEndSupportBody]:
    "Enten det er prosjektledelse, design eller utvikling, tilbyr vi full støtte gjennom hele reisen.",
  [tokens.whyChooseDaxap.creativeCollaboration]: "Kreativt samarbeid",
  [tokens.whyChooseDaxap.creativeCollaborationBody]:
    "Jobb tett med et team som verdsetter kreativitet og innovasjon like mye som deg.",
  /* How It Works Translations */
  [tokens.howItWorks.shareYourVision]: "Del din visjon",
  [tokens.howItWorks.shareYourVisionText]:
    "Fortell oss om prosjektidéen din og målene dine. Jo flere detaljer du gir, jo mer kan vi skreddersy vår tilnærming for å levere nøyaktig det du trenger.",
  [tokens.howItWorks.collaborate]: "Samarbeid",
  [tokens.howItWorks.collaborateText]:
    "Vi jobber tett med deg for å designe og planlegge hvert aspekt av prosjektet ditt, slik at det samsvarer med din visjon og dine mål.",
  [tokens.howItWorks.createAndDeliver]: "Utvikling",
  [tokens.howItWorks.createAndDeliverText]:
    "Vårt team realiserer prosjektet ditt ved å bruke industriledende teknologi og beste praksis for å sikre førsteklasses kvalitet.",
  [tokens.howItWorks.ongoingSupport]: "Løpende støtte",
  [tokens.howItWorks.ongoingSupportText]:
    "Selv etter at prosjektet er fullført, er vi her for å tilby støtte og hjelpe deg med å skalere.",
  [tokens.howItWorks.sectionTitle]: "HVORDAN JOBBER VI",
  [tokens.howItWorks.mainTitle]: "Fra visjon til virkelighet",
  contact: {
    sectionTitle: "Kontakt oss",
    mainTitle: "Realiser din visjon i dag",
    getInTouch: "KONTAKT OSS",
    description:
      "Klar til å få ideen din på rett spor? Ta kontakt med en kort beskrivelse av hva du ønsker å oppnå, så kommer vi tilbake til deg med måter å realisere visjonen din på! ",
    form: {
      namePlaceholder: "Ditt navn *",
      emailPlaceholder: "E-post *",
      mobilePlaceholder: "Mobil *",
      serviceNeeded: "Tjeneste nødvendig",
      projectType: {
        webDevelopment: "Nettutvikling",
        mobileApp: "Mobilapp",
        qualityAssurance: "Kvalitetssikring",
      },
      descriptionPlaceholder: "Kort beskrivelse av prosjektet",
      sendMessage: "Send melding",
    },
    submissionReceived: {
      title: "Din innsending er mottatt.",
      message: "Vi vil komme tilbake til deg så snart som mulig.",
      newMessageBtn: "Ny melding",
    },
    errors: {
      fullName: "Vennligst spesifiser ditt fulle navn",
      validEmail: "Vennligst oppgi en gyldig e-postadresse",
      requiredField: "Dette feltet er påkrevd",
      validPhone: "Vennligst oppgi et gyldig telefonnummer (10 til 15 sifre)",
      validProjectType: "Dette feltet er påkrevd",
    },
  },
  careerCard: {
    sectionTitle: "Interessert i å bli med i Daxap?",
    mainTitle: "Bli med i et voksende team av lidenskapelige mennesker",
    description:
      "Vi tror på det å investere i talent og lidenskap. Vi ser alltid etter engasjerte personer som ønsker å bli med på vår reise og som vil utgjøre en forskjell. Enten du leter etter din neste karrieremulighet eller en spennende praksisplass vil vi gjerne høre fra deg!",
    interestedMessage: "INTERESSERT I Å BLI MED I DAXAP?",
    joinMessage: "Bli med i et voksende team av lidenskapelige mennesker",
  },
  careers: {
    dynamicTeamsTitle: "Dynamiske team",
    dynamicTeamsText:
      "Med fokus på fleksibilitet og tilpasningsevne kan dynamiske team i et IT-selskap raskt respondere på teknologiske endringer og bransjens krav.",
    greatTeammatesTitle: "Fantastiske lagspillere",
    greatTeammatesText:
      "Denne verdien fremhever viktigheten av å ha teammedlemmer som ikke bare har solid teknisk kompetanse, men som også utmerker seg i samarbeid, gjensidig respekt og støtte.",
    openCommunicationTitle: "Åpen kommunikasjon",
    openCommunicationText:
      "Denne verdien understreker viktigheten av åpenhet, regelmessige oppdateringer og fri flyt av ideer på alle nivåer i organisasjonen, noe som fremmer tillit og effektivitet.",
    autonomyAndAttitudeTitle: "Autonomi og holdning",
    autonomyAndAttitudeText:
      "Vi oppfordrer ansatte til å ta initiativ, være selvmotiverte og ta beslutninger som er i samsvar med selskapets mål, samtidig som de opprettholder en positiv og fremoverlent tankegang.",
    supportAndWinTitle: "Vinn gjennom støtte",
    supportAndWinText:
      "Det betyr at selskapet og de ansatte er forpliktet til å gi støtte til hverandre i sine profesjonelle bestrebelser, og skaper en vinn-vinn-situasjon for både individuell og selskapets vekst.",
    teamworkMakesTheDreamWorkTitle: "Sammen skaper vi suksess",
    teamworkMakesTheDreamWorkText:
      "Denne setningen oppsummerer troen på at kollektiv innsats, der teammedlemmer jobber sammen mot et felles mål, er grunnlaget for å oppnå ekstraordinære resultater og innovasjoner.",
  },
  contactForm: {
    readyMessage:
      "Klar til å bli med i et innovativt og dynamisk team? Ta det neste steget i karrieren din og søk i dag.",
    submitCVButton: "Send inn CV-en din",
  },
  /* Trust Center Page*/
  trustCenter: {
    securityTitle: "Sikkerhet hos Daxap",
    securityDescription:
      "Hos Daxap har vi som mål å skape de sikreste og mest pålitelige plattformene for våre kunder og deres sluttbrukere. Informasjonssikkerhet, personvern og beskyttelse av immaterielle rettigheter er kjernen i det vi gjør, og vi er forpliktet til å stadig heve standarden på disse områdene.",
    isoCertified: "ISO 27001 Sertifisert",
    gdprCompliant: "GDPR-kompatibel",
    environmentalCertified: "Miljøfyrtårnsertifisert",
  },
  [tokens.security.features.securelyDesigned]: "Sikkert designet",
  [tokens.security.features.encryption]: "Kryptering",
  [tokens.security.features.networkSegregation]:
    "Nettverkssegregering og sikkerhet",
  [tokens.security.features.accessToSystems]: "Tilgang til systemer",
  [tokens.security.features.loggingMonitoring]:
    "Logging og overvåking av systemene",
  [tokens.security.features.respondingIncidents]:
    "Reaksjon på sikkerhetshendelser",

  // Texts
  [tokens.security.features.securelyDesignedText]:
    "Fra visjon til konsept og sluttprodukt, Daxaps informasjonssikkerhetsstandarder blir anvendt gjennom hele livssyklusen av hver avtale.",
  [tokens.security.features.encryptionText]:
    "All data i transitt og i hvile er kryptert med sterke protokoller, inkludert TLS/SSL og AES.",
  [tokens.security.features.networkSegregationText]:
    "Daxap separerer systemene sine i distinkte nettverk for å forbedre beskyttelsen av sensitiv data.",
  [tokens.security.features.accessToSystemsText]:
    "Daxap følger prinsippet om minste privilegium, som tillater kun autorisert tilgang til systemer og krever at alle ansatte bruker Multi-Factor Authentication (MFA).",
  [tokens.security.features.loggingMonitoringText]:
    "Tilgang til Daxaps nettverk, systemer og kommunikasjon er sterkt beskyttet, men i tillegg blir all aktivitet logget og overvåket for å identifisere potensiell misbruk av systemer eller informasjon.",
  [tokens.security.features.respondingIncidentsText]:
    "Daxaps prosess for hendelseshåndtering er etablert for å korrekt identifisere, begrense, undersøke og utbedre hendelser som truer sikkerheten eller konfidensialiteten til data.",
  [tokens.faq.securityAndPrivacyProgram]:
    "Har organisasjonen din et program og retningslinjer for sikkerhet og personvern?",
  [tokens.faq.designatedLead]:
    "Har dere en utpekt sikkerhets-/personvernleder som administrerer sikkerhetsprogrammet deres?",
  [tokens.faq.externalAudit]: "Er plattformens sikkerhet eksternt revidert?",
  [tokens.faq.dataCenters]:
    "Har dere egne datasentre? Hvordan lagrer dere dataene mine?",
  [tokens.faq.incidentDetectionResponse]:
    "Hvordan oppdager og responderer organisasjonen din på en sikkerhetshendelse?",

  // Texts
  [tokens.faq.securityAndPrivacyProgramText]:
    "Ja. Daxap har etablert et Informasjonssikkerhetsstyringssystem (ISMS) fra selskapets oppstart og forvalter ISMS gjennom policyer, prosedyrer og pålitelige tjenesteleverandører.",
  [tokens.faq.designatedLeadText]:
    "Daxap oppfyller alle kravene til ISMS med sikkerhetsteamet, ledet av vår Chief Information Security Officer (CISO). Teamet implementerer og forvalter sikkerhetsprogrammet.",
  [tokens.faq.externalAuditText]:
    "Ja, Daxap har ISO 27001-sertifisering, som krever regelmessige eksterne revisjoner av vårt informasjonssikkerhetsstyringssystem og sikkerhetskontroller.",
  [tokens.faq.dataCentersText]:
    "Daxap eier ikke sine egne datasentre. I stedet bruker vi bransjeledende skyløsninger for å lagre og kassere dataene dine. All data er fullt kryptert, og systemene er bare tilgjengelige for personer og tjenester som definert i vårt ISMS.",
  [tokens.faq.incidentDetectionResponseText]:
    "Daxap har en policy og prosedyrer for hendelseshåndtering, samt programvare for proaktivt å oppdage hendelser eller trusler om hendelser. Vårt sikkerhetsteam oppdager, definerer og responderer på hendelser så snart de er identifisert. I tilfelle en sikkerhetshendelse, eller til og med trusselen om en, vil kundene våre motta informasjon om tid, natur og årsak til hendelsen, samt en plan for å sikre at fremtidige sårbarheter blir beskyttet.",
  /* security-white-paper page */
  [tokens.whitePaper.introduction]: "Introduksjon",
  [tokens.whitePaper.organizationalSecurity]: "Organisatorisk sikkerhet",
  [tokens.whitePaper.protectingCustomerData]: "Beskytte kundedata sikkerhet",
  [tokens.whitePaper.conclusion]: "Konklusjon",

  // Texts
  [tokens.whitePaper.introductionText]:
    "Daxaps oppdrag er å lage løsninger som har en positiv samfunnsmessig innvirkning og gjør hverdagen enklere for både organisasjoner og deres kunder. Vi mener at vi må gjøre dataene dine sikre og beskytte dataene dine. Det er et av våre viktigste ansvarsområder. Vi er dedikert til å være åpne om sikkerhetstiltakene våre og hjelpe deg med å forstå metodene våre.",
  [tokens.whitePaper.organizationalSecurityText]:
    "Daxap har etablert et informasjonssikkerhetsledelsessystem (ISMS) fra starten av selskapet og oppnådde ISO-27001-sertifisering i september 2023. Daxaps sikkerhetsprogram er i samsvar med ISO 27001 og utvikler seg stadig med andre bransjens beste praksiser. Daxap oppfyller alle kravene til ISMS med sikkerhetsteamet, ledet av vår Chief Information Security Officer (CISO). Teamet implementerer og forvalter sikkerhetsprogrammet. I denne forbindelse er det 31 policyer, 16 prosedyrer og 134 bevisoppgaver for å implementere kontrollene og klausulene i ISO-27001. Daxap har også etablert et eiendomsforvaltningssystem, hendelseshåndteringssystem, risikohåndteringssystem, osv., og gjennomført opplæring av ansatte og interne/eksterne revisjoner for å forvalte ISMS.",
  [tokens.whitePaper.protectingCustomerDataText]:
    "Som Daxap prioriterer vi sikkerheten og integriteten til informasjonen din. Etablering av ISMS og oppnåelse av ISO-27001-sertifisering understreker vårt engasjement for å opprettholde de høyeste standardene for informasjonssikkerhetsledelse, og sikre at dataene dine beskyttes med største omtanke og nøysomhet.",
  [tokens.whitePaper.conclusionText]:
    "Vi har et grunnleggende engasjement for å beskytte dataene dine. Vi mener at beskyttelse av dataene dine er en avgjørende plikt vi skylder kundene våre, og vi arbeider stadig for å opprettholde den tilliten. Vennligst ikke nøl med å kontakte oss for eventuelle bekymringer eller spørsmål.",
  [tokens.organizationalSecurity.statement1]:
    "Daxap skal kontinuerlig forbedre og tilpasse informasjonssikkerhetspraksiser til globale beste praksiser og standarder.",
  [tokens.organizationalSecurity.statement2]:
    "Daxap skal opprettholde de høyeste standardene for informasjonssikkerhetsledelse og sørge for at kundenes data er beskyttet.",
  [tokens.organizationalSecurity.statement3]:
    "Informasjon skal klassifiseres og håndteres i henhold til dens kritikalitet og sensitivitet i samsvar med relevante lovbestemte, regulatoriske og kontraktsmessige krav.",
  [tokens.organizationalSecurity.statement4]:
    "Passende kontakter skal opprettholdes med relevante myndigheter, spesielle interessegrupper eller andre spesialist sikkerhetsfora.",
  [tokens.organizationalSecurity.statement5]:
    "Krav til konfidensialitet eller ikke-offentliggjøringsavtaler som gjenspeiler organisasjonens behov for beskyttelse av informasjon skal identifiseres, regelmessig gjennomgås og dokumenteres.",
  [tokens.organizationalSecurity.statement6]:
    "All informasjon delt av Daxap med potensielle kunder, før de går inn i, og i løpet av, en kontrakt, skal gjøres i samsvar med de etablerte konfidensialitets- eller ikke-offentliggjøringsavtalene.",
  [tokens.organizationalSecurity.statement7]:
    "Daxaps informasjonseiendelsinventar skal gjennomgås og oppdateres når en ny eiendel legges til og/eller en eksisterende eiendel oppgraderes.",
  [tokens.organizationalSecurity.statement8]:
    "En risikovurderingsprosess for Daxaps informasjonseiendeler skal defineres og følges. Risikobehandling skal utføres gjennom prosessen med kontinuerlig forbedring.",
  [tokens.organizationalSecurity.statement9]:
    "Interne vurderinger eller revisjoner av Daxaps informasjonssikkerhetsprogram skal utføres periodisk, og eventuelle hull eller funn skal utbedres raskt.",
  [tokens.organizationalSecurity.statement10]:
    "Retningslinjer for informasjonssikkerhet skal gjennomgås regelmessig av ledelsen. Daxap-ansatte skal årlig bekrefte at de overholder disse retningslinjene og praksisene for informasjonssikkerhet.",
  [tokens.organizationalSecurity.statement11]:
    "Roller og ansvar for seniorledere og ansatte skal være klart definert og kommunisert til relevante personer.",
  [tokens.organizationalSecurity.statement12]:
    "Anti-virus- og anti-malware-løsninger skal implementeres på systemkomponenter.",
  [tokens.organizationalSecurity.statement13]:
    "Forebyggings-, oppdagelses- og gjenopprettingskontroller for å beskytte mot malware- og phishing-angrep skal implementeres av Daxap, og disse skal kombineres med passende brukerbevissthet.",
  [tokens.organizationalSecurity.statement14]:
    "Sikkerhetsbevissthetstreninger for ansatte skal tilbys regelmessig.",
  [tokens.organizationalSecurity.statement15]:
    "Planer for katastrofegjenoppretting (DRP) og planer for forretningskontinuitet (BCP) skal gjennomgås og testes minst årlig.",
  [tokens.organizationalSecurity.statement16]:
    "Endring og sårbarhetsstyringskontroller skal etableres og implementeres.",
  [tokens.customerDataSecurity.securelyDesign]:
    "Sikretdesign - Daxaps produkter er utviklet sikkert fra design til sluttstadium. Daxap har to hovedpolitikker for dette formålet: “Programvareutviklingspolicy” og “Sikker kodingspolicy”. Daxap skal opprettholde en robust sikker utviklingslivssyklus og følge OWASP sikker kodingspraksis.",
  [tokens.customerDataSecurity.dataInTransit]:
    "Alle data som er i transitt mellom Daxap-kunder og Daxap-tjenestene er kryptert ved hjelp av sterke protokoller. Daxap bruker de nyeste anbefalte sikre kryptosuitene for å kryptere all trafikk i transitt, inkludert Transport Layer Security (TLS), Internet Protocol Security (IPSec), Secure Shell (SSH), osv. når kundene støtter dem.",
  [tokens.customerDataSecurity.dataAtRest]:
    "For data i ro er Daxaps produksjonsnettverk kryptert ved hjelp av metoder som Advanced Encryption Standard (AES) eller RSA. Dette gjelder for alle typer data som lagres i Daxaps systemer, inkludert relasjonsdatabaser, fillagre og databasekopier.",
  [tokens.customerDataSecurity.encryption]:
    "Kryptering - Daxap bruker AWS som sine servere og datasentre hvor sikkerhet og fysisk beskyttelse er gitt.",
  [tokens.customerDataSecurity.networkSegregation]:
    "Nettverkssegregering og sikkerhet - Daxap deler systemene sine i distinkte nettverk for å forbedre beskyttelsen av sensitive data. Systemer som brukes til testing og utvikling er vert i et annet nettverk enn de som støtter Daxaps produksjonsinfrastruktur. Tilgang til Daxaps produksjonsmiljø fra åpne, offentlige nettverk (Internett) er begrenset. Daxap logger, overvåker og reviderer alle systemaktiviteter og oppretter varsler innen leverandørens muligheter for alle potensielle trusler.",
  [tokens.customerDataSecurity.accessToSystems]:
    "Tilgang til systemer - Når det gis tilgang, følger Daxap prinsippet om minste privilegium og “nekte alt” som standard, og tillater kun autorisert tilgang til systemene i samsvar med roller og ansvar for å forhindre enhver form for datalekkasje.",
  [tokens.customerDataSecurity.multiFactorAuthentication]:
    "Daxap krever at alle ansatte bruker Multi-Factor Authentication (MFA) for å logge inn på alle systemer der det er mulig og hensiktsmessig.",
  [tokens.customerDataSecurity.passwordManager]:
    "Daxap pålegger at ansatte bruker en autorisert passordbehandling som oppretter, lagrer og skriver inn distinkte og komplekse passord for å forhindre passordgjenbruk, phishing-angrep og andre passordrelaterte trusler.",
  [tokens.customerDataSecurity.loggingMonitoring]:
    "Logging og overvåking av systemene - Tilgang til Daxaps nettverk, systemer og kommunikasjon skal logges og overvåkes for å identifisere potensielt misbruk av systemer eller informasjon. Loggingsaktiviteter skal inkludere regelmessig overvåking av systemtilgang for å forhindre forsøk på uautorisert tilgang og bekrefte at tilgangskontrollsystemene er effektive. Loggservere og dokumenter skal oppbevares sikkert og kun gjøres tilgjengelig for autorisert personell. Disse loggene skal oppbevares så lenge det er nødvendig eller påkrevd for funksjonell bruk eller passende statlig regulering eller lov.",
  [tokens.customerDataSecurity.dataRetention]:
    "Databevaring og avhending - Bortskaffelse av kundedata vil bli utført i samsvar med den kontraktsmessige avtalen mellom Daxap og kunden. I fravær av noen kontraktsavtale skal et automatisk skript aktiveres på enhver Daxap-plattform som inneholder kundedata. Dette aktiverer en full hard sletting av kundedata på plattformen. Det er Daxaps hostingleverandørers ansvar å sikre at data blir fjernet fra diskene før de gjenbrukes.",
  [tokens.customerDataSecurity.securityIncidents]:
    "Reaksjon på sikkerhetshendelser - Daxap har etablert en prosess for hendelseshåndtering for å korrekt identifisere, inneholde, undersøke og utbedre hendelser som truer sikkerheten eller konfidensialiteten til Daxaps informasjonsaktiva. Alle sikkerhetshendelser administreres av Daxaps dedikerte personell. Ved behov vil sikkerhetshendelser bli rapportert utenfor Daxap umiddelbart av den utpekte personen.",
  [tokens.customerDataSecurity.vendorManagement]:
    "Tredjepartsleverandører (Leverandør) Håndtering - Daxap er avhengig av tredjepartsorganisasjoner/applikasjoner for å operere effektivt. Siden disse tredjeparts tjenestene kan påvirke sikkerheten til Daxaps produksjonsmiljø, tar vi nødvendige tiltak for å opprettholde våre sikkerhetsstandarder ved å opprette avtaler som forplikter disse tjenesteleverandørene til å opprettholde konfidensialitetsforpliktelsene. Daxap sikrer effektiviteten til disse organisasjonenes beskyttelsesforanstaltninger ved å gjennomgå kontrollene deres før engasjement og minst en gang i året.",
  [tokens.customerDataSecurity.thirdPartyValidation]:
    "Tredjepartsvalidering - Daxap overvåker kontinuerlig og forbedrer effektiviteten av ISMS-aktiviteter. Disse revisjonene utføres ikke bare av interne revisorer, men også av tredjeparts sertifiserte vurderere. Resultatene fra disse interne og eksterne revisjonene deles med toppledelsen og spores til løsning.",
  /* privacy-policy page */
  [tokens.privacyPolicy.title]: "Personvernerklæring",
  [tokens.privacyPolicy.introduction]:
    "Er du interessert i hvordan din personlige informasjon beskyttes på denne nettsiden? Da har du kommet til rett sted! Nedenfor kan du lese hvordan vi i daxap.io håndterer personlig informasjon, samt få informasjon om rettighetene dine som registrert hos oss. Vi håper du finner det du leter etter her. Du kan selvfølgelig også kontakte oss hvis du har spørsmål om behandlingen av informasjonen din. Vi er opptatt av sikkerhet og åpenhet på dette området, og vi tar forespørslene dine på alvor!",
  [tokens.privacyPolicy.treatmentManager.heading]: "Behandlingsansvarlig:",
  [tokens.privacyPolicy.treatmentManager.name]: "Navn:",
  [tokens.privacyPolicy.treatmentManager.contactPerson]: "Kontaktperson:",
  [tokens.privacyPolicy.treatmentManager.telephone]: "Telefon:",
  [tokens.privacyPolicy.treatmentManager.address]: "Adresse:",
  [tokens.privacyPolicy.processingPurpose]:
    "Hvorfor behandler vi personopplysninger?",
  [tokens.privacyPolicy.processingPurposeText]:
    "Vi samler inn og behandler personlig informasjon for å tilby besøkende på våre nettsteder de tjenestene og produktene de ber om. Vi ønsker også å tilby bedre og mer relevant innhold til den enkelte kunde, samt skape gode og varige forhold til våre eksisterende kunder.",
  [tokens.privacyPolicy.processingWhen]: "Når behandler vi personopplysninger?",
  [tokens.privacyPolicy.processingWhenText]:
    "Nedenfor finner du en liste over når og hvordan vi behandler personopplysninger i de enkelte tilfellene. Vær oppmerksom på at all behandling er basert på samtykke, med unntak av innsamling av data på kjøpstidspunktet, hvor visse opplysninger må oppgis for å fullføre kjøpet. I dette tilfellet er grunnlaget for behandlingen inngåelse av avtale.",
  [tokens.privacyPolicy.newsletter.heading]: "Nyhetsbrev:",
  [tokens.privacyPolicy.newsletter.where]: "Hvor: Nyhetsbrev",
  [tokens.privacyPolicy.newsletter.storedData]: "Hva lagres: E-postadresse.",
  [tokens.privacyPolicy.newsletter.usage]:
    "Bruk av personlig informasjon: For å sende ut nyhetsbrev.",
  [tokens.privacyPolicy.newsletter.storageTime]:
    "Lagringstid: På ubestemt tid. Inntil du melder deg av nyhetsbrevet eller ber om sletting.",
  [tokens.privacyPolicy.newsletter.basis]: "Grunnlag for behandling: Samtykke.",
  [tokens.privacyPolicy.newsletter.safety]:
    "Sikkerhet: Informasjonen lagres på en sikker server. Kontakt oss for mer informasjon om sikkerhet.",
  [tokens.privacyPolicy.newsletter.dataProcessor]:
    "Databehandler (tredjepart): –",
  [tokens.privacyPolicy.contactForm.heading]:
    "Kontaktskjema / kundehenvendelser:",
  [tokens.privacyPolicy.contactForm.where]: "Hvor: Kontaktskjema på nettsiden",
  [tokens.privacyPolicy.contactForm.storedData]:
    "Hva lagres: Fornavn, etternavn, telefonnummer, e-post + forespørsel.",
  [tokens.privacyPolicy.contactForm.usage]:
    "Bruk av personlig informasjon: Informasjonen brukes for å kunne svare på forespørselen din. Hvis du tidligere har sendt inn en forespørsel registrert med samme navn, telefonnummer eller e-postadresse, vil vi kunne se forespørselshistorikken din. Vi gjør dette for å kunne svare bedre på spørsmålene dine, spesielt hvis de er relatert til tidligere forespørsel eller kjøp.",
  [tokens.privacyPolicy.contactForm.storageTime]:
    "Lagringstid: Informasjonen knyttet til forespørslene dine lagres så lenge som nødvendig for å kunne svare på henvendelsene dine.",
  [tokens.privacyPolicy.contactForm.basis]:
    "Grunnlag for behandling: Samtykke.",
  [tokens.privacyPolicy.contactForm.safety]:
    "Sikkerhet: Informasjonen lagres på en sikker server. Kontakt oss for mer informasjon om sikkerhet.",
  [tokens.privacyPolicy.contactForm.dataProcessor]:
    "Databehandler (tredjepart): tawk.to, ClickUp",
  [tokens.privacyPolicy.cookies]: "Informasjonskapsler:",
  [tokens.privacyPolicy.cookiesText]:
    "Denne nettsiden bruker informasjonskapsler, eller såkalte cookies, for å gi deg en bedre brukeropplevelse på alle kanaler. Bruken av informasjonskapsler er basert på samtykke gjennom informasjonskapselinnstillingene i nettleseren din, samt din fortsatte bruk av sidene våre. Er du nysgjerrig på vår bruk av informasjonskapsler, samt hvordan du kontrollerer bruken av disse?",
  [tokens.privacyPolicy.processingBasis]:
    "Hva er nettstedets behandlingsgrunnlag?",
  [tokens.privacyPolicy.processingBasisText]:
    "DAXAP AS behandler visse typer personopplysninger. Du finner typene data vi behandler i oversikten ovenfor. Vårt behandlingsgrunnlag er i de fleste tilfeller samtykke, med ett unntak. Dette betyr at vi samler inn og behandler personopplysninger basert på samtykke fra den registrerte.",
  [tokens.privacyPolicy.rightsAccess]:
    "Dine rettigheter til å få tilgang til egen personlig informasjon:",
  [tokens.privacyPolicy.rightsAccessText]:
    "Hvis du er registrert i våre systemer, har du rett til å få tilgang til dataene vi har om deg, hvis du ønsker det. Dette betyr at du kan få dataene sendt til deg, eller du kan bli informert om hvor du kan finne disse dataene. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før du kan få tilgang. Kontakt behandlingsansvarlig hvis tilgang ønskes.",
  [tokens.privacyPolicy.rightsCorrection]:
    "Korrigering av egen personlig informasjon:",
  [tokens.privacyPolicy.rightsCorrectionText]:
    "Hvis du er registrert i våre systemer, og opplever at vi har registrert feil informasjon om deg, har du rett til å få denne informasjonen korrigert, eller du kan få tilgang til å korrigere dette selv. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før du kan få tilgang. Kontakt behandlingsansvarlig hvis korrigering ønskes.",
  [tokens.privacyPolicy.rightsDeletion]:
    "Sletting av egen personlig informasjon:",
  [tokens.privacyPolicy.rightsDeletionText]:
    "Hvis du er registrert i våre systemer, men ønsker at vi sletter informasjonen vi har om deg, har du rett til å gjøre det. Hvis du ønsker å slette dataene dine, vennligst kontakt oss via e-postadresse post@daxap.io. Vi forbeholder oss retten til at det i noen tilfeller kan være grunner til at dataene ikke kan slettes umiddelbart, hvis du har inngått en kontrakt med oss, og disse dataene er nødvendige for opprettholdelse av denne kontrakten. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før du kan få tilgang. Kontakt datakontrolleren hvis sletting ønskes.",
  [tokens.privacyPolicy.rightsRestriction]:
    "Begrensning av behandling av egen personlig informasjon:",
  [tokens.privacyPolicy.rightsRestrictionText]:
    "Hvis du er registrert i våre systemer, og ønsker å begrense behandlingen av dine personopplysninger, har du rett til å be om dette. Du kan be om at bestemte former for behandling stoppes, eller du kan be om at vi avslutter all behandling. Vi forbeholder oss retten til at det i noen tilfeller kan være juridiske grunner til at slik begrensning av behandlingen ikke kan gjennomføres. Sletting av data kan da være et alternativ, hvis en generell begrensning ikke er mulig i henhold til gjeldende forskrifter. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før vi kan foreta endringene. Kontakt behandlingsansvarlig hvis begrensning av behandlingen ønskes.",
  [tokens.privacyPolicy.dataPortability]: "Dataportabilitet:",
  [tokens.privacyPolicy.dataPortabilityText]:
    "Hvis du er registrert i våre systemer, og innsamlingen og behandlingen av denne personopplysningen skjer automatisk og er basert på samtykke, har du rett til å overføre denne informasjonen til andre leverandører. Du kan be om dette fra oss, og du har da rett til å motta denne informasjonen i et strukturert, allment brukt og maskinlesbart format. Du kan også ha rett til at disse dataene overføres direkte fra oss til en annen leverandør, hvis dette er teknisk mulig. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før vi kan gjøre det. Kontakt datakontrolleren hvis overføring eller overføring av dine personopplysninger ønskes.",
  [tokens.privacyPolicy.withdrawConsent]:
    "Tilbaketrekking av samtykke til behandling av egne personopplysninger:",
  [tokens.privacyPolicy.withdrawConsentText]:
    "Med unntak av informasjon du gir i forbindelse med kjøp, er all behandling av personopplysninger av Daxap AS basert på samtykke. Du har rett til å trekke tilbake samtykket ditt når som helst, og behandlingen av disse personopplysningene for det aktuelle formålet skal da opphøre. Hvis informasjonen kun brukes til det formålet som du trekker tilbake samtykket ditt for, må også informasjonen slettes. I noen tilfeller kan vi være nødt til å be om tilleggsinformasjon om deg for å bekrefte identiteten din før vi kan gjøre det. Kontakt datakontrolleren hvis du ønsker å trekke tilbake samtykket ditt til en eller flere behandlinger av personopplysninger.",
  [tokens.privacyPolicy.complaint]:
    "Klage på behandling av egne personopplysninger:",
  [tokens.privacyPolicy.complaintText]:
    "Hvis du ønsker å klage på måten informasjonen din behandles på, eller noe annet relatert til dine personopplysninger, har du rett til å gjøre det. Vi håper du vil sende en e-post eller kontakte datakontrolleren (kontaktinformasjonen for datakontrolleren finner du øverst på denne siden). Datakontrolleren er ansvarlig for å ivareta rettighetene dine og hjelper deg med det du måtte lure på. Du har også rett til å kontakte Datatilsynet hvis du er uenig i avgjørelser eller behandlingsmetoder hos Daxap AS.",
  /* cookie-policy page */
  [tokens.cookiePolicy.title]:
    "Hva er en informasjonskapsel og hva brukes de til?",
  [tokens.cookiePolicy.description]:
    "En informasjonskapsel er en liten tekstfil i alfanumerisk format som installeres på brukerens enhet av serveren til det besøkte nettstedet, eller av en tredjepartsserver (f.eks. webanalyse). Når du besøker Daxap-nettstedet, lagres informasjonskapsler i nettleseren din. Vi bruker denne informasjonen til: Vi tar også hensyn til sikkerhet og personvern, og sikrer at applikasjonen din følger gjeldende regler og retningslinjer. Hvis du vil utvikle din egen applikasjon eller lære mer om appene våre, kan du kontakte oss for en uforpliktende samtale om dine behov og muligheter. Vi ser frem til å hjelpe deg med å realisere ideene dine og forbedre din digitale tilstedeværelse.",
  [tokens.cookiePolicy.userAccount]:
    "Vite hvilken brukerkonto som er logget inn for å kunne vise korrekt data for brukeren.",
  [tokens.cookiePolicy.googleAnalytics]:
    "Google Analytics for å få statistikk over trafikken til nettstedet.",
  [tokens.cookiePolicy.marketing]: "Markedsføring",
  [tokens.cookiePolicy.cookiesListTitle]:
    "Liste over informasjonskapsler som brukes på nettstedet",
  [tokens.cookiePolicy.noCookies]: "Nei",
  [tokens.cookiePolicy.thirdPartyCookiesTitle]:
    "Informasjonskapsler utstedt av tredjeparter",
  [tokens.cookiePolicy.thirdPartyCookiesDescription]:
    "Informasjonskapsler kan utstedes på nettstedet vårt av tredjeparter som følge av integrerte tredjepartsapplikasjoner på nettstedet vårt, via tredjepartsinnhold. Med disse informasjonskapslene kan du: Gjenkjenne enheten mens du surfer på alle andre nettsteder eller tjenester, og hvilke annonsører eller tredjeparter som utsteder informasjonskapsler, tilpasse innholdet til terminalen din.",
  [tokens.cookiePolicy.marketingCookiesTitle]:
    "Informasjonskapsler vi bruker for markedsføring og hva de registrerer:",
  [tokens.cookiePolicy.googleAnalyticsTitle]: "Google Analytics",
  [tokens.cookiePolicy.googleAnalyticsDescription]:
    "For en bedre brukeropplevelse. Et analyseverktøy som registrerer brukerens mønster på daxap.io, slik at vi bedre forstår hvordan du bruker nettstedene våre. Dataene brukes til å forbedre nettstedet og brukeropplevelsen din. På Googles egne sider kan du lese mer om hvordan de samler inn og beskytter data.",
  [tokens.cookiePolicy.googleConversionTitle]: "Google Conversion Tracking",
  [tokens.cookiePolicy.googleConversionDescription]:
    "Registrerer hva som skjer etter at du har klikket på en av annonsene våre. Gjør det mulig for oss å tilpasse brukeropplevelsen på våre landingssider bedre for deg.",
  [tokens.cookiePolicy.googleRemarketingTitle]: "Google Dynamisk Remarketing",
  [tokens.cookiePolicy.googleRemarketingDescription]:
    "Samler informasjon som brukes til å vise deg tilpassede annonser basert på hvilke deler av daxap.io du har besøkt.",
  [tokens.cookiePolicy.facebookTitle]:
    "Facebook Connect & Facebook Custom Audience",
  [tokens.cookiePolicy.facebookDescription]:
    "Brukes for å verifisere om du har en Facebook-profil, for å kunne nå deg med Facebook-annonser etter at du har besøkt daxap.io, samt for å måle hvordan annonsene fungerer. Dataene anonymiseres, og ingen personlig informasjon registreres. Les mer om Facebooks personvernregler og hvordan du kan endre dem i innstillingene dine.",
  [tokens.cookiePolicy.linkedinTitle]:
    "LinkedIn Ads & LinkedIn Marketing Solutions",
  [tokens.cookiePolicy.linkedinDescription]:
    "Gjør det mulig for oss å lage målrettede annonser på LinkedIn mot tidligere besøkende til daxap.io. Formålet er å lage mer relevante annonser for den enkelte bruker basert på tidligere interesseområder på daxap.io. Disse kapslene registrerer ingen personlig informasjon, og all data anonymiseres. Les mer om hvordan du kan administrere personverninnstillingene dine.",
  [tokens.cookiePolicy.googleTagManagerTitle]: "Google Tag Manager",
  [tokens.cookiePolicy.googleTagManagerDescription]:
    "Brukes til å organisere og publisere skript og tagger på nettstedet.",
  [tokens.cookiePolicy.tawkToTitle]: "Tawk.to",
  [tokens.cookiePolicy.tawkToDescription]:
    "Brukes til å kommunisere på nettstedet.",
  [tokens.cookiePolicy.acceptRejectTitle]:
    "Akseptere/Avvise informasjonskapsler",
  [tokens.cookiePolicy.acceptRejectDescription]:
    "Du kan til enhver tid velge å akseptere eller avvise installasjonen av informasjonskapsler på terminalen din ved å konfigurere nettleseren din.",
  [tokens.cookiePolicy.acceptCookiesTitle]:
    "Hvis du aksepterer informasjonskapsler",
  [tokens.cookiePolicy.acceptCookiesDescription]:
    "Registreringen av en informasjonskapsel på datamaskinen eller telefonen din er underlagt din vilje. Du kan uttrykke og endre valgene dine når som helst og gratis gjennom alternativene som er tilgjengelige i nettleseren din. Hvis du har akseptert lagringen av informasjonskapsler i enheten din i nettleseren din, vil de integrerte informasjonskapslene på sidene og innholdet du har besøkt bli midlertidig lagret i et dedikert rom i enheten din. De vil bare bli gjort synlige for avsenderen.",
  [tokens.cookiePolicy.benefitsTitle]:
    "Hvilken fordel får jeg ved å akseptere informasjonskapsler?",
  [tokens.cookiePolicy.benefitsDescription]:
    "Når du besøker Daxap-nettstedet, lagrer disse informasjonskapslene informasjon på enheten din. Vi bruker denne informasjonen, blant annet, til å tilby produkter i samsvar med de du har valgt ved tidligere besøk, og dermed gjøre navigasjonen på nettstedet vårt enklere.",
  [tokens.cookiePolicy.rejectCookiesTitle]:
    "Hvis du avviser informasjonskapsler",
  [tokens.cookiePolicy.rejectCookiesDescription]:
    "Hvis du avviser informasjonskapsler på terminalen, eller hvis du fjerner de som er registrert, kan du ikke dra nytte av en rekke funksjoner som fortsatt er nødvendige for å navigere på visse deler av nettstedet vårt. Dette vil være tilfellet hvis du prøver å få tilgang til innhold eller tjenester som krever innlogging. Det er også tilfelle når vi eller våre leverandører ikke kan gjenkjenne (for teknisk regnskap) hvilken type nettleser som brukes av terminalen, innstillinger og visningsspråk eller landet der enheten ser ut til å være koblet til internett. I så fall fraskriver Daxap AS seg alt ansvar for konsekvensene av redusert drift av tjenestene våre når det er umulig for oss å lagre eller vise nødvendige informasjonskapsler som du har avvist eller slettet.",
  [tokens.cookiePolicy.browserSettingsTitle]: "Innstilling av nettleseren din",
  [tokens.cookiePolicy.browserSettingsDescription]:
    "Innstillingen av bruken av informasjonskapsler avhenger av nettleseren. Det er beskrevet i hjelpemenyen i nettleseren din.",
  [tokens.cookiePolicy.ieSettingsTitle]: "For Internet Explorer",
  [tokens.cookiePolicy.ieSettingsDescription]:
    "For å tilpasse innstillingene for informasjonskapsler til et nettsted i Internet Explorer, klikk på Verktøy og deretter Alternativer for Internett. Klikk på Personvern-fanen, deretter nettsteder Skriv inn den fullstendige adressen (URL) til nettstedet du vil tilpasse personverninnstillingene i adressekolonnen. For eksempel http://www.microsoft.com. Hvis du vil tillate informasjonskapsler fra det angitte nettstedet på datamaskinen din, klikker du Tillat. For å hindre lagring av informasjonskapsler fra det angitte nettstedet på datamaskinen din, klikker du Avvis. Gjenta trinn 3 og 4 for hvert nettsted for å blokkere eller tillate. Når du er ferdig, klikker du OK to ganger.",
  [tokens.cookiePolicy.safariSettingsTitle]: "For Safari",
  [tokens.cookiePolicy.safariSettingsDescription]:
    "Gå til Favoritter og klikk deretter på kategorien for personvern og informasjonskapsler.",
  [tokens.cookiePolicy.chromeSettingsTitle]: "For Chrome",
  [tokens.cookiePolicy.chromeSettingsDescription]:
    "Klikk på skiftenøkkel-ikonet på nettleserens verktøylinje. Velg Innstillinger. Klikk på Vis avanserte innstillinger. I delen 'Personvern' klikker du på innstillingsknappen for innhold. I delen 'Informasjonskapsler' kan du endre følgende parametere: Fjern informasjonskapsler, blokker informasjonskapsler automatisk, aksepter inform",
  /* Sustainability Page */
  [tokens.title]: "Bærekraft",
  [tokens.subtitle1]: "Daxaps forpliktelse til bærekraft og miljøansvar",
  [tokens.description1]:
    "Hos Daxap mener vi at teknologi skal skape positiv endring – ikke bare for våre kunder og samfunnet, men også for miljøet. Vår forpliktelse til bærekraftig utvikling er sentralt i alt vi gjør, fra tjenestene vi leverer til hvordan vi opererer internt. Som en del av vårt kontinuerlige engasjement ønsker vi å gjøre vårt arbeid transparent og tilgjengelig, og dele hvordan vi bidrar til en mer bærekraftig verden.",
  [tokens.subtitle2]: "Våre miljø- og klimamål",
  [tokens.description2]:
    "I tråd med FNs Bærekraftsmål (SDGs), særlig Mål 12.6, er vi forpliktet til å adoptere bærekraftige praksiser og integrere disse i våre operasjoner. Vi har implementert flere tiltak for å redusere vårt miljøavtrykk, inkludert energieffektive utviklingsprosesser, grønn koding og fremme miljøvennlige arbeidsvaner.",
  [tokens.ongoingEffortsTitle]: "Pågående innsats og prestasjoner",
  [tokens.transparencyTitle]: "Åpenhet og ansvarlighet",
  [tokens.transparencyDescription]:
    "Vi tror på åpenhet. Som en del av dette deler vi åpent våre årlige bærekraftsrapporter, inkludert vårt klimaregnskap og resultatene av våre bærekraftinitiativer. Du kan få tilgang til detaljert informasjon om vår miljøpåvirkning og pågående prosjekter gjennom våre bærekraftsrapporter, som er tilgjengelige på denne siden. For mer informasjon om Daxaps miljøinnsats eller for å be om en kopi av våre bærekraftsrapporter, kontakt oss direkte. Sammen kan vi bygge en bedre, grønnere fremtid gjennom teknologi.",
  [tokens.energyEfficiencyTitle]: "Energieffektivitet",
  [tokens.energyEfficiencyText]:
    "Vi fokuserer på å redusere energiforbruket til de digitale tjenestene vi utvikler for kundene. Dette inkluderer optimalisering av infrastrukturen vår og sørge for at vi følger beste praksis for energieffektiv programmering.",
  [tokens.sustainableDevelopmentTitle]: "Bærekraftige praksiser i utvikling",
  [tokens.sustainableDevelopmentText]:
    "Vårt team jobber kontinuerlig for å minimere miljøpåvirkningen av programvareutvikling. Dette innebærer bruk av teknologier og metoder som ikke bare er innovative, men også bevisste på energiforbruk og bærekraft.",
  [tokens.employeeEngagementTitle]: "Ansattes engasjement",
  [tokens.employeeEngagementText]:
    "Vi fremmer en arbeidskultur som vektlegger miljøbevissthet. Alle Daxap-ansatte er opplært i bærekraftspraksis, og vi oppfordrer til aktiv deltakelse i våre grønne initiativer.",
  /* App Dev Page */
  [tokens.appDevelopment.sidebarTitle]: "Webutvikling",
  [tokens.appDevelopment.title]:
    "Daxap app-utvikling – fra MVP til topp rangerte apper",
  [tokens.appDevelopment.description]:
    "Fra å utvikle våre egne Software as a Service (SaaS)-plattformer til å lage applikasjoner for våre kunder, skaper vi skalerbare, sikre og brukervennlige produkter.",
  [tokens.appDevelopment.feature1]:
    "Komplett in-house utviklingsteam, fra Product Owners (PO) til front-end og back-end utviklere, testere, brukergrensesnitt (UI) og brukeropplevelse (UX) designere.",
  [tokens.appDevelopment.feature2]:
    "Daxaps ISO 27001 Informasjonssikkerhetsstyringssystemer (ISMS)-sertifisering betyr at alle apper er bygget etter de høyeste sikkerhetsstandardene, inkludert GDPR.",
  [tokens.appDevelopment.feature3]:
    "Sømløs integrasjon med alle iOS, Android og Windows operativsystemer og globale appbutikker.",
  [tokens.appDevelopment.feature4]:
    "Integrering av Application Programming Interfaces (API’er), fra betalingsløsninger til HR og kalenderintegrasjoner.",
  [tokens.appDevelopment.feature5]:
    "Bruk av Agile-Scrum-metodikken sikrer at endringer og oppdateringer kan gjøres raskt uten store budsjettmessige forsinkelser.",
  [tokens.appDevelopment.ourApps]: "Våre apper",
  /* Web Dev Page */
  [tokens.webDevelopment.sidebarTitle]: "Apputvikling",
  [tokens.webDevelopment.title]:
    "Daxap webutvikling – skalerbare løsninger som vokser med dine behov",
  [tokens.webDevelopment.description]:
    "Daxap skaper plattformer som forenkler hvordan sluttbrukeren interagerer med våre nettsider, samtidig som vi integrerer systemer som sparer tid og penger for våre kunder.",
  [tokens.webDevelopment.feature1]:
    "Komplett in-house utviklingsteam, fra Product Owners (PO) til front-end og back-end utviklere, testere, brukergrensesnitt (UI) og brukeropplevelse (UX) designere.",
  [tokens.webDevelopment.feature2]:
    "Daxaps ISO 27001 Informasjonssikkerhetsstyringssystemer (ISMS)-sertifisering betyr at alle apper er bygget etter de høyeste sikkerhetsstandardene, inkludert GDPR.",
  [tokens.webDevelopment.feature3]:
    "Integrering av Application Programming Interfaces (API-er), fra betalingsløsninger til HR og kalenderintegrasjoner.",
  [tokens.webDevelopment.feature4]:
    "Bruk av Agile-Scrum-metodikken sikrer at endringer og oppdateringer kan gjøres raskt uten store budsjettmessige forsinkelser.",
  [tokens.webDevelopment.ourWebApps]: "Våre Web-apper",
  /* QA Page */
  [tokens.qaDevelopment.sidebarTitle]: "Kvalitetssikring",
  [tokens.qaDevelopment.title]:
    "Daxap kvalitetssikring – beskytter dine digitale produkter.",
  [tokens.qaDevelopment.description]:
    "Våre kvalitetssikringstjenester (QA) er skreddersydd for å møte de spesifikke behovene til prosjektet ditt, med nøye oppmerksomhet på detaljer og en omfattende tilnærming til testing.",
  [tokens.qaDevelopment.feature1]:
    "Vår QA-tjeneste er skreddersydd for å møte de spesifikke behovene til prosjektet ditt, med nøye oppmerksomhet på detaljer og en omfattende tilnærming til testing.",
  [tokens.qaDevelopment.feature2]:
    "Vi er stolte av å levere QA-løsninger som er tilpasset merkevarens standarder og forventninger. Vårt team av erfarne QA-fagfolk bruker bransjeledende verktøy og metodologier for å grundig vurdere funksjonalitet, brukervennlighet og ytelse til programvaren din.",
  [tokens.qaDevelopment.feature3]:
    "I tillegg til manuell testing, spesialiserer vi oss også på API- og automatiseringstesting for å sikre sømløs integrering og funksjonalitet av dine digitale produkter på tvers av plattformer. Våre automatiserte testprosesser muliggjør rask og effektiv validering av programvarefunksjonalitet, noe som sparer tid og ressurser samtidig som høye kvalitetsstandarder opprettholdes.",
  [tokens.qaDevelopment.feature4]:
    "Med vår QA-tjeneste kan du stole på at dine digitale produkter vil oppfylle de høyeste kvalitetsstandardene, og sikre en sømløs brukeropplevelse som bidrar til å drive suksessen til virksomheten din. La oss hjelpe deg med å oppnå fremragende resultater innen kvalitetssikring av programvare og løfte din digitale tilstedeværelse til nye høyder.",
  /* Portfolio Page */
  [tokens.ourPortfolio.subtitle]: "Portefølje",
  [tokens.ourPortfolio.title]: "Innovative digitale løsninger",
  [tokens.ourPortfolio.description]:
    "Hos Daxap er vi stolte av vår evne til å levere skreddersydde digitale løsninger som ikke bare møter, men som overgår våre kunders forventninger. Vår utviklingsprosess er bygget på Agile-Scrum-metodikken, en tilnærming som gir oss fleksibiliteten til å tilpasse oss raskt og effektivt til endringer samtidig som vi kontinuerlig leverer funksjonaliteter og verdi. Denne metoden har vist seg å være spesielt effektiv for å møte dagens krav til rask utvikling og lansering av digitale produkter.",
  [tokens.ourPortfolio.contactWithUs]: "Kontakt Oss",
  [tokens.ourPortfolio.whyChooseDaxap.subtitle]: "Eksperter i Utvikling",
  [tokens.ourPortfolio.whyChooseDaxap.title]: "Hvorfor Velge Daxap",
  [tokens.ourPortfolio.whyChooseDaxap.feature1Title]: "Agile-Scrum",
  [tokens.ourPortfolio.whyChooseDaxap.feature1Text]:
    "Ved å dele utviklingsarbeidet inn i mindre, håndterbare sprinter, kan vi regelmessig levere nye funksjoner og forbedringer, slik at våre kunder kan se og teste nye deler av produktet.",
  [tokens.ourPortfolio.whyChooseDaxap.feature2Title]:
    "Et dedikert og mangfoldig team",
  [tokens.ourPortfolio.whyChooseDaxap.feature2Text]:
    "Daxap-teamet består av dyktige fagfolk innen ulike disipliner, inkludert Product Owners (PO), Backend Engineers (BE), Frontend Engineers (FE), App-utviklere, Testere og DevOps Engineers.",
  [tokens.ourPortfolio.whyChooseDaxap.feature3Title]: "ISO 27001",
  [tokens.ourPortfolio.whyChooseDaxap.feature3Text]:
    "Kvalitet og sikkerhet er grunnleggende elementer i vår utviklingsprosess. Daxap er stolt over å være ISO 27001-sertifisert, en globalt anerkjent standard som sikrer at vi følger strenge protokoller for informasjonssikkerhet.",
  [tokens.ourPortfolio.whyChooseDaxap.feature4Title]:
    "Effektiv prosjektledelse",
  [tokens.ourPortfolio.whyChooseDaxap.feature4Text]:
    "For å sikre en sømløs arbeidsflyt bruker vi moderne verktøy som Figma for UI/UX-design og ClickUp for prosjektledelse. Figma gjør det mulig for vårt designteam å lage intuitive og brukervennlige grensesnitt.",
  [tokens.ourPortfolio.whyChooseDaxap.feature5Title]:
    "Funksjonsbasert levering",
  [tokens.ourPortfolio.whyChooseDaxap.feature5Text]:
    "Hos Daxap handler det ikke bare om å levere et produkt, men om å bygge et langsiktig partnerskap med våre kunder. Vi er ikke en typisk rådgivning som fakturerer per time.",
  [tokens.ourPortfolio.whyChooseDaxap.feature6Title]: "En fleksibel partner",
  [tokens.ourPortfolio.whyChooseDaxap.feature6Text]:
    "I tillegg til å tilby utviklingstjenester, er Daxap også åpen for muligheten for å bli medeier i prosjekter der dette er ønskelig. Vi forstår at noen prosjekter har unik potensial.",
  [tokens.ourPortfolio.portfolioOurProjects.subtitle]: "Våre Prosjekter",
  [tokens.ourPortfolio.portfolioOurProjects.title]:
    "Mangfoldige mennesker, mangfoldige prosjekter. Sjekk ut hva vi har bygget",
  /* Contact Us Page */
  [tokens.contactUs.ourOfficeAddress]: "Vår kontoradresse",
  [tokens.contactUs.daxapHeadquarters]: "Daxap hovedkontor",
  [tokens.contactUs.daxapOslo]: "Daxap Oslo",
  [tokens.contactUs.sectionTitle]: "KONTAKT OSS",
  [tokens.contactUs.mainTitle]: "La oss få det til å skje",
  [tokens.contactUs.description]:
    "Klar til å begynne? Fyll ut skjemaet, så kontakter vi deg.",
  [tokens.contactUs.form.namePlaceholder]: "Navn *",
  [tokens.contactUs.form.emailPlaceholder]: "E-post *",
  [tokens.contactUs.form.messagePlaceholder]: "Melding *",
  [tokens.contactUs.form.sendMessage]: "Send",
  [tokens.contactUs.submissionReceived.title]: "Din innsending er mottatt.",
  [tokens.contactUs.submissionReceived.message]:
    "Vi vil kontakte deg så snart som mulig.",
  [tokens.contactUs.errors.fullName]: "Vennligst oppgi et gyldig fullt navn",
  [tokens.contactUs.errors.validEmail]:
    "Vennligst oppgi en gyldig e-postadresse",
  [tokens.contactUs.errors.requiredField]: "Dette feltet er obligatorisk",
  /* blog detail page */
  [tokens.tags]: "Stikkord :",
  [tokens.share]: "Del :",
  [tokens.recentPosts]: "Nylige innlegg :",
  /* Kiddy Detail Page */
  [tokens.kiddy.appName]: "Kiddy",
  [tokens.kiddy.projectInfo]: "Prosjektinformasjon",
  [tokens.kiddy.client]: "Kunde:",
  [tokens.kiddy.clientText]: "Institusjoner med barn og foreldre",
  [tokens.kiddy.date]: "Dato:",
  [tokens.kiddy.category]: "Kategori:",
  [tokens.kiddy.categoryText]: "Utdanning og kommunikasjonsapp",
  [tokens.kiddy.projectDesc]: "Prosjektbeskrivelse",
  [tokens.kiddy.overview]: "Oversikt",
  [tokens.kiddy.overviewText]:
    "Kiddy er en flerspråklig kommunikasjonsplattform og app som forenkler kommunikasjonen mellom foreldre og institusjoner som skoler, barnehager og idrettsklubber. Med Kiddy kan brukere følge med på viktig informasjon, som timeplaner, barnets utvikling og daglige aktiviteter, alt på ett sted. Kiddy-appen trygger foreldrene og gjør at de kan holde seg oppdatert uansett hvor de er.",
  [tokens.kiddy.challenges]: "Utfordringer",
  [tokens.kiddy.challengesText]:
    "Foreldre og foresatte, spesielt de med innvandrerbakgrunn, opplever det som utfordrende å kommunisere effektivt med institusjoner som skoler, barnehager og idrettsklubber. Dette får konsekvenser for barnas evne til å føle seg inkludert.",
  [tokens.kiddy.solutions]: "Løsninger",
  [tokens.kiddy.solutionsText]:
    "Kiddy ble utviklet med fokus på flerspråklig- og brukervennlighet. Vi fokuserte på brukervennlig design, intuitiv navigasjon og flerspråklige funksjoner. I tillegg integrerte vi sanntidsvarsler, for å holde foreldrene informert om barnets aktiviteter. Webadmin/admin-modulen ble også designet for å spare ansatte og administratorer for tid.",
  [tokens.kiddy.outcomes]: "Resultater",
  [tokens.kiddy.outcomesText]:
    "Kiddy har mottatt utmerket tilbakemelding fra både foreldre og omsorgspersoner. Appen har blitt rost for sin brukervennlighet og for hvordan den strømlinjeformer kommunikasjonen mellom familier og ansatte. I en undersøkelse utført av SINTEF svarte 77% av foreldrene at Kiddy gjorde kommunikasjon mye bedre, og 83% svarte at den flerspråklige funksjonen gav informasjon de ellers ikke hadde forstått.",
  [tokens.kiddy.keyFeatures]: "Nøkkelfunksjoner i Kiddy",
  [tokens.kiddy.dailyActivity]: "Daglige aktiviteter",
  [tokens.kiddy.dailyActivityText]:
    "Daglige aktiviteter Foreldre kan se bilder, sovetider og aktiviteter gjennom dagen.",
  [tokens.kiddy.realTime]: "Sanntidsoppdateringer",
  [tokens.kiddy.realTimeText]:
    "Ansatte kan sende oppdateringer, meldinger og bilder til foreldre i sanntid.",
  [tokens.kiddy.childProgress]: "Fremgangsrapporter",
  [tokens.kiddy.childProgressText]:
    "Regelmessige rapporter om barnets utvikling og milepæler.",
  [tokens.kiddy.safeSecure]: "Sikker og trygg",
  [tokens.kiddy.safeSecureText]:
    "All personlig data krypteres og lagres sikkert.",
  [tokens.kiddy.testimonial]: "Tilbakemelding",
  /* Vibori Detail Page */
  [tokens.vibori.appName]: "Vibori",
  [tokens.vibori.projectInfo]: "Prosjektinformasjon",
  [tokens.vibori.client]: "Kunde:",
  [tokens.vibori.clientText]: "Kommuner og samfunn",
  [tokens.vibori.date]: "Dato:",
  [tokens.vibori.category]: "Kategori:",
  [tokens.vibori.categoryText]: "Samfunnsengasjementsapp",
  [tokens.vibori.projectDesc]: "Prosjektbeskrivelse",
  [tokens.vibori.overview]: "Oversikt",
  [tokens.vibori.overviewText]:
    "Vibori ble utviklet for å holde innbyggere informerte og engasjerte i aktiviteter i sitt lokalmiljø.Med Vibori-appen får brukerne tilgang på viktige oppdateringer, nyheter, og arrangementer i sin kommune. Vibori gjør det enkelt å finne aktiviteter og velge spesifikke interesser for å motta varsler om arrangementer i nærområdet.Med fokus på å skape et sammenkoblet og informert fellesskap, bidrar Vibori til å forbedre deltakelse og engasjement, noe som mange lokalsamfunn sliter med verden over. ",
  [tokens.vibori.challenges]: "Utfordringer",
  [tokens.vibori.challengesText]:
    "Lokalsamfunn sliter med å engasjere innbyggerne i lokale arrangementer, aktiviteter og frivillig arbeid. På grunn av algoritmene på plattformer som Facebook og Instagram har grasrotorganisasjoner og kommuner vanskeligheter med å nå ut til innbyggerne, spesielt de med innvandrerbakgrunn som kanskje ikke snakker det lokale språket.",
  [tokens.vibori.solutions]: "Løsninger",
  [tokens.vibori.solutionsText]:
    "Vibori utviklet et intuitivt design som inkluderer en innebygd oversettelsesfunksjon, noe som gjør appen tilgjengelig for alle. Arrangementkalenderen er enkel å navigere, og brukerne kan filtrere arrangementer etter kategori, slik at de enkelt finner relevant innhold. Appen lar også brukere følge spesifikke organisasjoner og velge interesser for å motta skreddersydde varsler om hendelser og oppdateringer.",
  [tokens.vibori.outcomes]: "Resultater",
  [tokens.vibori.outcomesText]:
    "Selv om Vibori er en helt ny løsning, har vi allerede begynt å gjøre en forskjell i lokalsamfunn ved å engasjere kommuner og organisasjoner, slik at de får ett sentralt sted for kommunikasjon med innbyggerne. Vibori har også en “Impact management plan”, der den sosiale effekten vil bli målt etterhvert som plattformen vokser.",
  [tokens.vibori.keyFeatures]: "Nøkkelfunksjoner i Vibori",
  [tokens.vibori.localNews]: "Lokale Nyheter",
  [tokens.vibori.localNewsText]:
    "Hold deg oppdatert på de siste lokale nyhetene og viktige oppdateringer fra din kommune.",
  [tokens.vibori.eventCalendar]: "Aktivitetskalender",
  [tokens.vibori.eventCalendarText]:
    "Bla gjennom og filtrer arrangementer etter kategorier som familie, kultur og sport, og legg til i din personlige kalender.",
  [tokens.vibori.followOrg]: "Følg organisasjoner",
  [tokens.vibori.followOrgText]:
    "Motta sanntidsvarsler fra organisasjoner du følger, slik at du aldri går glipp av en viktig oppdatering eller hendelse.",
  [tokens.vibori.translateFeature]: "Oversettelsesfunksjon",
  [tokens.vibori.translateFeatureText]:
    "Få tilgang til alt innhold på flere språk, og bryt ned språkbarrierer ved å inkludere alle i samfunnet.",
  [tokens.vibori.mapInt]: "Kartintegrasjon",
  [tokens.vibori.mapIntText]:
    "Finn lokale arrangementer og aktiviteter ved å bruke kartfunksjonen, som gjør det enkelt å finne aktiviteter i nærheten.",
  /* Malimo Detail Page */
  [tokens.malimo.appName]: "Malimo",
  [tokens.malimo.projectInfo]: "Prosjektinformasjon",
  [tokens.malimo.client]: "Kunde:",
  [tokens.malimo.clientText]: "Malimo",
  [tokens.malimo.date]: "Dato:",
  [tokens.malimo.dateText]: "Utdanningsressurser",
  [tokens.malimo.place]: "Sted:",
  [tokens.malimo.placeText]: "Steinkjer, Norge",
  [tokens.malimo.projectDesc]: "Prosjektbeskrivelse",
  [tokens.malimo.overview]: "Oversikt",
  [tokens.malimo.overviewText]:
    "Malimo er en ledende leverandør av læringsmateriell og ressurser tilpasset elever fra 1. til 7. klasse, spesialundervisning og barnehager. Målet til Malimo er å fremme motivasjon gjennom mestring og sikre at hver enkelt elev lykkes på sitt eget nivå. Ved å tilby et omfattende bibliotek av ressurser, ønsker Malimo å forenkle arbeidsmengden for lærere, slik at de kan bruke mer tid på undervisning og mindre på administrative oppgaver.",
  [tokens.malimo.challenges]: "Utfordringer",
  [tokens.malimo.challengesText]:
    "Hovedutfordringen var å utvikle en plattform som ikke bare tilbød et variert utvalg av læringsressurser, men som også imøtekom ulike læringsnivåer og behov, fra vanlige klasserom til spesialundervisning. I tillegg måtte plattformen være brukervennlig både for lærere og elever.",
  [tokens.malimo.solutions]: "Løsninger",
  [tokens.malimo.solutionsText]:
    "Malimo tilbyr et stadig voksende bibliotek med over 250 gratisressurser og mer enn 2 500 produkter, alle utformet for å forbedre læring. Deres tilnærming er basert på «Malimo-pedagogikken», som understreker at alle kan lære, bare på forskjellige måter og i ulikt tempo. Plattformen inkluderer ferdig materiale, redigerbare maler og ressurser tilgjengelig på norsk, engelsk og andre språk, noe som sikrer tilgjengelighet og tilpasning for alle brukere.",
  [tokens.malimo.outcomes]: "Resultater",
  [tokens.malimo.outcomesText]:
    "Med over 72 000 aktive brukere har Malimo oppnådd stor suksess med å tilby lærere og pedagoger ressurser av høy kvalitet som sparer tid. Lærere rapporterer at bruken av Malimos materialer ikke bare effektiviserer arbeidsprosessen, men også engasjerer elevene på nye og kreative måter. Tilbakemeldingene fra brukerne viser tydelig den betydelige innvirkningen Malimo har hatt på utdanningslandskapet i Norge.",
  [tokens.malimo.keyFeatures]: "Nøkkelfunksjoner i Malimo",
  [tokens.malimo.testimonial]:
    "Daxap har vært avgjørende for oss. Vi har mottatt en teknisk løsning som gjør at vi kan vokse inn i fremtiden, med stabilitet og en smidig brukeropplevelse. De besitter solid kompetanse og har løst alle utfordringer underveis. I tillegg er de flotte mennesker som går den ekstra milen for oss på alle nivåer.",
  [tokens.malimo.testimonialAuthor]: "Maren Lie Malmo",
  [tokens.malimo.testimonialPosition]: "Grunnlegger, Malimo",
  [tokens.malimo.pedagogical]: "Pedagogisk Rammeverk",
  [tokens.malimo.pedagogicalText]:
    "Malimos utdanningsfilosofi bygger på troen om at hvert barn kan lykkes, med fokus på individuelt tilpasset læring og motivasjon gjennom mestring.",
  [tokens.malimo.extensive]: "Omfattende Ressursbibliotek",
  [tokens.malimo.extensiveText]:
    "Tilgang til over 250 gratisressurser og tusenvis av pedagogiske produkter som dekker flere fag og læringsnivåer.",
  [tokens.malimo.inclusive]: "Inkluderende Læring",
  [tokens.malimo.inclusiveText]:
    "Materialene er utformet for å støtte spesialundervisning, barnehager og elever med ulike læringsbehov.",
  /* Norsk Luftfartsmuseum Detail Page */
  [tokens.norskLuftfartsmuseum.appName]: "Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.projectInfo]: "Prosjektinformasjon",
  [tokens.norskLuftfartsmuseum.client]: "Kunde:",
  [tokens.norskLuftfartsmuseum.clientText]: "Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.date]: "Dato:",
  [tokens.norskLuftfartsmuseum.category]: "Kategori:",
  [tokens.norskLuftfartsmuseum.categoryText]: "Museums lydguide-app",
  [tokens.norskLuftfartsmuseum.projectDesc]: "Prosjektbeskrivelse",
  [tokens.norskLuftfartsmuseum.overview]: "Oversikt",
  [tokens.norskLuftfartsmuseum.overviewText]:
    "Appen “Norsk Luftfartsmuseum” er utviklet for å berike besøksopplevelsen ved det største flymuseet i Norden.Museet strekker seg over 10 000 kvadratmeter og belyser luftfartens historie, med særlig fokus på norsk luftfart. Appen er designet for å guide besøkende gjennom både militær- og sivil luftfart, og gir en mer interaktiv og informativ opplevelse.",
  [tokens.norskLuftfartsmuseum.challenges]: "Utfordringer",
  [tokens.norskLuftfartsmuseum.challengesText]:
    "Hovedutfordringen var å lage en app som ville tilføre verdi og brukervennlighet for besøkende i alle aldre fra det øyeblikket de entrer museet.",
  [tokens.norskLuftfartsmuseum.outcomes]: "Resultater",
  [tokens.norskLuftfartsmuseum.outcomesText]:
    "Norsk Luftfartsmuseum-appen har effektivt forbedret besøksopplevelsen, og tilbyr en mer engasjerende og interaktiv måte å utforske museets rike historie på. Besøkende kan nå nyte en skreddersydd, informativ tur som er tilpasset både voksne og barn, noe som gjør museet tilgjengelig og hyggelig for alle aldre.",
  [tokens.norskLuftfartsmuseum.keyFeatures]:
    "Nøkkelfunksjoner i Norsk Luftfartsmuseum",
  [tokens.norskLuftfartsmuseum.audio]: "Lydguide",
  [tokens.norskLuftfartsmuseum.audioText]:
    "Lær mer om museets utstillinger med en omfattende lydguide om både militær- og sivil luftfart.",
  [tokens.norskLuftfartsmuseum.qrCode]: "QR-kodeskanner",
  [tokens.norskLuftfartsmuseum.qrCodeText]:
    "Man kan enkelt skanne QR-koder på utstillingene for mer detaljert informasjon om spesifikke objekter.",
  [tokens.norskLuftfartsmuseum.interactive]: "Interaktivt Museumskart",
  [tokens.norskLuftfartsmuseum.interactiveText]:
    "Naviger deg gjennom museet med et interaktivt kart som hjelper deg å finne frem til de ulike seksjonene og utstillingene.",
  [tokens.norskLuftfartsmuseum.educational]: "Pedagogiske verktøy",
  [tokens.norskLuftfartsmuseum.educationalText]:
    "Få tilgang til pedagogiske ressurser, inkludert ruter og aktiviteter tilrettelagt for unge besøkende.",
  [tokens.norskLuftfartsmuseum.museumPark]: "Informasjon om museumsparken",
  [tokens.norskLuftfartsmuseum.museumParkText]:
    "Utforsk museumsparken og lær om utstillingene som er tilgjenelige utendørs.",
  /* t4kids Detail Page */
  [tokens.t4kids.appName]: "Time4Kids",
  [tokens.t4kids.projectInfo]: "Prosjektinformasjon",
  [tokens.t4kids.client]: "Kunde:",
  [tokens.t4kids.clientText]: "Foreldreplanlegger AS",
  [tokens.t4kids.date]: "Dato:",
  [tokens.t4kids.category]: "Kategori:",
  [tokens.t4kids.categoryText]: "Familie Meglingsplattform",
  [tokens.t4kids.projectDesc]: "Prosjektbeskrivelse",
  [tokens.t4kids.overview]: "Oversikt",
  [tokens.t4kids.overviewText]:
    "Time4Kids er en plattform laget for å hjelpe foreldre, meglere og advokater med å administrere familiemeklingsprosesser, spesielt ved separasjon eller skilsmisse. Den forenkler kommunikasjon, sakshåndtering og avtaleoppfølging mellom foreldre og meglere. Time4Kids har som mål å skape en jevnere og mer effektiv prosess som hjelper foreldre å samarbeide om viktige beslutninger angående sine barn.",
  [tokens.t4kids.challenges]: "Utfordringer",
  [tokens.t4kids.challengesText]:
    "Den største utfordringen for Time4Kids var å utforme en plattform som effektivt støtter både foreldre og meglere i å navigere i den ofte komplekse familiemeklingsprosessen. Målet var å tilby et enkelt, intuitivt grensesnitt samtidig som det gir de nødvendige verktøyene for å administrere saker, sette opp meklingsmøter og spore fremdrift.",
  [tokens.t4kids.solutions]: "Løsninger",
  [tokens.t4kids.solutionsText]:
    "Time4Kids tilbyr flere essensielle funksjoner, inkludert saksadministrasjon, møteplanlegging, oppretting av avtaler og en kalenderfunksjon for å administrere viktige frister og møter.Plattformen tilrettelegger for sømløst samarbeid, sparer tid og reduserer kostnader for både foreldre og meklere. De innebygde verktøyene sikrer at alle meklingsprosesser enkelt kan spores og administreres på ett sted, noe som gir klarhet og transparens.",
  [tokens.t4kids.outcomes]: "Resultater",
  [tokens.t4kids.outcomesText]:
    "Time4Kids har revolusjonert måten familiemekling utføres ved å tilby et digitalt rom hvor alle relevante parter kan samarbeide effektivt. Meglere kan håndtere flere saker uten problemer, mens foreldre drar nytte av strømlinjeformet kommunikasjon og enklere håndtering av barnerelaterte avtaler. Resultatet er en raskere og mer organisert prosess som støtter alle involverte parter.",
  [tokens.t4kids.keyFeatures]: "Nøkkelfunksjoner i T4Kids",
  [tokens.t4kids.caseMng]: "Saksbehandling",
  [tokens.t4kids.caseMngText]:
    "Enkelt opprett og administrer saker på nett, og sørg for at all dokumentasjon og fremgang er tilgjengelig på ett sted.",
  [tokens.t4kids.agreement]: "Avtaleopprettelse",
  [tokens.t4kids.agreementText]:
    "Forenkler utarbeidelse og håndteringen av avtaler mellom foreldre under mekling.",
  [tokens.t4kids.mediation]: "Planlegging av meklingsøkter",
  [tokens.t4kids.mediationText]:
    "Planlegg og administrer obligatoriske meklingsøkter mellom foreldre med en integrert kalender.",
  [tokens.t4kids.tracking]: "Sporing av Fremdrift",
  [tokens.t4kids.trackingText]:
    "Følg status og utvikling av saker og økter, noe som sparer tid og reduser kostnader.",
  [tokens.t4kids.licenceMng]: "Lisensadministrasjon",
  [tokens.t4kids.licenceMngText]:
    "Meklere kan administrere flere lisenser og dele dem med teammedlemmer, noe som sikrer en samarbeidsorientert tilnærming.",
  [tokens.t4kids.testimonial]:
    "Daxap AS har vært en nøkkelpartner i å utvikle våre produkter, og deres teknologiske ekspertise har hjulpet oss med å realisere vår visjon om å tilby innovative og brukervennlige verktøy for foreldre. Med deres fleksibilitet og evne til å levere skreddersydde løsninger har Daxap vært en pålitelig samarbeidspartner, noe som har styrket vår posisjon i markedet og gjort oss i stand til å tilby produkter av høy kvalitet.",
  [tokens.t4kids.testimonialAuthor]: "Grethe Andersen",
  [tokens.t4kids.testimonialPosition]: "Medgründer, Foreldreplanlegger",
  /* Pailot Detail Page */
  [tokens.pailot.appName]: "Pailot",
  [tokens.pailot.projectInfo]: "Prosjektinformasjon",
  [tokens.pailot.client]: "Klient",
  [tokens.pailot.clientText]: "Servai AS",
  [tokens.pailot.date]: "Dato:",
  [tokens.pailot.category]: "Kategori:",
  [tokens.pailot.categoryText]: "Plattform for veldedig organisasjonsstyring",
  [tokens.pailot.projectDesc]: "Prosjektbeskrivelse",
  [tokens.pailot.overview]: "Oversikt",
  [tokens.pailot.overviewText]:
    "Pailot er en innovativ plattform utviklet for å revolusjonere hvordan ideelle organisasjoner og NGO-er administrerer prosjekter, frivillig innsats, samt sosial effekt. Pailot integrerer Kunstig Intelligens (KI) -drevne verktøy for å effektivisere driften, forbedre samarbeidet og gi verdifulle innsikter i organisasjonens resultater. Ved å forenkle de komplekse prosessene innen prosjektledelse og frivillig koordinering, gir Pailot ideelle organisasjoner mulighet til å fokusere mer på å skape et meningsfullt samfunnsengasjement, samtidig som de sparer verdifull tid.",
  [tokens.pailot.challenges]: "Utfordringer",
  [tokens.pailot.challengesText]:
    "Ideelle organisasjoner møter ofte betydelige utfordringer i forbindelse med prosjektledelse og samordning av frivillige på grunn av begrensede ressurser og komplekse operasjoner. Pailot håndterer disse utfordringene ved å tilby en omfattende løsning som kombinerer brukervennlighet, datasikkerhet og KI-drevne innsikter, samtidig som det overholder strenge databeskyttelses-reguleringer.",
  [tokens.pailot.solutions]: "Løsninger",
  [tokens.pailot.solutionsText]:
    "Pailot tilbyr et sett funksjoner som er utviklet for å forbedre organisatorisk effektivitet, inkludert prosjektstyringsverktøy, frivilligsamordningsmoduler og KI-drevet analyse som gir handlingsbare innsikter. Plattformen er bygget med et brukervennlig grensesnitt for å sikre at også organisasjoner med begrenset teknisk ekspertise kan bruke den effektivt. I tillegg prioriterer Pailot datasikkerhet med kryptert dataoverføring og overholdelse av globale forskrifter som GDPR.",
  [tokens.pailot.outcomes]: "Resultater",
  [tokens.pailot.outcomesText]:
    "Siden lanseringen har Pailot hjulpet flere ideelle organisasjoner med å optimalisere driften, redusere administrative byrder og forsterke deres sosiale påvirkning. Gjennom integrerte analyser kan ideelle organisasjoner nå spore fremgangen sin og ta datadrevne beslutninger som øker effektiviteten til deres oppdrag. Plattformen har vist seg å være et verdifullt verktøy for å fremme samarbeid og sikre at hver innsats er i tråd med organisasjonens mål.",
  [tokens.pailot.keyFeatures]: "Nøkkelfunksjoner i Pailot",
  [tokens.pailot.aiPowered]: "Kunstil Intelligens Analyse",
  [tokens.pailot.aiPoweredText]:
    "Bruk KI for å få dypere innsikt i prosjektets potensial og frivillig engasjement, slik at din organisasjon kan ta informerte beslutninger.",
  [tokens.pailot.projectMng]: "Prosjektstyring",
  [tokens.pailot.projectMngText]:
    "Administrer prosjekter fra start til slutt med verktøy som forenkler oppgavefordeling, sporing og rapportering.",
  [tokens.pailot.volunteer]: "Frivillig Koordinering",
  [tokens.pailot.volunteerText]:
    "Effektiviser frivillig onboarding, planlegging og kommunikasjon for å øke engasjement og beholdning.",
  [tokens.pailot.dataSec]: "Datasikkerhet",
  [tokens.pailot.dataSecText]:
    "Føl deg trygg med Pailots sterke databeskyttelsespraksis, inkludert kryptering og GDPR-overholdelse.",
  [tokens.pailot.collaboration]: "Samarbeidsverktøy",
  [tokens.pailot.collaborationText]:
    "Frem godt samarbeid med integrerte kommunikasjonsfunksjoner som holder alle oppdaterte.",
};
