import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const OurSolutionsSidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();

  const services = [
    { label: t(tokens.appDevelopment.sidebarTitle), href: "/app-development" },
    { label: t(tokens.webDevelopment.sidebarTitle), href: "/web-development" },
    {
      label: t(tokens.qaDevelopment.sidebarTitle),
      href: "/quality-assurance",
    },
  ];

  return (
    <aside className="services-sidebar">
      {/* Our Services */}
      <div className="services-cat-list mb-30">
        <ul className="list-wrap">
          {services.map((item) => (
            <li
              key={item.label}
              className={cn({ active: item.href === pathname })}
            >
              <Link to={item.href}>
                {t(item.label)} <i className="flaticon-right-arrow"></i>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};
