import React from "react";
import { OurCapabilityText } from "./OurCapabilityText";
import { OurCapabilityIcon } from "./OurCapabilityIcon";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const OurCapability = () => {
  const { t } = useTranslation();

  return (
    <section className="our-capability-area has-animation">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title section-title-three text-center mb-60 tg-heading-subheading animation-style1">
              <span className="sub-title tg-element-title">
                {t(tokens.ourCapability.subTitle)}
              </span>
              <h2 className="title tg-element-title">
                {t(tokens.ourCapability.title)}
              </h2>
              <p>{t(tokens.ourCapability.description)}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-5 col-12">
            <OurCapabilityText />
          </div>
          <div className="col-lg-7 col-12">
            <OurCapabilityIcon />
          </div>
        </div>
      </div>
    </section>
  );
};
