import React from "react";
import { SERVICES_ONE_LIST } from "../../data/services";
import { ALL_PROJECT_LIST } from "../../data/projects";
import { PortfolioAreaItem } from "./Home/PortfolioAreaItem";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const PortfolioWrapper = () => {
  const list = [...SERVICES_ONE_LIST, ...SERVICES_ONE_LIST];
  list.length = 6;
  const { t } = useTranslation();

  return (
    <section className="services-area-five inner-services-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="section-title text-center mb-50">
              <span className="sub-title">
                {t(tokens.ourPortfolio.portfolioOurProjects.subtitle)}
              </span>
              <h2 className="title">
                {t(tokens.ourPortfolio.portfolioOurProjects.title)}
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {ALL_PROJECT_LIST.map((project) => (
            <div key={project.id} className="col-lg-4 col-md-6 col-sm-10">
              <PortfolioAreaItem project={project} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
