// FooterTwo.js
import React from "react";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const FooterTwo = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-area-two footer-bg-two">
        <div className="footer-top-two">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                      <img src={LOGO} alt="" />
                    </Link>
                  </div>

                  <div className="footer-content mb-4">
                    <div>
                      <span>{t(tokens.footer.mainOffice)}</span>
                    </div>
                    <div>
                      <span>{t(tokens.footer.norwayOffice)}</span>
                    </div>
                  </div>
                  <div className="footer-social footer-social-two">
                    <ul className="list-wrap">
                      <li>
                        <a
                          href="https://www.facebook.com/daxap.no"
                          aria-label="Visit our Facebook page"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/daxap"
                          aria-label="Visit our LinkedIn page"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">{t(tokens.footer.menu)}</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/team">{t(tokens.nav.ourTeam)}</Link>
                      </li>
                      <li>
                        <Link to="/social-entrepreneurship">{t(tokens.nav.socialEntrepreneurship)}</Link>
                      </li>
                      <li>
                        <Link to="/app-development">{t(tokens.nav.ourSolutions)}</Link>
                      </li>
                      <li>
                        <Link to="/portfolio">{t(tokens.nav.portfolio)}</Link>
                      </li>
                      <li>
                        <Link to="/blog">{t(tokens.nav.blog)}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t(tokens.nav.contactUs)}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">{t(tokens.footer.company)}</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/realize-your-project">{t(tokens.nav.realizeProject)}</Link>
                      </li>
                      <li>
                        <Link to="/careers">{t(tokens.nav.careers)}</Link>
                      </li>
                      <li>
                        <Link to="/trust-center">{t(tokens.nav.trustCenter)}</Link>
                      </li>
                      <li>
                        <Link to="/sustainability">{t(tokens.nav.sustainability)}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">{t(tokens.footer.contactUs)}</h4>
                  <div className="footer-newsletter">
                    <div className="footer-info">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="content">
                            <a href="tel:0123456789">+47 98 33 90 44</a>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="flaticon-mail"></i>
                          </div>
                          <div className="content">
                            <a href="mailto:post@daxap.io">post@daxap.io</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text-two text-center">
                  <p>{t(tokens.footer.copyright)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
