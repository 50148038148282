import React from "react";
import { Link } from "react-router-dom";
import { HOMEPAGE_PROJECT_LIST } from "../../../data/projects";
import { PortfolioAreaItem } from "./PortfolioAreaItem";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const PortfolioArea = () => {
  const { t } = useTranslation();

  return (
    <section className="project-area-four">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-8">
            <div className="section-title section-title-three mb-50 tg-heading-subheading animation-style1">
              <span className="sub-title tg-element-title">{t(tokens.portfolio.title)}</span>
              <h2 className="title tg-element-title">
                {t(tokens.portfolio.subtitle)}
              </h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-4">
            <div className="view-all-btn text-end mb-30">
              <Link to="/portfolio" className="btn btn-three">
                {t(tokens.portfolio.seeAllProjects)}
              </Link>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {HOMEPAGE_PROJECT_LIST.map((project) => (
            <div key={project.id} className="col-lg-4 col-md-6 col-12">
              <PortfolioAreaItem project={project} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
