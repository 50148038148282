import axios from "axios";

// Blogs
export const blogApi = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_PUBLIC_API_URL,
});

blogApi.interceptors.request.use(
  function (config) {
    const token = process.env.REACT_APP_API_TOKEN;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

blogApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
