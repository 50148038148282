import React from "react";
import { ScrollToTopButton } from "../components/ScrollToTopButton/ScrollToTopButton";
import { FooterTwo } from "../components/Footers/FooterTwo";
import { Header } from "../components/Headers/Header";

export const Layout = ({ children, header, footer, breadcrumb, title }) => {
  return (
    <>
      {/* Scroll-top  */}
      <ScrollToTopButton />

      {/* header */}
      <Header />

      {/* breadcrumb */}
      {/* {breadcrumb && <Breadcrumb text={breadcrumb} title={title} />} */}

      {/* children */}
      <main className="fix">{children}</main>

      {/* footer */}
      <FooterTwo />
    </>
  );
};
