import { CiWavePulse1 } from "react-icons/ci";
import { FaBookReader } from "react-icons/fa";
import { RiEqualizer2Fill } from "react-icons/ri";
import { RiCommunityFill } from "react-icons/ri";
import { FcCollaboration } from "react-icons/fc";
import { tokens } from "../locales/tokens";

export const GOALS = [
  {
    id: 1,
    count: 3,
    icon: <CiWavePulse1 />,
    title: tokens.goals.goodHealth,
  },
  {
    id: 2,
    count: 4,
    icon: <FaBookReader />,
    title: tokens.goals.qualityEducation,
  },
  {
    id: 3,
    count: 10,
    icon: <RiEqualizer2Fill />,
    title: tokens.goals.reducedInequalities,
  },
  {
    id: 4,
    count: 11,
    icon: <RiCommunityFill />,
    title: tokens.goals.sustainableCities,
  },
  {
    id: 5,
    count: 17,
    icon: <FcCollaboration />,
    title: tokens.goals.partnerships,
  },
];
