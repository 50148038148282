import React from "react";
import { CareerCardItem } from "./CareerCardItem";
import { CAREERS } from "../../../data/career";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const CareerCard = () => {
  const { t } = useTranslation();

  return (
    <section className="services-area-seven pt-120 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title-two text-center mb-50">
              <span className="sub-title">
                {t(tokens.careerCard.interestedMessage)}
              </span>
              <h2 className="title">{t(tokens.careerCard.mainTitle)}</h2>
              <p>{t(tokens.careerCard.description)}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {CAREERS.map((career) => (
            <div key={career.id} className="col-lg-4 col-md-6">
              <CareerCardItem career={career} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
