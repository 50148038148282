import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { LanguagePopover } from "./LanguagePopover";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const NavMenus = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isActiveCn = (path) => {
    return path === pathname ? "active" : "";
  };

  const aboutLinkList = [
    {
      to: "/team",
      title: t(tokens.nav.ourTeam),
    },
    {
      to: "/social-entrepreneurship",
      title: t(tokens.nav.socialEntrepreneurship),
    },
  ];

  const companyLinkList = [
    {
      to: "/realize-your-project",
      title: t(tokens.nav.realizeProject),
    },
    {
      to: "/careers",
      title: t(tokens.nav.careers),
    },
    {
      to: "/trust-center",
      title: t(tokens.nav.trustCenter),
    },
    {
      to: "/sustainability",
      title: t(tokens.nav.sustainability),
    },
  ];

  const ourSolutionsList = [
    {
      to: "/app-development",
      title: t(tokens.appDevelopment.sidebarTitle),
    },
    {
      to: "/web-development",
      title: t(tokens.webDevelopment.sidebarTitle),
    },
    {
      to: "/quality-assurance",
      title: t(tokens.qaDevelopment.sidebarTitle),
    },
  ];

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {/* about */}
        <li
          className={cn("menu-item-has-children", {
            active: aboutLinkList.map((el) => el.to).includes(pathname),
          })}
        >
          <a href="#">{t(tokens.nav.aboutUs)}</a>
          <ul className="sub-menu">
            {aboutLinkList.map((el) => (
              <li key={el.to} className={cn(isActiveCn(el.to))}>
                <Link to={el.to}>{el.title}</Link>
              </li>
            ))}
          </ul>
        </li>
        {/* Company */}
        <li
          className={cn("menu-item-has-children", {
            active: [...companyLinkList].map((el) => el.to).includes(pathname),
          })}
        >
          <a href="#">{t(tokens.nav.company)}</a>
          <ul className="sub-menu">
            {companyLinkList.map((el) => (
              <li key={el.to} className={cn(isActiveCn(el.to))}>
                <Link to={el.to}>{el.title}</Link>
              </li>
            ))}
          </ul>
        </li>
        {/* Our Solutions */}
        <li
          className={cn("menu-item-has-children", {
            active: [...ourSolutionsList].map((el) => el.to).includes(pathname),
          })}
        >
          <a href="#">{t(tokens.nav.ourSolutions)}</a>
          <ul className="sub-menu">
            {ourSolutionsList.map((el) => (
              <li key={el.to} className={cn(isActiveCn(el.to))}>
                <Link to={el.to}>{el.title}</Link>
              </li>
            ))}
          </ul>
        </li>
        {/* Portfolio */}
        <li>
          <Link to="/portfolio">{t(tokens.nav.portfolio)}</Link>
        </li>
        {/* Blog */}
        <li className={cn(isActiveCn("/blog"))}>
          <Link to="/blog">{t(tokens.nav.blog)}</Link>
        </li>
        {/* Contact */}
        <li>
          <Link to="/contact">{t(tokens.nav.contactUs)}</Link>
        </li>
        {/* Language Popover */}
        <li>
          <LanguagePopover />
        </li>
      </ul>
    </div>
  );
};
