import {
  anders_IMG,
  kubilay_IMG,
  oddbjorn_IMG,
  jan_IMG,
  emirhan_IMG,
  asim_IMG,
  ismail_IMG,
  simen_IMG,
  mustafa_IMG,
  una_IMG,
  burak_IMG,
  karen_IMG,
  tone_IMG,
  maren_IMG,
  jon_IMG,
  sindre_IMG,
} from "../lib/assets";
import { tokens } from "../locales/tokens";

export const boardTeam = [
  {
    id: 1,
    src: anders_IMG,
    title: "Anders Krohn",
    designation: tokens.team.chairman,
    linkedIn: "https://www.linkedin.com/in/anders-krohn-18870828/",
  },
  {
    id: 2,
    src: kubilay_IMG,
    title: "Kubilay Kartal",
    designation: tokens.team.ceo,
    linkedIn: "https://www.linkedin.com/in/kblykrtl/",
  },
  {
    id: 3,
    src: oddbjorn_IMG,
    title: "Oddbjørn Sve",
    designation: tokens.team.boardMember,
    linkedIn: "https://www.linkedin.com/in/oddbj%C3%B8rn-sve-798143161/",
  },
  {
    id: 4,
    src: jan_IMG,
    title: "Jan Tveterås",
    designation: tokens.team.boardMember,
    linkedIn: "https://www.linkedin.com/in/jan-tveteras/",
  },
];

export const coreTeam = [
  {
    id: 1,
    src: emirhan_IMG,
    title: "Emirhan Sozan",
    designation: tokens.team.headOfDevOps,
    linkedIn: "https://www.linkedin.com/in/emir-4026a4154/",
  },
  {
    id: 2,
    src: asim_IMG,
    title: "Asim E. Yilmaz",
    designation: tokens.team.chiefProductOfficer,
    linkedIn: "https://www.linkedin.com/in/asmemreylmz/",
  },
  {
    id: 3,
    src: ismail_IMG,
    title: "Ismail Kaya",
    designation: tokens.team.qaManager,
    linkedIn: "https://www.linkedin.com/in/ismailkaya83/",
  },
  {
    id: 4,
    src: simen_IMG,
    title: "Simen R. Andresen",
    designation: tokens.team.marketingManager,
    linkedIn: "https://www.linkedin.com/in/simen-riffault-andresen-01a95284/",
  },
  {
    id: 5,
    src: mustafa_IMG,
    title: "Mustafa Gul",
    designation: tokens.team.headOfAppDev,
    linkedIn: "https://www.linkedin.com/in/mustafa-gul-36a568223/",
  },
  {
    id: 6,
    src: una_IMG,
    title: "Una Landsem",
    designation: tokens.team.marketer,
    linkedIn:
      "https://www.linkedin.com/in/una-landsem-36711726b?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAEIL_j8BrQyhMpRBGedpHZSlHn0kxN_NqzU&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bqwy6IGwWQ92sJheRDfbUfw%3D%3D",
  },
  {
    id: 7,
    src: burak_IMG,
    title: "Burak Basara",
    designation: tokens.team.globalSalesDirector,
    linkedIn:
      "https://www.linkedin.com/in/burakbasara?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAI0z2YBY9xoDndqWjCwZMDhYW0UF9EuLyw&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BNJUrcguCRJ6OYmbX6eeCKA%3D%3D",
  },
];

export const contributors = [
  {
    id: 1,
    src: karen_IMG,
    title: "Karen J. Skarbø",
    designation: tokens.team.businessDeveloper,
    year: "2021",
    linkedIn: "https://www.linkedin.com/in/karenskarbo/",
  },
  {
    id: 2,
    src: tone_IMG,
    title: "Tone Evensen",
    designation: tokens.team.adviser,
    year: "2021",
    linkedIn: "https://www.linkedin.com/in/tone-evensen-97a505201/",
  },
  {
    id: 3,
    src: maren_IMG,
    title: "Maren L. Malmo",
    designation: tokens.team.adviser,
    year: "2023",
    linkedIn: "https://www.linkedin.com/in/maren-lie-malmo-9b201285/",
  },
  {
    id: 4,
    src: jon_IMG,
    title: "Jon Grunnan",
    designation: tokens.team.adviser,
    year: "2023",
    linkedIn: "https://www.linkedin.com/in/grunnan/",
  },
  {
    id: 5,
    src: sindre_IMG,
    title: "Sindre L. Nyeng",
    designation: tokens.team.adviser,
    year: "2023",
    linkedIn: "https://www.linkedin.com/in/sindre-lutdal-nyeng-b19408110/",
  },
];
