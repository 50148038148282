import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/Blog/BlogPageWrapper";
import { getPaginateBlogs } from "../../services/blog";
import { BlogCard } from "../../components/Blog/BlogCard";
import { BlogCardPlaceholder } from "../../components/Blog/BlogCardSkeleton";
import { LanguageContext } from "../../context/language-provider";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { language } = useContext(LanguageContext);

  const blogsPerPage = 4;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (!language) return; // Only proceed if language is defined

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchBlogs = async (page = 1) => {
      setIsLoading(true);
      const start = (page - 1) * blogsPerPage;
      const lang = language === "no" ? "nb" : language;
      const res = await getPaginateBlogs(start, blogsPerPage, signal, lang);
      setBlogs(res.fetchedBlogs);
      setIsLoading(false);

      const totalBlogCount = res.total;
      setTotalPages(Math.ceil(totalBlogCount / blogsPerPage));
    };

    fetchBlogs(currentPage);

    return () => {
      controller.abort();
    };
  }, [currentPage, language]);

  return (
    <Layout header={5} footer={2}>
      <BlogPageWrapper>
        <div className="blog-post-wrap">
          <div className="row">
            {isLoading
              ? Array(blogsPerPage)
                  .fill()
                  .map((_, index) => <BlogCardPlaceholder key={index} />)
              : blogs.map((blog) => (
                  <div key={blog.id} className="col-md-6">
                    <BlogCard blog={blog} />
                  </div>
                ))}
          </div>

          {/* Pagination */}
          <div className="pagination-wrap mt-30">
            <nav aria-label="Page navigation example">
              <ul className="pagination list-wrap">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i className="fas fa-angle-left"></i>
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item next-page">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <i className="fas fa-angle-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogPage;
