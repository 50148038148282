import { CAPABILITYICON } from "../../data/capabilities";

export const OurCapabilityIcon = () => {
  return (
    <div className="our-capability-icon-wrap">
      <ul className="list-wrap">
        {CAPABILITYICON.map((icon) => (
          <li key={icon.id}>
          <span>{icon.icon}</span>
            <span>{icon.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
