import { SERVICES_IMG01, SERVICES_IMG02, SERVICES_IMG03 } from "../lib/assets";
import { tokens } from "../locales/tokens";

export const SERVICES_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/app-development",
    title: tokens.servicesList.appDevelopmentTitle,
    subtitle: tokens.servicesList.appDevelopmentSubtitle,
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/web-development",
    title: tokens.servicesList.webDevelopmentTitle,
    subtitle: tokens.servicesList.webDevelopmentSubtitle,
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/quality-assurance",
    title: tokens.servicesList.qualityAssuranceTitle,
    subtitle: tokens.servicesList.qualityAssuranceSubtitle,
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Business Analysis",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG03,
    title: "Financial Advice",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];
