// SocialEntrepreneurship.js
import React from "react";
import { useTranslation } from "react-i18next";
import {
  SOCIAL_ENTERPRENEURSHIP_1_IMG,
  SOCIAL_ENTERPRENEURSHIP_2_IMG,
} from "../../lib/assets";
import { CollaborationPartner } from "../Brand/CollaborationPartner";
import { FeatureGoals } from "../Features/FeatureGoals";
import { tokens } from "../../locales/tokens";

export const SocialEntrepreneurship = () => {
  const { t } = useTranslation();

  return (
    <section className="about-area-five">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="about-img-wrap-five">
              <img
                src={SOCIAL_ENTERPRENEURSHIP_1_IMG}
                alt="social_entrepreneurship_img"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="about-content-five">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  {t(tokens.socialEntrepreneurship.title)}
                </span>
                <h2 className="title tg-element-title">
                  {t(tokens.socialEntrepreneurship.whatIs)}
                </h2>
              </div>
              <p>{t(tokens.socialEntrepreneurship.description)}</p>
              <div className="about-content-bottom">
                <div className="about-list">
                  <ul className="list-wrap">
                    <li>
                      <i className="fas fa-arrow-right"></i>
                      {t(tokens.socialEntrepreneurship.focusChallenges)}
                    </li>
                    <li>
                      <i className="fas fa-arrow-right"></i>
                      {t(tokens.socialEntrepreneurship.provenResults)}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center pt-60 pb-30">
          <div className="col-xl-5 col-lg-6">
            <div className="about-img-wrap-five">
              <img
                src={SOCIAL_ENTERPRENEURSHIP_2_IMG}
                alt="social_entrepreneurship_img"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-7 col-lg-6">
            <div className="about-content-five">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <h2 className="title tg-element-title">
                  {t(tokens.socialEntrepreneurship.daxapQualifies)}
                </h2>
              </div>
              <p>{t(tokens.socialEntrepreneurship.daxapStory)}</p>
              <p>{t(tokens.socialEntrepreneurship.daxapPartners)}</p>
            </div>
          </div>
        </div>
      </div>
      <FeatureGoals />
      <CollaborationPartner />
    </section>
  );
};
