import { FaCircleCheck } from "react-icons/fa6";
import { DiNodejs } from "react-icons/di";
import { SiNestjs } from "react-icons/si";
import { FaLaravel } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiMysql } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { RiNextjsFill } from "react-icons/ri";
import { SiTypescript } from "react-icons/si";
import { FaGithub } from "react-icons/fa";
import { SiClickup } from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";
import { DiMongodb } from "react-icons/di";
import { SiHeroku } from "react-icons/si";
import { SiVercel } from "react-icons/si";
import { SiRedis } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";
import { SiFlutter } from "react-icons/si";
import { SiSalesforce } from "react-icons/si";
import { SiKotlin } from "react-icons/si";
import { FaDocker } from "react-icons/fa";
import { SiKubernetes } from "react-icons/si";
import { FaJenkins } from "react-icons/fa";
import { SiFrappe } from "react-icons/si";
import { tokens } from "../locales/tokens";

export const CAPABILITYTEXT = [
  {
    id: 1,
    icon: <FaCircleCheck style={{ marginRight: "16px" }} />,
    title: tokens.capability.saasApplications, // Translated title
  },
  {
    id: 2,
    icon: <FaCircleCheck style={{ marginRight: "16px" }} />,
    title: tokens.capability.webMobileAppDevelopment, // Translated title
  },
  {
    id: 3,
    icon: <FaCircleCheck style={{ marginRight: "16px" }} />,
    title: tokens.capability.productDevelopment, // Translated title
  },
  {
    id: 4,
    icon: <FaCircleCheck style={{ marginRight: "16px" }} />,
    title: tokens.capability.customSoftwareDevelopment, // Translated title
  },
  {
    id: 5,
    icon: <FaCircleCheck style={{ marginRight: "16px" }} />,
    title: tokens.capability.customApiDevelopmentIntegration, // Translated title
  },
];

export const CAPABILITYICON = [
  {
    id: 1,
    icon: <DiNodejs size={40} />,
    title: "Node.js",
  },
  {
    id: 2,
    icon: <SiNestjs size={40} />,
    title: "Nest.js",
  },
  {
    id: 3,
    icon: <FaLaravel size={40} />,
    title: "Laravel",
  },
  {
    id: 4,
    icon: <FaPython size={40} />,
    title: "Python",
  },
  {
    id: 5,
    icon: <FaReact size={40} />,
    title: "React",
  },
  {
    id: 6,
    icon: <RiNextjsFill size={40} />,
    title: "NextJs",
  },
  {
    id: 7,
    icon: <SiTypescript size={40} />,
    title: "Typescript",
  },
  {
    id: 8,
    icon: <FaGithub size={40} />,
    title: "Github",
  },
  {
    id: 9,
    icon: <SiClickup size={40} />,
    title: "Clickup",
  },
  {
    id: 10,
    icon: <BiLogoPostgresql size={40} />,
    title: "PostgreSQL",
  },
  {
    id: 11,
    icon: <SiMysql size={40} />,
    title: "MySQL",
  },
  {
    id: 12,
    icon: <DiMongodb size={40} />,
    title: "MongoDB",
  },
  {
    id: 13,
    icon: <SiRedis size={40} />,
    title: "Redis",
  },
  {
    id: 14,
    icon: <FaAws size={40} />,
    title: "AWS",
  },
  {
    id: 15,
    icon: <SiMicrosoftazure size={40} />,
    title: "Azure",
  },
  {
    id: 16,
    icon: <SiHeroku size={40} />,
    title: "Heroku",
  },
  {
    id: 17,
    icon: <SiVercel size={40} />,
    title: "Vercel",
  },
  {
    id: 18,
    icon: <SiFlutter size={40} />,
    title: "Flutter",
  },
  {
    id: 20,
    icon: <SiKotlin size={40} />,
    title: "Kotlin",
  },
  {
    id: 21,
    icon: <FaDocker size={40} />,
    title: "Docker",
  },
  {
    id: 22,
    icon: <SiKubernetes size={40} />,
    title: "Kubernetes",
  },
  {
    id: 23,
    icon: <FaJenkins size={40} />,
    title: "Jenkins ",
  },
  {
    id: 19,
    icon: <SiSalesforce size={40} />,
    title: "AI",
  },
  {
    id: 20,
    icon: <SiFrappe size={40} />,
    title: "Frappe",
  },
];
