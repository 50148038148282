import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getRecentBlogs } from "../../services/blog";
import { LanguageContext } from "../../context/language-provider";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";

export const BlogPageSidebar = () => {
  const [recentBlogs, setRecentBlogs] = useState([]);
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!language) return;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchBlogs = async () => {
      const lang = language === "no" ? "nb" : language;
      const res = await getRecentBlogs(signal, lang);
      setRecentBlogs(res);
    };

    fetchBlogs();

    return () => {
      controller.abort();
    };
  }, [language]);
  return (
    <aside className="blog-sidebar">
      {/* recents */}
      <div className="blog-widget">
        <h4 className="bw-title">{t(tokens.recentPosts)}</h4>

        <div className="rc-post-wrap">
          {recentBlogs?.map((blog) => {
            const cover = blog.cover.formats.thumbnail.url;
            const publishedAt = moment(blog.publishedAt).format("DD.MM.YYYY ");
            const title = blog.title;
            const slug = blog.slug;
            return (
              <div key={blog.id} className="rc-post-item">
                <div className="thumb">
                  <Link to={`/blog/${slug}`}>
                    <img src={cover} alt={slug} />
                  </Link>
                </div>
                <div className="content">
                  <span className="date">
                    <i className="far fa-calendar"></i>
                    {publishedAt}
                  </span>
                  <h2 className="title">
                    <Link to={`/blog/${slug}`}>{title}</Link>
                  </h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </aside>
  );
};
